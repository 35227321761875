import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {CreateRole} from '../core/_requests'
import swal from 'sweetalert'

type Props = {
  role: any
  update: boolean
  refreshPage: any
  handleClose: any
}

export const AddRole: FC<Props> = ({role, update, refreshPage, handleClose}) => {
  let formData = new FormData()
  const [userForEdit] = useState<any>({
    ...role,
    id: role.id,
    name: role.name,
  })
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          //  await UpdateUser(user.id, values)
          //     .then(() => {
          //       swal('Düzenlendi', 'Kullanıcı Bilgileri başarı ile düzenlendi.', 'success', {timer: 2000})
          //       refreshPage()
          //       handleClose()
          //     })
          //     .catch((err) => {
          //       swal('Hata', err.response.data.message, 'error', {timer: 2000})
          //     })
        } else {
          formData.append('name', values.name)
          await CreateRole(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Rol başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              console.log(err.message)
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <form
      id='kt_modal_add_user_info_form'
      onSubmit={formik.handleSubmit}
      className='form'
      noValidate
    >
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_info_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_info_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_info_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Rol Adı</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.title && formik.errors.title},
              // {
              //   'is-valid': formik.touched.title && !formik.errors.title,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {/* {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )} */}
          {/* end::Input */}
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
