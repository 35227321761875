import React, {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {Search} from '../../../../_theme/partials'
import {ModalHeader} from '../../components/ModalHeader'
import {AddBlog} from '../blog-edit-modal/AddBlog'
import {Categories} from '../components/Categories'
import {RecentBlog} from '../components/RecentBlog'
// import '../style/style-swal.css'

import swal from 'sweetalert'
import {DeleteBlog, getBlogDetail} from '../core/_requests'
import {FileItem} from '../../Announcements/components/fileItem'
import {BlogViewUsers} from '../components/BlogViewUsers'
import clsx from 'clsx'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ButtonActive} from '../../Activities/components/ButtonActive'
import {UpdateAtachments} from '../blog-edit-modal/UpdateAtachments'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import { ChatInner } from '../components/ChatInner'
type LocationState = {
  id: string
  title: string | Blob
  start_date?: string
  content: string
  is_public: boolean
  is_slider: boolean
  email_notification: boolean
  phone_notification: boolean
  category: any
  attachments: any
  assignments: [
    {
      id: 1
      name: ''
    }
  ]
  banner?: any
  created_by?: any
  files: any
  readers: any
}
type Props = {
  id: string
}

export const BlogDetail: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  let data = location.state as LocationState
  console.log(data)
  const [show, setShow] = useState(false)
  const [controlButtons, setControlbuttons] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [showAtachModal, setShowAtachModal] = useState(false)
  const handleCloseAtach = () => setShowAtachModal(false)
  const handleShowAtach = () => setShowAtachModal(true)
  const [blog, setBlog] = useState(data)
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  useEffect(() => {
    if (data.created_by.id == user.id || user.id === 36) {
      setControlbuttons(true)
    }
    getBlogDetail(data.id).then(({data}) => {
      setBlog(data.data)
      console.log(data.data)
    })
  }, [show, showAtachModal, data])

  // Delete announcement and route to announcement page
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteBlog(data.id)
        swal('Silindi!', 'Blog başarı ile silindi.', 'success', {timer: 2000})

        navigate('/blog')
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const imgLink = data.files.length
    ? blog.files[0].path
    : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')
  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className='app-container container-fluid'>
            <div className='card'>
              <div className='card-body p-lg-10 pb-lg-0'>
                <div className='d-flex flex-column flex-xl-row'>
                  <div className='flex-lg-row-fluid me-xl-15'>
                    <div className='mb-17'>
                      <div className='mb-8'>
                        <div className='overlay mt-0'>
                          {/* <div
                            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-325px'
                            style={{
                              backgroundImage: `url(${imgLink})`,
                              backgroundSize: '100% 100%',
                            }}
                          ></div> */}
                          <img src={imgLink} alt="blog-photo" className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded w-100'/>
                        </div>
                      </div>

                      <div className='p-0'>
                        <div className='d-flex align-items-center justify-content-between pb-4'>
                          <div className='text-gray-500 fs-5'>
                            <span className='fw-bold'>
                              {blog.created_by.name} tarafından oluşturuldu
                            </span>
                          </div>
                          <span className='me-2 fw-bolder'>
                            {blog.start_date?.substring(8, 10)}.{blog.start_date?.substring(5, 7)}.{' '}
                            {blog.start_date?.substring(0, 4)}
                          </span>
                          {/* <span className='text-gray-500 me-2 fw-bolder fs-5'>5 mins read</span> */}
                        </div>

                        <span className='fw-bolder text-dark mb-3 fs-2hx lh-sm '>{blog.title}</span>

                        <div className='fs-5 fw-bold text-gray-600 mt-4 '>
                          <p className='mb-8'>{blog.content}</p>
                        </div>
                      </div>
                      {blog.files.length > 1 ? (
                        <div className='overflow-auto pb-5 '>
                          <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-500px p-7 scroll-x'>
                            {blog.files.map(
                              (
                                el: {path: string; name: string; size: string; id: ID},
                                index: number
                              ) => {
                                if (index !== 0) {
                                  return (
                                    <FileItem
                                      key={el.id}
                                      fileLink={el.path}
                                      name={el.name}
                                      size={el.size}
                                    />
                                  )
                                }
                              }
                            )}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className='d-flex align-items-center border border-dashed card-rounded p-5 p-lg-10 mb-14'>
                        <div className='text-center flex-shrink-0 align-center mx-auto'>
                          <div
                            className='symbol symbol-circle symbol-25px me-3'
                            data-bs-toggle='tooltip'
                            data-bs-boundary='window'
                            data-bs-placement='top'
                            title={data.created_by.name}
                          >
                            {blog.created_by.avatar ? (
                              <div className='symbol symbol-35px symbol-circle'>
                                <img
                                  src={blog.created_by.avatar}
                                  alt={blog.created_by.name}
                                  className=''
                                />
                              </div>
                            ) : (
                              <div
                                className={clsx('symbol-label', 'bg-primary', 'text-white', 'h1')}
                                style={{width: '50px', height: '50px'}}
                              >
                                {blog.created_by.name?.substring(0, 1)}
                              </div>
                            )}{' '}
                          </div>

                          <div className='mb-0'>
                            <a
                              className='text-gray-700 fw-bolder text-hover-primary'
                              onClick={async () => {
                                setProfilUserId(blog.created_by.id)
                                await openProfilModal()
                              }}
                            >
                              {blog.created_by.name}
                            </a>
                            <span className='text-gray-400 fs-7 fw-bold d-block mt-1'>
                              {blog.created_by.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      {controlButtons ? (
                        <div className='d-flex justify-content-left'>
                          <div className='' role='presentation'>
                            <Button className='me-5' variant='primary' onClick={handleShow}>
                              Düzenle
                            </Button>{' '}
                          </div>
                          <div className='' role='presentation'>
                            <Button className='m-auto' variant='primary' onClick={swalDelete}>
                              Sil
                            </Button>{' '}
                          </div>
                          <div className='nav-item h-10 w-150px' role='presentation'>
                            <ButtonActive text={'Dosya Düzenle'} clickFunc={handleShowAtach} />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <BlogViewUsers usersList={data.readers} />
                   
                    </div>
                  </div>

                  <div className='flex-column flex-lg-row-auto w-100 w-xl-300px mb-10'>
                    <Categories />

                    <RecentBlog />
                    <ChatInner data={blog}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddBlog
            handleClose={handleClose}
            update={true}
            blog={{
              id: blog.id,
              title: blog.title,
              start_date: blog.start_date,
              content: blog.content,
              is_public: blog.is_public,
              is_slider: blog.is_slider,
              email_notification: blog.email_notification,
              phone_notification: blog.phone_notification,
              blog_category_id: blog.category.id,
              attachments: blog.attachments,
              files: blog.files,
              assignments: blog.assignments,
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showAtachModal} onHide={handleCloseAtach}>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseAtach} titleHeader={'Dosya Düzenle'} />
          <UpdateAtachments
            handleClose={handleCloseAtach}
            update={true}
            blog={{
              id: blog.id,
              attachments: blog.files,
              banner: blog.files[0],
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
