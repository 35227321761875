import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'

import {CreateSLACategory, UpdateSLACategory} from '../core/_requests'
import swal from 'sweetalert'
import {number} from 'yup'

type Props = {
  sla: any
  supplier_id: any
  handleClose: any
  update: boolean
}

export const AddSLA: FC<Props> = ({sla, supplier_id, handleClose, update}) => {
  let formData = new FormData()
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const [userForEdit] = useState<any>({
    ...sla,
    id: sla.id,
    title: sla.title,
    description: sla.description,
    supplier_id: sla.supplier_id,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log(values)
          await UpdateSLACategory(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'SLA Yönetimi başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values.title)
          formData.append('description', values.description)
          formData.append('response_time', values.response_time)
          formData.append('supplier_id', supplier_id)
          await CreateSLACategory(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'SLA Yönetimi başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.title && formik.errors.title},
                // {
                //   'is-valid': formik.touched.title && !formik.errors.title,
                // }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            {/* end::Input */}
          </div>
          {/* end::Input Başlık */}
          {/* begin::Input kategori */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Yanıt Süresi</label>
            {/* end::Label */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Yanıt Süresi Seç'
              {...formik.getFieldProps('response_time')}
              name='response_time'
              defaultValue={sla.response_time}
            >
              {/* {activity.assigned_by!==''} */}
              <option hidden>Yanıt Süresi Seç</option>
              {numbers.map((i) => {
                return <option key={i} value={i}>{i} saat</option>
              })}
            </select>
            {/* begin::Input Açıklama */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>Açıklama</label>
              {/* end::Label */}
              <textarea
                placeholder='Type Target Details'
                {...formik.getFieldProps('description')}
                name='description'
                rows={8}
                cols={70}
                className='form-control form-control-solid mb-3 mb-lg-0'
              ></textarea>
            </div>
            {/* end::Açıklama */}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='reset'
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting}
              >
                İptal
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Gönder</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
