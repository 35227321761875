import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {DSRNItem} from './components/DSRNItem'
import Chart from 'react-apexcharts'
import {downloadDLP, GetDSRN, GetDSRNStatistics} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'

import {SearchComponent} from '../components/SearchComponent'
import {ChartsWidget3} from './components/ChartsWidget3'
import {AddDSRN} from './add-DSRN/Add-DSRN'
import { TableData } from './components/TableData'

export const DSRN: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [dsrn, setDSRN] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [statistic, setStatistic] = useState<any>(null)
  const [statistic2, setStatistic2] = useState<any>(null)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0
  const [hourly, setHourly] = useState<any>([])
  const [title, setTitle] = useState<any>([])
  let count: any = []
  let count1: any = []
  let count2: any = []
  let count3: any = []
  const [dataTransfe, setDataTransfer] = useState<any>([])
  const [web, setWeb] = useState<any>([])
  const [dam, setDam] = useState<any>([])

  const chart3 = {
    labels: ['< 24 saat', '24 saat < x < 48 saat', '48 saat < x < 72 saat', '> 74 saat'],
    dataLabels: {
      enabled: true,
    },
    chart: {
      events: {
        dataPointSelection: (
          event: any,
          chartContext: any,
          config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}
        ) => {},
      },
    },
  }

  useEffect(() => {
    GetDSRNStatistics('1').then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })
    GetDSRNStatistics('2').then((data) => {
      console.log(data.data)
      pstatisticType3(data.data)
      setStatistic2(data.data)
    })
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetDSRN(link).then((data) => {
      setDSRN(data.data.data)
    })
  }, [refresh, searchKeyClick,show])

  const downloa = () => {
    downloadDLP('').then((data) => {
      console.log(data)
      window.open(data?.data?.url)
    })
  }

  const prdType = (data: any) => {
    count.push(data?.rate_hour_0_24 / data?.total_hour_0_24)
    count.push(data?.rate_hour_24_48 / data?.total_hour_24_48)
    count.push(data?.rate_hour_48_72 / data?.total_hour_48_72)
    count.push(data?.rate_hour_72 / data?.total_hour_72)
    setHourly(count)
  }
  const pstatisticType3 = (data: any) => {
    setTitle([])

    for (let i = 0; i < data.length; i++) {
      count1.push(data[i]?.total_data_transfer)
      count2.push(data[i]?.total_web_access)
      count3.push(data[i]?.total_disable_mod)
      title.push(data[i]?.month + '.' + data[i]?.year.slice(2))
    }
    setDataTransfer(count1)
    setWeb(count2)
    setDam(count3)
    setTitle(title)
  }
  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        {statistic != null ? (
          <div className='row g-5 g-xxl-10'>
            {/* begin::Col */}
            <div className='col-xl-6 col-xxl-7 mb-xl-7 mb-xxl-12'>
              <ChartsWidget3
                className='h-100'
                chartHeight={200}
                categoriesData={title}
                verticalTitle={''}
                horizentalTitle={'Data Transfer & Web Access & DAM Telapleri'}
                pointTitle={'Adet'}
                dataTransfer={dataTransfe}
                web={web}
                dam={dam}
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-5 col-xxl-4 mb-xl-5 mb-xxl-10'>
              <div className='card h-100'>
                <div className='card-body p-3'>
                  <div className='fs-4 fw-bolder'>Talep Karşılama Sürelerine Göre SM Talepleri</div>
                  {/* <div className='fs-4 fw-bold text-gray-400 mb-7'>Haftalık Durum</div> */}
                  <div>
                    <Chart options={chart3} series={hourly} type='pie' />
                  </div>
                </div>
              </div>
            </div>

            {/* end::Col */}
          </div>
        ) : (
          ''
        )}
        <div className='h-150px'>
          <TableData data={statistic2}/>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color:'#50c8da'}}>
                    <th className=''>Task Number</th>
                    <th className=''>Request ID</th>
                    <th className=''>TT SD ID</th>
                    <th className=''>TT Tech</th>
                    <th className=''>Assigned Group</th>
                    <th className=''>Submit Date</th>
                    <th className=''>Close Date</th>
                    <th className=''>Status</th>
                    <th className=''>TT Catalog Name</th>
                    <th className=''>İzin Süresi</th>
                    <th className=''>Action</th>
                    <th className=''>Saat</th>
                    <th className=''>Closure Code</th>
                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {dsrn?.map((dsqn: any) => {
                    return (
                      <>
                        <DSRNItem key={dsqn?.id} isam={dsqn} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddDSRN handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
