import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {KTSVG} from '../../../_theme/helpers'
import {ModalHeader} from '../components/ModalHeader'
import {AddUser} from '../OrganizationalStructure/edit-modal/AddUser'
import {getIssueWidget} from './core/_request'

type Props = {
  user: any
  refreshPage: any
}
export const ProfileInfo: FC<Props> = ({user, refreshPage}) => {
  const [issues, setIssues] = useState<any>([])
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  useEffect(() => {
    getIssueWidget(user.id).then(({data}) => {
      // console.log(data)
      setIssues(data)
    })
  }, [])
  return (
    <>
    
      <div className='flex-column flex-lg-row-auto mb-10 ml-0 w-25'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-2'>
              {/*  <div className='symbol symbol-100px symbol-circle mb-7'>
      <img src='assets/media/avatars/300-6.jpg' alt='image' />
    </div> */}
              <div className=' position-relative mb-2'>
                <div className='symbol symbol-65px symbol-circle w-65px'>
                  {user.avatar ? (
                    <div className='symbol symbol-75px symbol-circle w-75px'>
                      <img src={user.avatar} alt={user.name} className='w-100' />
                    </div>
                  ) : (
                    <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>
                      {user.name?.substring(0, 1)}
                    </div>
                  )}{' '}
                </div>
              </div>
              <span className='fs-3 text-gray-800 fw-bolder mb-3'>{user.name}</span>
              {user.roles.length > 0 ? (
                <div className='mb-3'>
                  <div className='badge badge-lg badge-light-primary d-inline'>
                    {user.roles[0].name}
                  </div>
                </div>
              ) : (
                ''
              )}
              <span className='fs-6 text-gray-800 fw-bolder mt-3'>Atanan işler</span>
            </div>

            <div className='d-flex  justify-content-center '>
              {issues.map((issue: any) => {
                return (
                  <div className='border border-gray-300 border-dashed rounded  py-3 px-2 me-1 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-8 fw-bolder'>{issue.total_issues}</div>
                    </div>

                    <div className='fw-bold fs-8 text-gray-400'>{issue.status_name}</div>
                  </div>
                )
              })}
            </div>
            <div className='d-flex flex-stack fs-4 py-3'>
              <div
                className='fw-bolder rotate collapsible'
                data-bs-toggle='collapse'
                role='button'
                aria-expanded='false'
                aria-controls='kt_user_view_details'
              >
                Detaylar
                {/* <span className='ms-2 rotate-180'>
                  <span className='svg-icon svg-icon-3'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </span> */}
              </div>
              <span data-bs-toggle='tooltip' data-bs-trigger='hover' title='Edit customer details'>
                <a
                  className='btn btn-sm btn-light-primary'
                  data-bs-toggle='modal'
                  onClick={handleShow}
                >
                  Düzenle
                </a>
              </span>
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Organizasyon Birimi</div>
                <div className='text-gray-600'>
                  {user.assignment ? user.assignment.name : 'Super Admin'}
                </div>
                {user.roles.length > 0 ? (
                  <>
                    <div className='fw-bolder mt-5'>Unvan</div>
                    <div className='text-gray-600'>{user.roles[0].name}</div>
                  </>
                ) : (
                  ''
                )}

                <div className='fw-bolder mt-5'>Tecrübe</div>
                <div className='text-gray-600'>{user.experience} yıl</div>
                {user.certificates && user.certificates !== 'undefined' ? (
                  <>
                    <div className='fw-bolder mt-5'>Sertifika Eğitimleri</div>
                    <div className='text-gray-600'>{user.certificates}</div>
                  </>
                ) : (
                  ''
                )}
                {user.competencies !== 'undefined' && user.competencies ? (
                  <>
                    <div className='fw-bolder mt-5'>Yetkinlikler</div>
                    <div className='text-gray-600'>{user.competencies}</div>
                  </>
                ) : (
                  ''
                )}
                <div className='fw-bolder mt-5'>Eğitim Bilgileri</div>
                {user.associate_degree && user.associate_agree !== 'undefined' ? (
                  <>
                    <div className='fw-bolder mt-5'>Ön Lisans</div>
                    <div className='text-gray-600'>{user.associate_degree}</div>
                  </>
                ) : (
                  ''
                )}
                {user.bachelors_degree && user.bachelors_degree !== 'undefined' ? (
                  <>
                    <div className='fw-bolder mt-5'>Lisans</div>
                    <div className='text-gray-600'>{user.bachelors_degree}</div>
                  </>
                ) : (
                  ''
                )}
                {user.masters_degree && user.masters_degree != 'undefined' ? (
                  <>
                    <div className='fw-bolder mt-5'>Yüksek Lisans</div>
                    <div className='text-gray-600'>{user.masters_degree}</div>
                  </>
                ) : (
                  ''
                )}

                {/* <div className='fw-bolder mt-5'>Ülke</div>
    <div className='text-gray-600'></div>

    <div className='fw-bolder mt-5'>Vergi Numarası </div>
    <div className='text-gray-600'></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddUser user={user} update={true} handleClose={handleClose} refreshPage={refreshPage} />
        </Modal.Body>
      </Modal>
    </>
  )
}
