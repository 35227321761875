import  {FC, useEffect, useState} from 'react'
import { NoMatchRecords } from '../../components/NoMatchRecords'
import {getDoc} from '../core/_requests'
import { ListFileItem } from './ListFileItem'
import { ListFolder } from './ListFolder'

type Props = {
  cat: string
  searchKey: string
  setTotalCounts: any
}

export const ListFiles: FC<Props> = ({cat,searchKey,  setTotalCounts}) => {
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () =>{
    refresh ? setRefresh (false) : setRefresh(true)
  }
  const [docs, setDocs] = useState([
    {
      id: '1',
      name: '',
      uuid: '',
      tags: '',
      category: {
        id:1,
        name:''
      },
      attachments: [],
      assignments: [],
      banner: [],
      created_at: '',
      created_by: [],
      files: [
        {
          id: '',
          fileable_type: 'App\\Models\\Document',
          fileable_id: 1,
          uuid: '983a0310-f506-47ef-93c3-7d01a8a6d4af',
          name: 'odev-4.docx',
          mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          type: 'attachments',
          size: 13117,
          path: 'https://sgd.ecodation.com/storage/f6385c777c120fdbebd6dbf4ede5254d/odev-4.docx',
          created_at: '2022-05-28T09:47:55.000000Z',
          created_by: {
            id: 36,
            name: 'Süper Admin',
            email: 'superadmin@ecodation.com',
          },
        },
      ],
    },
  ])
  useEffect(() => {
    let link = '';
    if(cat!==''){
      link = '?document_category_id='+cat;
    }
    if(searchKey!=='')link = '?q='+searchKey;
    getDoc(link).then(({data}) => {
      setTotalCounts(data.meta.total)
      setDocs(data.data)
    })
  }, [cat,searchKey, refresh])

  return (
    <div className='card card-flush'>
      {/* <div className='card-header d-flex justify-content-end pt-8'>


        <div className='card-toolbar'>
          <div
            className='d-flex justify-content-end align-items-center d-none'
            data-kt-filemanager-table-toolbar='selected'
            
          >
            <div className='fw-bolder me-5'>
              <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
              Selected
            </div>
            <button
              type='button'
              className='btn btn-danger'
              data-kt-filemanager-table-select='delete_selected'
            >
              Delete Selected
            </button>
          </div>
        </div>
      </div> */}

      <div className='card-body'>
        {/* <div className='d-flex flex-stack'>
          <div className='badge badge-lg badge-light-primary'>
      
          </div>

          {/* <div className='badge badge-lg badge-primary'>
            <span id='kt_file_manager_items_counter'>82 items</span>
          </div> */}
        {/* </div>  */}
        

        <table
          id='kt_file_manager_list'
          data-kt-filemanager-table='folders'
          className='table align-middle table-row-dashed fs-6 gy-5'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
              {/* <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_file_manager_list .form-check-input'
                    value='1'
                  />
                </div>
              </th> */}
              <th className='min-w-250px'>Adı</th>
              <th className='min-w-10px'>Boyutu</th>
              <th className='min-w-125px'>Sorumlu</th>
              <th className='w-125px'>Kategori</th>
              <th className='w-75px'></th>
            </tr>
          </thead>

          <tbody className='fw-bold text-gray-600'>
            {docs.length<1 ? <NoMatchRecords isTable={true}/> : docs.map((e) => {
              {
                if(e.files.length>1){
                  return <ListFolder el={e} files={e.files} refreshPage={refreshPage} />
                }
                   return e.files.map((file) => {
                    
                      return (
                        <ListFileItem
                          key={file.id}
                          id={e.id}
                          attid={file.id}
                          categoryName={e.category.name}
                          path={file.path}
                          name={file.name}
                          fileName={e.name}
                          size={file.size.toString()}
                          created_by={file.created_by}
                          lastMadied={file.created_by} subitem={false}
                          refreshPage={refreshPage} fileAssignments={e?.assignments} />
                      )
                    })
              }
            })}
            
          </tbody>
        </table>
      </div>
     
    </div>
  )
}
