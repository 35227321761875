import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {ModalHeader} from '../../../components/ModalHeader'
import {AddSupp} from '../../../Supplier/supplier-edit-modal/AddSupp'
import {AddRole} from '../../edit-modal/AddRole'

type Props = {
  refreshPage: any
}
const RoleListToolbar: FC<Props> = ({refreshPage}) => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  const [show, setShow] = useState(false)
  const openAddRolModal = () => {
    setShow(true)
  }
  const closeAddRolModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={openAddRolModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Rol Ekle
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show} onHide={closeAddRolModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddRolModal} titleHeader={'Yeni Rol Ekle'} />
          <AddRole
            role={{
              id: 1,
              name: '',
            }}
            update={false}
            refreshPage={refreshPage}
            handleClose={closeAddRolModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {RoleListToolbar}
