import axios, {AxiosResponse} from 'axios'

const API_URL = 'https://sgd.ecodation.com'
export const SUPPLIER = `${API_URL}/suppliers`
export const SUPPLIER_CATEGORY =`${API_URL}/supplier_categories`
export const COUNTRIES = `${API_URL}/countries`
export const TICKET = `${API_URL}/tickets`
export const SLA = `${API_URL}/sla_categories`
const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

export async function GetCountries(){
    return await axios.get<any>(COUNTRIES)
} 

export async function GetSupplierCategories(){
    return await axios.get<any>(SUPPLIER_CATEGORY)
}

//supplier
export async function GetSuppliers(link:string){
    return await axios.get<any>(SUPPLIER+link)
}

export async function getSuppliers() {
    return await axios.get<any>(SUPPLIER)
}
export async function GetSupplierById(id: any){
    return await axios.get<any>(SUPPLIER+'/'+id)
}

export async function CreateSupplier(supplier: any){
    return await axios.post(SUPPLIER, supplier, config)
}

export async function DeleteSupplier(id:any){
    return await axios.delete(SUPPLIER+'/'+id)
}

export async function UpdateSupplier(id:any, supplier: any){
    return await axios.put(SUPPLIER+'/'+id, supplier)
}

// ticket
export async function CreateTicket(ticket: any){
    return await axios.post(TICKET, ticket, config)
}

export async function GetTickets(id: any){
    return await axios.get(TICKET+'?supplier_id='+id)
}

export async function DeleteTicket(id: any){
    return await axios.delete(TICKET+'/'+id)
}

export async function UpdateTicket(id: any, ticket: any){
    return await axios.put(TICKET+'/'+id,ticket)
}

// SLA

export async function GetSLACategories(supplier_id: any) {
    return await axios.get(SLA+'?supplier_id='+supplier_id)
}

export async function CreateSLACategory(sla: any) {
    return await axios.post(SLA,sla,config)
}

export async function DeleteSLACategory(id: any) {
    return await axios.delete(SLA+'/'+id)
}

export async function UpdateSLACategory(id: any, sla: any) {
    return await axios.put(SLA+'/'+id,sla)
}

//documents
export async function CreateAttachment(id:any, attachments: any) {
    return await axios.post(SUPPLIER+'/'+id+'/attachments', attachments, config)
}

export async function DeleteAttachment(supplier_id : any, file_id: any) {
    return await axios.delete(SUPPLIER+'/'+supplier_id+'/attachments/'+file_id)
}