import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import { ModalHeader } from '../../components/ModalHeader'
import {getDoc} from '../core/_requests'
import {AddFile} from '../doc-edit-modal/AddFile'
import { CardFileItem } from './CardFileItem'


type Props = {
  cat: string
  searchKey: string
  refresh: boolean
}


export const CardFiles: FC<Props> = ({cat,searchKey, refresh}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [dogs, setDocs] = useState([
    {
      id: '1',
      name: 'ln',
      uuid: '',
      tags: '',
      category: {
        id:1,
        name:''
      },
      attachments: [],
      assignments: [],
      banner: [],
      created_at: '',
      created_by: [],
      files: [
        {
          id: '',
          fileable_type: 'App\\Models\\Document',
          fileable_id: 1,
          uuid: '983a0310-f506-47ef-93c3-7d01a8a6d4af',
          name: 'odev-4.docx',
          mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          type: 'attachments',
          size: 13117,
          path: 'https://sgd.ecodation.com/storage/f6385c777c120fdbebd6dbf4ede5254d/odev-4.docx',
          created_at: '2022-05-28T09:47:55.000000Z',
          created_by: {
            id: 36,
            name: 'Süper Admin',
            email: 'superadmin@ecodation.com',
          },
        },
      ],
    },
  ])

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  useEffect(() => {
    console.log(cat);
    let link = '';
    if(cat!==''){
      link = '?document_category_id='+cat;
    }
    if(searchKey!=='')link = '?q='+searchKey;
    console.log(searchKey);
    getDoc(link).then(({data}) => {
      console.log(data)
      setDocs(data.data)
    })

  }, [show,cat,searchKey, refresh])

  return (
    <div className='row mb-6 mb-xl-9'>
      {dogs.map((e) => {
        {
              return (
                <CardFileItem
                  key={e.id}
                  icon='/media/svg/files/pdf.svg'
                  categoryName={e.category.name}
                  name={e.name}
                  date={e.created_at}
                  create_by={e.created_by}
                  files={e.files}
                />
              )

         }
      })}
      <div className='col-sm-6 col-md-3 mb-6 d-flex  align-self-stretch' onClick={handleShow}>
        <div className='dropzone d-flex align-items-center' id='kt_modal_create_ticket_attachments'>
          <div className='dz-message needsclick align-items-center text-center'>
            <div className='ms-4'>
              <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>Dosya Yükleme</h3>
              <span className='fw-bold fs-7 text-gray-400'>
                Dosyayı bu alanı sürükleyip bırakın
              </span>
            </div>
          </div>
        </div>
        {/* <div className='d-flex justify-content-center text-center flex-column p-5'>
        <div className='fs-5 fw-bolder mb-1 ml-4'>Dosya Yükleme</div>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>Dosyayı bu alanı sürükleyip bırakın</div>
      </div> */}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose}/>
          <AddFile
            isUserLoading={false}
            doc={{
              id: undefined,
              name: undefined,
              uuid: undefined,
              tags: undefined,
              category: undefined,
              attachments: undefined,
              assignments: undefined,
              created_at: undefined,
              files: {},
            }}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
