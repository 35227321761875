import moment from 'moment'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserItem} from '../../components/UserItem'
import "./style.css";

function getDate(craeteDat: string) {
  var today = Date()
  var start = moment(craeteDat)
  var end = moment(today)
  return end.diff(start, 'days') > 0 ? end.diff(start, 'days') + ' gün önce' : 'Bugün'
}

type Props = {
  icon: string
  create_by: any
  name: string
  date: string
  files: any
  categoryName: string
}

export const CardFileItem: FC<Props> = ({icon, name, date, create_by, files, categoryName}) => {
  let count = files.length;
  const dowFiles = () => {
    files.forEach(async (f: {path: string}) => {
      console.log(f.path)
      setTimeout(function () {
        window.open(f.path)
      }, 200)
    })
  }

  //renderSwitch(files);
  //  var interval = setInterval(dowFiles, 300, files);
  console.log(files)
  const link = files
  return (
    <div className='col-sm-6 col-md-3 mb-6'>
      <div className='card h-100'>
        <div className='card-body d-flex justify-content-center text-start flex-column p-5'>
          <a
            onClick={() => {
              dowFiles()
            }}
            className='text-gray-800 text-hover-primary d-flex flex-row'
          >
            <div className='symbol symbol-60px mb-6 mr-6'>
              <span className='svg-icon svg-icon-5x svg-icon-primary me-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='70'
                  height='70'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' />
                  <path
                    d='M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
            </div>
            <div className='pl-10 files-count'>
              <div className='fs-5 fw-bolder mb-1 ml-4'>{name}</div>
              <div className='fs-7 fw-bold text-gray-400 mt-auto'>{getDate(date.slice(0, 10))}</div>
              <div className='fs-7 fw-bold text-gray-400 mt-auto'>{categoryName}</div>
              <div className='border border-dashed border-gray-300 rounded py-2 px-3 count'>
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z'
                      fill='currentColor'
                    />
                    <path
                      d='M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>

                <span className='ms-1 fs-7 fw-bolder text-gray-600'>{count}</span>
              </div>
            </div>
          </a>
          <div className='card-footer p-3'>
            <UserItem id={create_by.id} name={create_by.name} email={''} />
          </div>
        </div>
      </div>
    </div>
  )
}
