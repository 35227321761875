import React, {FC, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Card2} from '../../../../_theme/partials/content/cards/Card2'
import {IconUserModel} from '../../../modules/profile/ProfileModels'
import {getFilteredProjects, getProjects} from '../core/requests'
import {Project} from './Project'
import {ProjectTableBody} from './ProjectTableBody'

type Props = {
  modalShow: any
  setCount: any
  directorateID: any
  searchKeyClick: string
  selectedType: string
  selectedStatus: string
  selectedPlan: string
  refreshPage: any
  projects: any
  type: string
}

export const ProjectsTableHeader: FC<Props> = ({
  modalShow,
  setCount,
  directorateID,
  searchKeyClick,
  selectedPlan,
  selectedStatus,
  selectedType,
  type,
  refreshPage,
  projects,
}) => {
  const [refresh, setRefresh] = useState(false)
  const refreshCount = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  return (
    <div className='row w-100'>
      <div className='card card-flush w-100 p-0' style={{width:'100vw'}}>
        <div className='card-body pt-3 overflow-scroll w-100'>
          <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr style={{color: '#50c8da'}}>
                <th className=''>Proje İsmi</th>
                <th className=''>Grup Müdürlüğü</th>
                <th className=''>Müdürlük</th>
                <th className=''>Proje Sınıfı</th>
                <th className=''>Proje Türü</th>
                <th className=''>Proje Kategorisi</th>
                <th className=''>Hedef Başlangıç Tarihi</th>
                <th className=''>Hedef Bitiş Tarihi</th>
                {type === 'tamamlanan' ? <th className=''>Gerçekleşen Başlangıç Tarihi</th> : ''}
                {type === 'tamamlanan' ? <th className=''>Gerçekleşen Bitiş Tarihi</th> : ''}
                {type === 'aday' ? <th className=''>Bütce Türü(CAPEX/OPEX)</th> : ''}
                <th className=''>Proje Amacı</th>
                {type === 'tamamlanan' ? <th className=''>Proje Yöneticisi</th> : ''}
                {type === 'tamamlanan' ? <th className=''>Proje Teknik Yöneticisi</th> : ''}
<th></th>

                {/* <th className=''>FİRMA</th> */}
              </tr>
            </thead>

            <tbody className='fs-6'>
              {projects?.map((project: any) => {
                return (
                  <>
                    <ProjectTableBody
                      key={project.id}
                      project={project}
                      refreshPage={refreshPage}
                      refreshCount={refreshCount}
                      id={project?.id}
                      type={type}
                    />
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
