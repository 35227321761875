import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {KTCard} from '../../../_theme/helpers'
import {UserModel} from '../../modules/auth/models/UserModel'
import { NoMatchRecords } from '../components/NoMatchRecords'
import {UsersListHeader} from './components/userHeader/UsersListHeader'

import {UserItem} from './components/UserItem'
import {GetUsers} from './core/_requests'

export const UsersPage: FC = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      assignment: {
        id: 1,
        name: '',
      },
      roles: [
        {
          id: 1,
          name: '',
        },
      ],
      mobile_number: '',
      phone_number: '',
      experience: 0,
      certificates: '',
      competencies: '',
      associate_degree: '',
      bachelors_degree: '',
      masters_degree: '',
    },
  ])
  const [assignmentId, setAssignmentId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') {
      link = '&q=' + searchKeyClick
    }
    GetUsers(assignmentId,link).then(({data}) => {
      setUsers(data.data)
    })
  }, [refresh, searchKeyClick, assignmentId])

  return (
    <KTCard>
      <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} setAssignmentId={setAssignmentId} />

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0'>
          <div className='card-body pt-3'>
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th></th>
                  <th className=''>AD SOYAD</th>
                  <th className=''>Görev</th>
                  <th className='min-w-200px'>Telefon</th>
                </tr>
              </thead>

              <tbody className='fs-6'>

                {users.length<1 ? <NoMatchRecords isTable={true}/> :
                users.map((user) => {
                  return (
                    <>
                      <UserItem key={user.id} user={user} refreshPage={refreshPage} />
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </KTCard>
  )
}
