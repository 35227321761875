import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {  Modal } from 'react-bootstrap'

import {Link} from 'react-router-dom'
import { ModalHeader } from '../../components/ModalHeader'
import { ProfilePopUp } from '../../Profile/ProfilePopUp'
import {SquareButton} from './SquareButton'


type Props = {
  issue: any
  backgroundColor ? :string
  commentColor ?: string
}

export const TargetItem: FC<Props> = ({issue,backgroundColor,commentColor}) => {
  const [showProfilModal,setShowProfilModal]= useState(false)
  const [profilUserId, setProfilUserId] =  useState(0)
  const closeProfilModal=()=>{
    setShowProfilModal(false)
  }
  const openProfilModal=async ()=>{
    await setShowProfilModal(true)
  }
  return (
    <><div className={`card mb-6 mb-xl-9 border border-${commentColor} ${ commentColor != 'primary' ? 'border-dashed' : '' }` }>
      <div className='card-body  rounded' style={{backgroundColor: backgroundColor}}>
        <div className='d-flex flex-stack mb-3'>
          {/* <div className='badge badge-light'></div> */}

          <div className=''>
            <Link
              to='/faaliyetler/detail'
              state={issue}

            >
              <h5 className='text-hover-primary'>{issue.title}</h5>
            </Link>
          </div>

          <SquareButton status={true} issue={issue} />
          {/* <EditStatus issue={issue}/>  */}
        </div>

        <div className='fs-6 fw-bold text-gray-600 mb-5'>
          {issue.content != null ? issue.content.slice(0,40) : ''}
          {issue.content != null && issue.content.length > 20 ? '...' : ''}
          {/* {issue.content !=null ? issue.content.slice(0, 20)
            (issue.content.length > 20 ? '...' : '') : '' } */}
        </div>
       <div className='d-flex justify-content-between'>
       {issue.assigned != null ? 
        <div className='d-flex justify-content-start'> 
              <div className='me-3 position-relative'>
              <div className='symbol symbol-35px symbol-circle'>
                {issue.assigned.avatar ? (
                  <img alt='Pic' src={issue.assigned.avatar} /> 
                ) : (
                  <div className={clsx('symbol-label fs-3', `bg-${commentColor}`, 'text-white')}>
                    {issue.assigned.name.substring(0, 1)}
                  </div>
                )}
              </div>
            </div>
            <span
              className={`text-${commentColor} fw-bolder mt-3 `}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              style={{cursor: 'pointer'}}
              onClick={async ()=>{setProfilUserId(issue.assigned.id);
                await openProfilModal()}}
            >
              {issue.assigned.name}
            </span>
              </div> :''}
        <div className='d-flex justify-content-end'>
          <div className='d-flex my-1' style={{position:'relative',width:'40px',height:'auto'}}>
            <Link to='/faaliyetler/detail' state={issue}>
              {/* <div className='border border-dashed border-gray-300 rounded py-2 px-3 ms-3'> */}
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='50'
                    height='50'
                    viewBox='0 0 22 22'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                      fill='currentColor' />
                    <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                    <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span>

                <span className={` badge badge-circle bg-${commentColor} ms-1 fs-7 fw-bolder text-white`}  style={{position:'absolute',top:'-10px',right:'0px'}}>{issue.comments.length}</span>
              {/* </div> */}
            </Link>
          </div>
        </div>
       </div>
       
        {/* <Badge badgeContent={5}
        color='primary'
      
        >
        {/* <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 22 22'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                      fill='currentColor' />
                    <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                    <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span> */}
               
{/* </Badge> */}
        
      
      
      </div>
    </div><Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId}  handleClose={closeProfilModal}/>
        </Modal.Body>
      </Modal></>
  )
}
