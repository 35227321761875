import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../_theme/layout/core'
import {UsersListWrapper} from '../../modules/apps/user-management/users-list/UsersList'
import { ErrorComponent } from '../components/ErrorComponent'
import { AssignmentsPage } from './AssignmentsPage'
import { Profile } from '../Profile'
import {RolesPage} from './RolesPage'
import {UsersPage} from './UsersPage'

export const OrganizationPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='personel-listesi'
          element={
            <>
              <PageTitle>Personel Listesi</PageTitle>
              <UsersPage />
            </>
          }
        />

        <Route
          path='roller'
          element={
            <>
              <PageTitle>Roller</PageTitle>
              {/* <ErrorComponent/> */}
              <RolesPage/>
            </>
          }
        />
         <Route path='profile' element={<><PageTitle breadcrumbs={[{title:'Personel Listesi',path:'../organizasyon-yapisi/personel-listesi',isActive:true}]}>/Personel Bilgisi</PageTitle> <Profile/></>}/>
           <Route
          path='atamalar'
          element={
            <>
              <PageTitle>Roller</PageTitle>
              {/* <ErrorComponent/> */}
              <AssignmentsPage/>
            </>
          }
        />
      </Route>
    
      <Route index element={<Navigate to='/personel-listesi' />} />
      {/* <Route path='/personel-listesi/roles' element={<Navigate to='/roles' />} /> */}
    </Routes>
  )
}
