import {FC, useState} from 'react'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {ID, KTSVG} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {AddPhoto} from '../edit-modal/AddPhoto'
import {AddUser} from '../edit-modal/AddUser'
import { ChangePassword } from '../edit-modal/ChangePassword'
import { RolesPage } from '../RolesPage'
import swal from 'sweetalert'
import { DeleteUser } from '../core/_requests'

type Props = {
  user: 
  {
    id: ID
    name: string
    email: string
    avatar: string
    assignment: {
      id: ID
      name: string
    },
    roles:{
      id:ID,
      name: string
    }[],
    mobile_number: string,
    phone_number: string,
    experience: number,
    certificates: string,
    competencies: string,
    associate_degree:string,
    bachelors_degree:string,
    masters_degree:string,

  }
  refreshPage: any
}
export const UserItem: FC<Props> = ({user, refreshPage}) => {
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const [show, setShow] = useState(false)
  const [showPhoto, setShowphoto] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [symbolAnd, setSymbolAnd] = useState('')
  let [counter, setCounter] =useState(1)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => setShow(false)
  const openPhotoModal = () => {
    setShowphoto(true)
  }
  const closePhotoModal = () => {
    setShowphoto(false)
    refreshPage()
  }
  const openPasswordModal = () =>{
    setShowPasswordModal(true)
  }
  const closePasswordModal = () => {
    setShowPasswordModal(false)
    refreshPage()
  }
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteUser(id).then(()=>{
          swal('Silindi!', 'Kullanıcı başarı ile silindi.', 'success', {timer: 2000})
          refreshPage()
        })

        
       
      }
    })
  }
  return (
    <>
      <tr>
        <td>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-65px symbol-circle w-65px'>
              {user.avatar ? (
                <div className='symbol symbol-65px symbol-circle w-65px'>
                  <img src={user.avatar} alt={user.name} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>
                  {user.name?.substring(0, 1)}
                </div>
              )}{' '}
            </div>
          </div>
        </td>
        <td className='fw-bolder'>
          <NavLink className='text-gray-800 text-hover-primary' to='../profile' state={user}>
            {user.name} <br />
            <span className='text-gray-500'>{user.email}</span>
          </NavLink>
        </td>
        {/* {user.roles.length>0 ? user.roles[0].name : ''}  */}
        <td className='text-gray-500'> {user.roles.map((role:any)=>{
     
          // if(counter==1)
          // {
          //   setSymbolAnd('');
          //   setCounter(counter+1)
          //   return(
          //     symbolAnd+role.name
              
          //   )
          // } 
          // else 
          // {
          //   setSymbolAnd(' & ')
          //   setCounter(counter+1)
          return(
            symbolAnd+role.name
            
          )
        // }
          
          
        })}<br />
        <span className='text-gray-800'>{user.assignment !== null && user.assignment.name}</span>
        </td>
        <td className='text-gray-500'>{user.mobile_number}</td>
        {loginUser.name === 'Süper Admin Kullanıcısı' ? (
          <td className='text-end' data-kt-filemanager-table=''>
          <Dropdown>
            <Dropdown.Toggle
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              id='dropdown-basic'
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
              
              >
                 <NavLink to='../profile' state={user} className='menu-link px-3 text-black'>
                Görüntüle
              </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
              onClick={async () => await handleShow()}
              >
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item
               onClick={async () => await openPhotoModal()}
              >
                {' '}
                <span className='menu-link px-3'>Fotoğraf Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item
               onClick={async () => await openPasswordModal()}
              >
                {' '}
                <span className='menu-link px-3'>Şifre Değiştir</span>
              </Dropdown.Item>
              <Dropdown.Item  onClick={async()=> await swalDelete(user.id)}>
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        ) : (
          ''
        )}
       
      
      </tr>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddUser user={{
              id: user.id,
              name:user.name,
              email:user.email,
              avatar:user.avatar,
              assignment :user.assignment,
              certificates:user.certificates,
              experience:user.experience,
              competencies: user.competencies,
              associate_degree:user.associate_degree,
              bachelors_degree:user.bachelors_degree,
              masters_degree:user.masters_degree,
              roles:user.roles,
              mobile_number:user.mobile_number,
              phone_number:user.phone_number
            }}
             update={true} handleClose={handleClose} refreshPage={refreshPage} />
        </Modal.Body>
      </Modal>

      <Modal show={showPhoto} onHide={closePhotoModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closePhotoModal} titleHeader={'Fotoğraf Düzenle'} />
          <AddPhoto user_id={user.id} handleClose={closePhotoModal} doc={user.avatar} />
        </Modal.Body>
      </Modal>

      <Modal show={showPasswordModal} onHide={closePasswordModal}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closePasswordModal} titleHeader={'Şifre Değiştir'} />
          <ChangePassword user={user} handleClose={closePasswordModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
