import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_theme/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardsPageWrapper} from '../pages/dashboards/DashboardsPageWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_theme/assets/ts/_utils'
import {Default} from '../pages/dashboards/Default'
import {PageTitle} from '../../_theme/layout/core'
import {HomePage} from '../pages/Home/HomePage'
import {UserModel} from '../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {Blog} from '../pages/Blog/Blog'
import {ActivitesPageWrapper} from '../pages/Activities/ActivitesPageWrapper'
import {AnnouncementsPageWrapper} from '../pages/Announcements/AnnouncementsPageWrapper'
import {Announcements} from '../pages/Announcements/Announcements'
import {ProjectsPageWrapper} from '../pages/Project/ProjectsPageWrapper'
import {Detail} from '../pages/Project/project-details/Detail'
import {BlogPageWrapper} from '../pages/Blog/BlogPageWrapper'
import { Supplier } from '../pages/Supplier/Supplier'
import { SupplierPageWrapper } from '../pages/Supplier/SupplierPageWrapper'
import { Documents } from '../pages/DocumentsShare/Documents'
import DetailPagesWrapper from '../pages/Project/project-details/DetailPagesWrapper'
import { InventoryPageWrapper } from '../pages/Inventory/InventoryPageWrapper'
import { Inventory } from '../pages/Inventory/Inventory'
import { OrganizationPageWrapper } from '../pages/OrganizationalStructure/OrganizationPageWrapper'
import { BudgetsPageWrapper } from '../pages/Budgets/BudgetsPageWrapper'
import { Profile } from '../pages/Profile'
import UsersPage from '../modules/apps/user-management/UsersPage'
import { OperationalDataWrapper } from '../pages/OperationalData/OperationalDataWrapper'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/ana-sayfa' />} />
        {/* Pages */}
        <Route path='dashboards/*' element={<DashboardsPageWrapper />} />

        <Route
          path='ana-sayfa'
          element={
            <>
     
            <PageTitle>Merhaba{user.name}</PageTitle>
              {/* <PageTitle>Merhaba{user.name}</PageTitle> */}
              <HomePage />
            </>
          }
        />
        <Route
          path='duyurular'
          element={
            <>
              <PageTitle>Merhaba{user.name}</PageTitle>
              <Announcements />
            </>
          }
        />
       <Route path='profile'  element={<Profile/>}/>
        <Route path='duyurular/*' element={<AnnouncementsPageWrapper />} />
        <Route path='faaliyetler/*' element={<ActivitesPageWrapper />} />
        <Route path='projeler/*' element={<ProjectsPageWrapper />} />
        <Route path='projettt/*' element={<ProjectsPageWrapper />} />
        <Route path='blog/*' element={<BlogPageWrapper />} />
        <Route path='dokuman-yonetimi' element={ <><PageTitle>Belgelerim</PageTitle><Documents /></>}  />
        <Route path='tedarikci-bilgileri/*' element={<SupplierPageWrapper/>}/>
        <Route path='envanter-yonetimi' element={<Inventory/>}/>
        <Route path='envanter-yonetimi/*' element={<><PageTitle>Envanter Yönetimi</PageTitle><InventoryPageWrapper /></>}/>
        <Route path='organizasyon-yapisi/*' element={<OrganizationPageWrapper/>}/>
        <Route path='butce/*' element={<BudgetsPageWrapper/>}/>
        <Route path='bilgi-guvenligi-verileri/*' element={<OperationalDataWrapper/>}/>
        <Route path='gorunurluk-izleme-ve-entegrasyon-durumlari/*' element={<OperationalDataWrapper/>}/>
        <Route path='saldiri-yuzey-yonetimi/*' element={<OperationalDataWrapper/>}/>
        <Route path='siber-guvenlik-risk-yonetimi-ve-uyumluluk/*' element={<OperationalDataWrapper/>}/>
        {/* <Route
          path='blog/*'
          element={
            <>
              <PageTitle>Merhaba bey,</PageTitle>
              <BlogPageWrapper />
            </>
          }
        /> */}
              {/* <Route
          path='projeler/*'
          element={
       
              <ProjectsPageWrapper />
 
          }
        /> */}
           <Route path='projeler/detail-project/*' element={
          <>
           <DetailPagesWrapper />
          </>
  
        } />
        {/* <Route
          path='/pages/Project/project-details/Detail'
          element={
            <>
              <PageTitle>Projeler/Proje Detay(Karne)</PageTitle>
              <Detail />
            </>
          }
        /> */}
        <Route
          path='blog'
          element={
            <>
              <PageTitle>Bilgi Paylaşım Platformu</PageTitle>
              <Blog />
            </>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
