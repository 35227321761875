import {FC} from 'react'
import NumberFormat from 'react-number-format'
import {NavLink} from 'react-router-dom'
type Props = {
  item: any
}
export const ATItem: FC<Props> = ({item}) => {
  return (
    <>
      <tr>
        <td className='fw-bolder'>{item?.case_title}</td>
        <td className='text-gray-500'>{item?.responsible_persons}</td>
        <td className='text-gray-500'>{item?.case_system_resource}</td>
        <td className='text-gray-500'>{item?.action_status} </td>
        <td className='text-gray-500'>{item?.action_owner} </td>

        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
      </tr>
    </>
  )
}
