import clsx from "clsx"
import { useFormik } from "formik"
import { FC, useState } from "react"
import swal from 'sweetalert'
import { ChangeUsersPassword } from "../core/_requests"
import * as Yup from 'yup'


type Props ={
    user: any
    handleClose: any
}
export const  ChangePassword : FC<Props> =({user, handleClose}) =>{
    const [userForEdit] = useState<any>({
        ...user
    })
    const passwordSchema = Yup.object().shape({
        password: Yup.string()
          
          .required('Zorunlu alan')
          .min(8, 'Lütfen az 8 karakter giriniz')
          .max(50, 'En fazla 50 karakter olabilir')
      })
    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: passwordSchema,
        onSubmit: async (values, {setSubmitting}) => {
          setSubmitting(true)
          try {
              await ChangeUsersPassword(user.id, values)
                .then(() => {
                  swal('Başarılı', 'Şifre başarı ile değiştirildi.', 'success', {timer: 2000})
                  handleClose()
                })
                .catch((err) => {
                  console.log(err.message)
                  handleClose()
                  swal('Hata!', err.response.data.message, 'error', {timer: 2000})
                })
          } catch (ex) {
            console.error(ex)
          } finally {
            setSubmitting(true)
          }
        },
      })
    return(
        <form
        id='kt_modal_add_user_info_form'
        onSubmit={formik.handleSubmit}
        className='form'
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_info_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_info_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_info_scroll'
          data-kt-scroll-offset='300px'
        >
             <div className='fv-row mb-7' >
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Şifre</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('password')}
              type='password'
              name='password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
            onClick={handleClose}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            // disabled={
            //   (formik.isSubmitting || !formik.isValid || !formik.touched.assignment_id) 
            // }
          >
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
            </div></form>
    )
}