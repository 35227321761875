import Chart from 'react-apexcharts'
import React, {FC, useEffect, useState} from 'react'
import {GetDLPStatistics} from '../../../OperationalData/core/request'
import { bottom } from '@popperjs/core'

type Props = {
  name: string
}

export const PieChart: FC<Props> = ({name}) => {
  let total = 0
  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()
  const [statistic, setStatistic] = useState<any>([])
  const chart3 = {
    labels: title,
    dataLabels: {
      enabled: true,
      color: '#223388',
      font: {
        size: 100,
      }
    },
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'left',
      fontSize: '13rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (
          event: any,
          chartContext: any,
          config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}
        ) => {},
      },
    },

    tooltip: {
      style: {
        fontSize: '20px',
      },
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ' Adet'
        },
      },
    },
  }
  useEffect(() => {
    GetDLPStatistics().then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })
  }, [name])
  const prdType = (data: any) => {
    setTitle([])
    total = 0
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.client_installation_version)
    }
    setPC(count1)
    setTitle(title1)
  }
  return (
    <div className='card h-100'>
      <div className='card-body p-3'>
        <div className='fs-2 fw-bolder'> DLP İstatistikleri </div>
        <div className='fs-4 fw-bold text-gray-400 mb-7'>Haftalık Durum</div>
        <div>
          <Chart options={chart3} series={planC} type='pie' />
        </div>
      </div>
    </div>
  )
}
