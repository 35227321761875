import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {
  CreateSupplier,
  GetCountries,
  GetSupplierCategories,
  UpdateSupplier,
} from '../core/_requests'
import swal from 'sweetalert'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { Modal } from 'react-bootstrap'
import { AddCategory } from '../../Categories/AddCategory'
import { ModalHeader } from '../../components/ModalHeader'
import * as Yup from 'yup'

type Props = {
  supplier: any
  handleClose: any
  refreshPage: any
  update: boolean
}
const supplierSchema = Yup.object().shape({
  name: Yup.string().required('Zorunlu alan'),
  email: Yup.string()
  .email('Lütfen E-Posta adresini doğru şekide giriniz')
  .min(3, 'Lütfen az 3 karakter giriniz')
  .max(50, 'En fazla 50 karakterden oluşmalı')
  .required('Zorunlu alan'),
  address: Yup.string().required('Zorunlu alan'),
  company_name: Yup.string().required('Zorunlu alan'),
  country_id: Yup.string().required('Zorunlu alan'),
  phone: Yup.string().required('Zorunlu alan'),
  supplier_category_id: Yup.string().required('Zorunlu alan'),
  tax_number: Yup.string().required('Zorunlu alan'),
})
export const AddSupp: FC<Props> = ({supplier, handleClose, refreshPage, update}) => {
  const [files, setFiles] = useState<any>([])
  const [emailBox, setEmailbox] = useState(supplier.email_notification)
  const [phoneBox, setPhonebox] = useState(supplier.phone_notification)
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [countries, setCountries] = useState([
    {
      id: 1,
      name: '',
    },
  ])
const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  useEffect(() => {
    GetSupplierCategories().then(async ({data}) => {
      await setCategories(data.data)
    })

    GetCountries().then(async ({data}) => {
      await setCountries(data.data)
    })
  }, [showCategoryModal])
  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }

  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )
  const [userForEdit] = useState<any>({
    ...supplier,
    id: supplier.id,
    name: supplier.name,
    email: supplier.email,
    company_name: supplier.company_name,
    phone: supplier.phone,
    tax_number: supplier.tax_number,
    country_id: supplier.country_id,
    supplier_category_id: supplier.supplier_category_id,
    email_notification: supplier.email_notification,
    phone_notification: supplier.phone_notification,
    attachments: supplier.attachments,
  })

  let formData = new FormData()

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: supplierSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          const UpdSupplier = {
            name: values.name,
            email: values.email,
            company_name: values.company_name,
            phone: values.phone,
            tax_number: values.tax_number,
            country_id: values.country_id,
            supplier_category_id: values.supplier_category_id,
            address: values.address,
            email_notification: emailBox,
            phone_notification: phoneBox
          }
          await UpdateSupplier(supplier.id, UpdSupplier)
            .then(() => {
              handleClose()
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('name', values.name)
          formData.append('email', values.email)
          formData.append('company_name', values.company_name)
          formData.append('phone', values.phone)
          formData.append('tax_number', values.tax_number)
          formData.append('country_id', values.country_id)
          formData.append('supplier_category_id', values.supplier_category_id)
          formData.append('address', values.address)
          formData.append('email_notification', values.email_notification ? '1' : '0')
          formData.append('phone_notification', values.phone_notification ? '1' : '0')
          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
            //console.log(base64);
          }
          await CreateSupplier(formData)
            .then(() => {
              swal('Eklendi', 'Tedarikçi başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
    <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Ad Soyad</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>E-Posta Adresi</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('email')}
              type='email'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Şirket</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('company_name')}
              type='text'
              name='company_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.company_name && formik.errors.company_name},
                {
                  'is-valid': formik.touched.company_name && !formik.errors.company_name,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.company_name && formik.errors.company_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.company_name}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Telefon</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('phone')}
              type='tel'
              name='phone'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phone}</span>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Vergi Numarası</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('tax_number')}
              type='text'
              name='tax_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.tax_number && formik.errors.tax_number},
                {
                  'is-valid': formik.touched.tax_number && !formik.errors.tax_number,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
           {formik.touched.tax_number && formik.errors.tax_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tax_number}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Ülke</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Kategori Seç'
              {...formik.getFieldProps('country_id')}
              name='country_id'
              defaultValue={supplier.country_id}
            >
              <option hidden>Ülke seç</option>
              {countries.map((country) => {
                return (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.country_id && formik.errors.country_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.country_id}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className='row mb-7 d-flex'>
          <div >
            {/* begin::Label */}
            <div className='d-flex justify-content-between'>
            <label className='required fw-bold fs-7 mb-2'>Kategori</label>
            {loginUser.name === 'Süper Admin Kullanıcısı' && (
                <img
                  src='https://cdn-icons-png.flaticon.com/512/992/992651.png'
                  alt='add-icon'
                  width='25px'
                  className=''
                  onClick={openCategoryModal}
                />
              )}
            </div>
          
            {/* end::Label */}

            {/* begin::Input */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Kategori Seç'
              {...formik.getFieldProps('supplier_category_id')}
              name='supplier_category_id'
              defaultValue={supplier.supplier_category_id}
            >
              <option hidden>Kategori Seç</option>
              {categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.supplier_category_id && formik.errors.supplier_category_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.supplier_category_id}</span>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-7 mb-2'>Adres Bilgisi</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Başlık'
            {...formik.getFieldProps('address')}
            type='text'
            name='address'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.address && formik.errors.address},
              {
                'is-valid': formik.touched.address && !formik.errors.address,
              }
            )}
            autoComplete='off'
            //disabled={formik.isSubmitting }
          />
          {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          {/* end::Input */}
        </div>
{update ? '' : (  <div className='fv-row mb-8'>
          <label className='fs-7 fw-bold mb-2 required'>Sözleşme Bilgisi</label>

          <div className='dropzone' id='kt_modal_create_ticket_attachments'>
            <div className='dz-message needsclick align-items-center'>
              <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                    fill='currentColor'
                  />
                  <path
                    d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                    fill='currentColor'
                  />
                  <path
                    d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                    fill='currentColor'
                  />
                  <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                </svg>
              </span>
              {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}
              <div  className='w-100 me-5'>
                <Dropzone
                  onDrop={async (acceptedFiles) => {
                    setFiles(acceptedFiles)
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                            Sözleşme belgesini buraya yükleyin
                          </h3>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
                <ul className='m-0 p-0'>{filess}</ul>
              </aside>
        </div>)}
      

        <div className='mb-7 fv-row'>
          <div className='d-flex flex-stack'>
            <div className='fw-bold me-5'>
              <label className='fs-7'>Bildirimler</label>
              <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
            </div>

            <div className='d-flex align-items-center'>
              <label className='form-check form-check-custom form-check-solid me-10'>
                <input
                  className='form-check-input h-20px w-20px'
                  type='checkbox'
                  {...formik.getFieldProps('email_notification')}
                  name='email_notification'
                  defaultChecked={emailBox}
                  onChange={() => (emailBox ? setEmailbox(false) : setEmailbox(true))}
                />

                <span className='form-check-label fw-bold'>Email</span>
              </label>

              <label className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-20px'
                  type='checkbox'
                  {...formik.getFieldProps('phone_notification')}
                  name='phone_notification'
                  defaultChecked={phoneBox}
                  onChange={() => (phoneBox ? setPhonebox(false) : setPhonebox(true))}
                />
                <span className='form-check-label fw-bold'>Phone</span>
              </label>
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            //disabled={formik.isSubmitting}
            onClick={handleClose}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={(formik.isSubmitting || !formik.isValid || !formik.touched || (files.length < 1 && !update))}
          >
            <span className='indicator-label'>Ekle</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
    <Modal show={showCategoryModal} onHide={closeCategoryModal}>
    <Modal.Body>
      <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
      <AddCategory
        category={{
          id: undefined,
          name: '',
        }}
        update={false}
        handleClose={closeCategoryModal}
        numberMenu='supplier'
      />
    </Modal.Body>
  </Modal>
  </>
  )
}
