import Chart from 'react-apexcharts'
import React, {FC, useEffect, useState} from 'react'
import {GetALStatistics, GetDLPStatistics, GetReportStatistics} from '../../../OperationalData/core/request'
import {bottom} from '@popperjs/core'

type Props = {
  name: string
}

export const PieNOACChart: FC<Props> = ({name}) => {
  let [total, setTotal] = useState<number>(0)
  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()
  const chart3 = {
    labels: title,
    dataLabels: {
      enabled: true,
    },
    legend: {
   
      show: true,
      position: bottom,
      harizontalAlign: 'left',
      fontSize: '13rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (
          event: any,
          chartContext: any,
          config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}
        ) => {},
      },
      size: {
        height: 300,
        width: 200
    }
    },
    tooltip: {
      style: {
        fontSize: '20px',
      },
      y: {
        formatter: function (val: any) {
          return ''
        },
      },
    },
  }
  useEffect(() => {
    GetReportStatistics('/number_of_alarm_cases/','1').then((data) => {
  
      prdType(data.data)

    })
  }, [name])
  const prdType = (data: any) => {
    setTitle([])

      count1.push(data?.total_active)
      count1.push(data?.total_closed)
      title1.push('Aktif: '+data?.total_active+ ' adet ' + data?.total_active_rate+'%')
      title1.push('Kapalı: '+ data?.total_closed+ ' adet ' + data?.total_closed_rate+'%')
 
    setTotal(data?.total_active+data?.total_closed);
    setPC(count1)
    setTitle(title1)
  }
  return (
    <div className='card h-100 ' style={{maxHeight:'350px'}}>
      <div className='card-body p-3'>
        <div className='fs-4 fw-bolder'> Tehdit Sayisi Raporu</div>
        <div className='fs-6 fw-bold text-gray-400 mb-7'>Toplam {total} Adet</div>
        <div>
          <Chart options={chart3} series={planC} type='pie' height={300}/>
        </div>
      </div>
    </div>
  )
}
