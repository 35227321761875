import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_theme/assets/ts/_utils'
import { GetNODStatistics} from '../../../OperationalData/core/request'

type Props = {
  className: string
  chartHeight: number
  type: any
  verticalTitle: string
  horizentalTitle: string
  pointTitle: string
}

export const BarNODChar3: FC<Props> = ({
  className,
  chartHeight,
  type,
  verticalTitle,
  horizentalTitle,
  pointTitle,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [title, setTitle] = useState<any>([])
  const [closeAverage, setCloseAverage] = useState<any>([])
  const [openAverage, setOpenAverage] = useState<any>([])
  const [pendingAverage, setPendingAverage] = useState<any>([])
  const [grafik, setGrafik] = useState(false)
  useEffect(() => {
    GetNODStatistics(type).then((data) => {
      toArray(data.data)
    })
  }, [])
  useEffect(() => {
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, title, closeAverage, openAverage, pendingAverage, verticalTitle, '')
    )
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [grafik])

  const toArray = (data: any) => {
    let titleTemp = []
    let closeAverageTemp = []
    let openAverageTemp = []
    let pendingAverageTemp = []

    for (let i = 0; i < data.length; i++) {
      titleTemp.push('W' + data[i]?.week_of_year)
      closeAverageTemp.push(data[i]?.close_average)
      openAverageTemp.push(data[i]?.open_average)
      pendingAverageTemp.push(data[i]?.pending_average)
    }
    setTitle(titleTemp)
    setCloseAverage(closeAverageTemp)
    setOpenAverage(openAverageTemp)
    setPendingAverage(pendingAverageTemp)
    setGrafik(true)
  }

  return (
    <div className={`card card-flush overflow-hidden ${className}`}>
      {/* begin::Header */}
      <div className='card-header text-center'>
        {/* begin::Title */}
        <h4 className='card-title align-items-center flex-column text-center w-100 m-1 p-1'>
          <span className='card-label fw-bolder text-dark fs-5'>{horizentalTitle}</span>
        </h4>
        {/* end::Title */}
        {/* begin::Toolbar */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
        {/* begin::Statistics */}
        {/* end::Statistics */}
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptions = (
  chartHeight: number,
  title: any,
  loss: any,
  correction: any,
  assured: any,
  verticalTitle: string,
  pointTitle: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: pointTitle,
        data: loss,
      },
      {
        name: pointTitle,
        data: correction,
      },
      {
        name: pointTitle,
        data: assured,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 'auto',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    dataLabels: {
      enabled: false,
    },
    fill: {},
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
    },

    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Çözümlenen','Açık - Ele Alınmamış','Açık - Üzerinde Çalışılan'],
      markers: {
        //  fillColors: ['#00E396', '#775DD0']
      },
    },
    xaxis: {
      categories: title,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,

      labels: {
        rotate: 0,
        rotateAlways: true,
        style: {
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          width: 1,
          dashArray: 1,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      //title:{text: assured},
      tickAmount: 5,
      max: Math.max.apply(Math, loss),
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
        // formatter: function (val) {
        //   return '$' + Number(val / 10).toFixed(1) + 'K'
        // },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
      size: 5,
    },
  }
}
