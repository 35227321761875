import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../_theme/helpers'
import {getUserById} from './core/_request'

type Props = {
  user_id: any
  handleClose: any
}
export const ProfilePopUp: FC<Props> = ({user_id, handleClose}) => {
  const [user, setUser] = useState<any>({
    id: 1,
    name: '',
    email: '',
    avatar: '',
    assignment: {
      id: 1,
      name: '',
    },
    roles: [
      {
        id: 1,
        name: '',
      },
    ],
    mobile_number: '',
    phone_number: '',
    experience: 0,
    certificates: '',
    competencies: '',
    associate_degree: '',
    bachelors_degree: '',
    masters_degree: '',
  })
  useEffect(() => {
    getUserById(user_id).then(async ({data}) => {
      await setUser(data.data)
    })
  }, [])
  return (
    <div className='flex-column flex-lg-row-auto  ml-0 '>
      <div>
        <div className='d-flex justify-content-end'>
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary '
            data-kt-users-modal-action='close'
            onClick={handleClose}
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <h2 className='fw-bolder text-center'>Personel Bilgisi</h2>
      </div>
      <div className='card-body '>
        <div className='d-flex flex-center flex-column '>
          <div className=' position-relative mb-2'>
            <div className='symbol symbol-65px symbol-circle w-65px'>
              {user.avatar ? (
                <div className='symbol symbol-75px symbol-circle w-75px'>
                  <img src={user.avatar} alt={user.name} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>
                  {user.name?.substring(0, 1)}
                </div>
              )}{' '}
            </div>
          </div>
          <span className='fs-3 text-gray-800 fw-bolder mb-3'>{user.name}</span>
          {user.roles.length > 0 ? (
            <div className='mb-3'>
              <div className='badge badge-lg badge-light-primary d-inline'>
                {user.roles[0].name}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='separator'></div>

        <div id='kt_user_view_details' className='collapse show'>
          <div className='pb-5 fs-6'>
            <div className='fw-bolder mt-5'>Organizasyon Birimi</div>
            <div className='text-gray-600'>
              {user.assignment ? user.assignment.name : 'Super Admin'}
            </div>

            <div className='fw-bolder mt-5'>Telefon Numarası</div>
            <div className='text-gray-600'>{user.mobile_number}</div>
{/* 
            <div className='fw-bolder mt-5'>Dahili Telefon Numarası</div>
            <div className='text-gray-600'>{user.phone_number}</div> */}

            <div className='fw-bolder mt-5'>Email</div>
            <div className='text-gray-600'>{user.email}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
