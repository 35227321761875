import { FC, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useSelector, shallowEqual } from "react-redux"
import swal from 'sweetalert'
import { RootState } from "../../../../setup"
import { UserModel } from "../../../modules/auth/models/UserModel"
import { ModalHeader } from "../../components/ModalHeader"
import { DeleteTicket } from "../core/_requests"
import { AddTicket } from "../supplier-edit-modal/AddTicket"

type Props = {
    ticket : any,
    refresh: boolean
    setRefresh: any
    supplier_id: any
}
export const Ticket : FC <Props> =({ticket,refresh,setRefresh,supplier_id})=>{
  const [showTicketModal, setShowticketModal] = useState(false)
  const openTicketModal = () => {
    setShowticketModal(true)
}
const closeTicketModal = () => {
    setShowticketModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
}
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteTicket(id).then(() => {
          swal('Silindi!', 'SLA Yönetimi başarı ile silindi.', 'success')
          refresh ? setRefresh(false) : setRefresh(true)
        })
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    return(
        <>
          <tr >
        <td colSpan={10} className='fs-4'>
          {' '}
          {ticket.case_name} <br />
          <span className='text-gray-300 fs-6'>{ticket.case_detail}</span>
        </td>
        {user.id==ticket.created_by.id || user.id==36 ? (
  <><td className="min-w-200px text-end">
              <Button variant='primary mt-2'
                onClick={() => openTicketModal()}
                className='me-3'
              >Düzenle</Button>
               <Button
                  variant='primary mt-3'
                  onClick={() => {
                    swalDelete(ticket.id)
                  } }
                >
                  Sil
                </Button>
            </td></>
        ): ''}
       
        </tr>
        <Modal show={showTicketModal} onHide={closeTicketModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Ticket-Case Ekle'} />
         <AddTicket ticket={ticket} supplier_id={supplier_id} handleClose={closeTicketModal} update={true}/>
        </Modal.Body>
      </Modal>
        </>
    )
}