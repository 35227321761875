import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_theme/layout/core'
import { Profile } from '../Profile'
import { Supplier } from './Supplier'
import { SupplierDetail } from './SupplierDetail'



const SupplierPageWrapper: React.FC = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='caseticket-takibi'
            element={
              <>
                <PageTitle>Tedarikçi Bilgileri</PageTitle>
                <Supplier/>
              </>
            }
            
          />
          <Route
            path='detail'
            element={
              <>
                <PageTitle  breadcrumbs={[{title:'Tedarikçi Bilgileri',path:'../tedarikci-bilgileri/caseticket-takibi',isActive:true}]}>/Detay</PageTitle>
                <SupplierDetail/>
              </>
            }
          />
           <Route path='profile' element={<Profile/>}/>
        <Route
            path='tedarikci-bilgieri'
            element={
              <>
                <PageTitle>Tedarikçi Bilgileri</PageTitle>
                <Supplier/>
              </>
            }
          />
        </Route>
        
        
        <Route index element={<Navigate to='/tedarikci-bilgileri' />} />
      </Routes>
    )
  }
  
  export {SupplierPageWrapper}