import {useFormik} from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import clsx from 'clsx'

import {shallowEqual, useSelector} from 'react-redux'
import {FC, useEffect, useState} from 'react'
import {CreateTicket, GetSLACategories, UpdateTicket} from '../../../Supplier/core/_requests'
import {CreateTarget, getProjectUsers, getTargetCategories, updateTarget} from '../../core/requests'

type Props = {
  target: any
  target_id: any
  handleClose: any
  update: boolean
  project_id: any
  refreshPage?: any
}
export const AddTarget: FC<Props> = ({target, target_id, handleClose, update, project_id, refreshPage}) => {
  const [emailBox, setEmailbox] = useState(target?.email_notification)
  const [phoneBox, setPhonebox] = useState(target?.phone_notification)
  const [users, setUsers] = useState<any>([])
  const [targetCategories, setTargetCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  useEffect(() => {
    getTargetCategories().then(({data}) => {
      setTargetCategories(data.data)
    })
    getProjectUsers(project_id).then(({data}) => {
      setUsers(data.data)
    })
  }, [])

  let formData = new FormData()

  const [userForEdit] = useState<any>({
    ...target,
    title: target?.title,
    project_id: project_id,
    target_category_id: target?.category?.id,
    start_date: target?.start_date,
    end_date: target?.end_date,
    actual_start_date: target?.actual_start_date,
    actual_end_date: target?.actual_end_date,
    content: target?.content,
    email_notification: target.email_notification,
    phone_notification: target.phone_notification,
    assign_by: target?.assigned?.id,
  })

  const targetSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu alan'),
    target_category_id: Yup.string().required('Zorunlu alan'),
    content: Yup.string().required('Zorunlu alan'),
    assigned_by: Yup.string().required('Zorunlu alan'),
    start_date: Yup.date()
      .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
      .required('Zorunlu alan'),
    end_date: Yup.date()
      .when(
        'start_date',
        (start_date, yup) =>
          start_date &&
          yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
      )
      // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
      // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
      .required('Zorunlu alan'),
    
  })

  const targetSchemaForUpdate = Yup.object().shape({
    // title: Yup.string().required('Zorunlu alan'),
    // target_category_id: Yup.string().required('Zorunlu alan'),
    // content: Yup.string().required('Zorunlu alan'),
    // assigned_by: Yup.string().required('Zorunlu alan'),
    // start_date: Yup.date()
    //   // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    //   .required('Zorunlu alan'),
    // end_date: Yup.date()
    //   .when(
    //     'start_date',
    //     (start_date, yup) =>
    //       start_date &&
    //       yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
    //   )

    //   .required('Zorunlu alan'),
  
    actual_start_date: Yup.date().max(new Date(Date.now()),'Lütfen geçerli bir tarih seçiniz'),
    actual_end_date: Yup.object().notRequired().when(
      'actual_start_date',
      (actual_start_date, yup) => 
        actual_start_date && 
        yup.min(
          new Date(actual_start_date),
          'Lütfen geçerli bir tarih seçiniz'
        )
        .max(
          new Date(Date.now()), 'Lütfen geçerli bir tarih giriniz'
        )
      
    
    )
  })
  const targetSchemaForUpdate2 = Yup.object().shape({
    // title: Yup.string().required('Zorunlu alan'),
    // target_category_id: Yup.string().required('Zorunlu alan'),
    // content: Yup.string().required('Zorunlu alan'),
    // assigned_by: Yup.string().required('Zorunlu alan'),
    // start_date: Yup.date()
    //   // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    //   .required('Zorunlu alan'),
    // end_date: Yup.date()
    //   .when(
    //     'start_date',
    //     (start_date, yup) =>
    //       start_date &&
    //       yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
    //   )

    //   .required('Zorunlu alan'),
  
    actual_start_date: Yup.date().max(new Date(Date.now()),'Lütfen geçerli bir tarih seçiniz')
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: update ?  targetSchemaForUpdate : targetSchema,
    onSubmit: async (values, {setSubmitting}) => {
     
      setSubmitting(true)
      console.log(values.actual_end_date)
      try {
     
        if (update) {
         
          let UpdTarget = {
            title: values.title,
            target_category_id: Number(values.target_category_id),
            start_date: values.start_date,
            end_date: values.end_date,
            actual_start_date: String(values.actual_start_date ? values.actual_start_date : ''),
            actual_end_date:String(values.actual_end_date ? values.actual_end_date : ''),
            content: values.content,
            email_notification: emailBox ? '1' : '0',
            phone_notification: phoneBox ? '1' : '0',
            assign_by: values.assigned_by,
          }
       
          await updateTarget(target.id, UpdTarget)
            .then(() => {
              swal('Düzenlendi', 'Hedef başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
              refreshPage()
              
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values.title)
          formData.append('assign_by', values.assigned_by)
          formData.append('target_category_id', values.target_category_id)
          formData.append('start_date', values.start_date)
          formData.append('end_date', values.end_date)
          formData.append(
            'actual_start_date',
            values.actual_start_date ? values.actual_start_date : ''
          )
          formData.append('actual_end_date', values.actual_end_date ? values.actual_end_date : '')
          formData.append('project_id', project_id)
          formData.append('content', values.content)
          formData.append('email_notification', emailBox ? '1' : '0')
          formData.append('phone_notification', phoneBox ? '1' : '0')
          await CreateTarget(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Hedef başarı ile eklendi.', 'success', {timer: 2000})
              window.location.reload()
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}

        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Hedef Başlık</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Giriniz...'
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.title && formik.errors.title},
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input Başlık */}
            {/* begin::Input kategori */}
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Sorumlu</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('assign_by')}
                name='assigned_by'
                // defaultValue={target.assigned}
              >
                {/* {activity.assigned_by!==''} */}
                <option hidden>Sorumlu Seç</option>
                {users.map((user: any) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.assigned_by && formik.errors.assigned_by && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.assigned_by}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {update ? (
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Gerçekleşen Başlangıç Tarihi</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('actual_start_date')}
                  type='date'
                  name='actual_start_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid':
                        formik.touched.actual_start_date && formik.errors.actual_start_date,
                    },
                    {
                      'is-valid':
                        formik.touched.actual_start_date && !formik.errors.actual_start_date,
                    }
                  )}
                  autoComplete='off'
                  // disabled={formik.isSubmitting || update}
                />
                {formik.touched.actual_start_date && formik.errors.actual_start_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.actual_start_date}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='fw-bold fs-7 mb-2'>Gerçekleşen Bitiş Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('actual_end_date')}
                  type='date'
                  name='actual_end_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.actual_end_date && formik.errors.actual_end_date},
                    {
                      'is-valid': formik.touched.actual_end_date && !formik.errors.actual_end_date,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.actual_end_date && formik.errors.actual_end_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.actual_end_date}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}

          {/* begin::Input Açıklama */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5 required'>Hedef Ayrıntıları</label>
            {/* end::Label */}
            <textarea
              placeholder='Hedef ayrıntılarını giriniz'
              {...formik.getFieldProps('content')}
              name='content'
              rows={4}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Açıklama */}

          <div className=' mb-7 '>
            {/* begin::Input kategori */}

            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Hedef Kategorisi</label>
            {/* end::Label */}
            {/* begin::Input */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Kategori Seç'
              {...formik.getFieldProps('target_category_id')}
              name='target_category_id'
              //defaultValue={activity.assigned_by}
            >
              <option hidden>Kategori Seç</option>
              {targetCategories.map((category: any) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.target_category_id && formik.errors.target_category_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.target_category_id}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirimler</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={emailBox}
                    onChange={() => {
                      emailBox == false ? setEmailbox(true) : setEmailbox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={phoneBox}
                    onChange={() => {
                      phoneBox == false ? setPhonebox(true) : setPhonebox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
