import {FC, useState} from 'react'
import {ProjectInfo} from './components/ProjectInfo'
import {HeaderModel} from './components/HeaderMenu'
import {Budged} from './components/Budget'
import { CreateGroup } from './components/CreateGroup'
import { Documents } from './components/Documents'

type Props = {
  setModalShow: any
  activity: any
  update: boolean
  refreshPage: any
}

export const AddProject: FC<Props> = ({setModalShow, activity, update, refreshPage}) => {
  const [activePage, setActivePage] = useState(1)
  const [form,setForm] = useState<any>(activity);
  const [budget,setBudget] = useState<any>(activity);
  const [formUsers,setFormUsers] = useState<any>([]);
  return (
    <>
      <div style={{width: '80%',height:'80vh',overflow:'scroll'}} className='m-auto'>
        <HeaderModel active={activePage} />


     
        {activePage==1 ? (
          <ProjectInfo setModalShow={setModalShow} project={form} update={update} setNext={setActivePage} setForm={setForm} />
        ) : activePage==2 ?(
          <Budged setModalShow={setModalShow} project={budget} update={update}  setNext={setActivePage} setBudget={setBudget}/>
        ): activePage==3 ? (
          <CreateGroup setModalShow={setModalShow} project={formUsers} update={update} setNext={setActivePage} setUser={setFormUsers} />
        ): <Documents setModalShow={setModalShow} project={activity} update={update} setNext={setActivePage} projeInfo={form} usersGroup={formUsers} budget={budget} handleClose={undefined} refreshPage={refreshPage}/>}
       
      </div>
    </>
  )
}
