import {FC, useState} from 'react'
import {Collapse, Popover} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {ListFileItem} from './ListFileItem'
import {ListSubFile} from './ListSubFile'

type Props = {
  el: any
  files: any
  refreshPage: any
}

export const ListFolder: FC<Props> = ({el, files, refreshPage}) => {
  const [open, setOpen] = useState(false)
  let total = 0;
  let created_by = '';
  files.map((file: any)=>{
    created_by = file.created_by.name;
  })

  return (
    <>
      <tr>
        {/* <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input' type='checkbox' value='1' />
          </div>
        </td> */}

        <td data-order='account' style={{position:'relative'}}>
          <div className='d-flex align-items-center'>
            <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' />
                <path
                  d='M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z'
                  fill='currentColor'
                />
              </svg>
            </span>

            <a
              onClick={() => setOpen(!open)}
              aria-controls='example-collapse-text'
              aria-expanded={open}
              className='text-gray-800 text-hover-primary'
            >
              {el.name}
            </a>
          </div>
        </td>

        <td>{(total / 1000000).toFixed(2)} MB</td>

        <td>{created_by}</td>
        <td>{el.category.name}</td>

      </tr>
      {open? files.map((file:any) => {
                    return (
                      <ListFileItem
                        key={file.id}
                        id={el.id}
                        attid={file.id}
                        categoryName={el.category.name}
                        path={file.path}
                        name={file.name}
                        fileName={el.name}
                        size={file.size.toString()}
                        created_by={file.created_by}
                        lastMadied={file.created_by.name} subitem={true}
                        refreshPage={refreshPage} fileAssignments={el?.assignments}                     />
                    )
                  }):''}
      {/* <Collapse in={open}>
        <div className='w-100' style={{width:'300vw',position:'relative'}} id='example-collapse-text'>
    
          {files.map((file: any) => {
            return (
              <ListSubFile
                key={file.id}
                id = {el.id}
                attid={file.id}
                categoryName={el.category.name}
                path={file.path}
                name={file.name}
                size={file.size.toString()}
                lastMadied={file.created_by.name}
              />
            )
          })}


        </div>
      </Collapse> */}
    </>
  )
}
