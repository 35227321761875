import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import Dropzone from 'react-dropzone'
import {isNotEmpty} from '../../../../_theme/helpers'
import {updateUser} from '../../../modules/apps/user-management/users-list/core/_requests'
import {Tags} from '../components/Tags'
import swal from 'sweetalert'
import {createDoc, getDocCategories} from '../core/_requests'
import {DocModel} from '../model/docModel'
import '../../../pages/style/style2.css'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCategory} from '../../Categories/AddCategory'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  isUserLoading: boolean
  doc: DocModel
  handleClose: any
}
const DocSchema = Yup.object().shape({
  name: Yup.string().required('Adı boş olamaz'),
  document_category_id: Yup.number().required('Kategori boş olamaz'),
})
export const AddFile: FC<Props> = ({isUserLoading, doc, handleClose}) => {
  const cancel = () => {
    handleClose()
  }
  const [roles, setRoles] = useState<any>([])
  const [files, setFiles] = useState<any>([])
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  const [userForEdit] = useState<DocModel>({
    ...doc,
    id: doc.id,
    name: doc.name,
    document_category_id: doc.document_category_id,
    attachments: doc.attachments,
    tags: doc.tags,
    assignments: doc.assignments,
  })

  const getRoles = useCallback(
    (selectRole) => {
      console.log(selectRole)
      setRoles([...roles, selectRole])
    },
    [roles]
  )
  const deleteRole = useCallback(
    (tagIndex) => {
      setRoles(roles.filter((_: any, i: any) => i !== tagIndex))
    },
    [roles]
  )

  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }

  const removeAll = () => {
    setFiles([])
  }

  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )

  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: DocSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateUser(values)
        } else {
          formData.append('name', values.name!)
          formData.append('tags', values.tags!)
          formData.append('document_category_id', values.document_category_id!)
          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
            //console.log(base64);
          }
          for (let i = 0; i < roles.length; i++) {
            formData.append('assignments[' + i + ']', roles[i].id)
            //console.log(base64);
          }
          await createDoc(formData)
            .then(() => {
              swal({
                title: 'Eklendi',
                text: 'Dosya başarı ile eklendi.',
                icon: 'success',
                buttons: ['Tamam', false],
                timer: 2000,
              })
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  useEffect(() => {
    getDocCategories().then(({data}) => {
      setCat(data.data)
    })
  }, [showCategoryModal])
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='modal-body'>
          <div className='form-group'>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Başlık</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Başlık'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
             {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
              {/* end::Input */}
            </div>
            <div className='d-flex flex-column'>
              <div className='row mb-7 d-flex'>
                <div className='col-xs-12 col-md-12'>
                  {/* begin::Label */}
                  <div className='d-flex justify-content-between'>
                    <label className='required fw-bold fs-7 mb-2'>Kategori</label>
                    {loginUser.name === 'Süper Admin Kullanıcısı' && (
                      <img
                        src='https://cdn-icons-png.flaticon.com/512/992/992651.png'
                        alt='add-icon'
                        width='25px'
                        className='m-1'
                        onClick={openCategoryModal}
                      />
                    )}
                  </div>

                  {/* end::Label */}

                  {/* begin::Input */}
                  <select
                    className='form-select form-select-solid'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Kategori Seç'
                    {...formik.getFieldProps('document_category_id')}
                    name='document_category_id'
                  >
                    <option hidden> Kategori Seç </option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.document_category_id && formik.errors.document_category_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.document_category_id}</span>
                </div>
              </div>
            )}
                </div>
              </div>
              <div className='d-flex flex-column mb-8 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                  <span className='required'>Erişim Sağlayabilecek Roller</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify a target priorty'
                  ></i>
                </label>

                <div className='form-control form-control-solid'>
                  <Tags setRoles={getRoles} delRole={deleteRole} />
                </div>
              </div>

              <div className='w-50'>
                <Dropzone
                  onDrop={async (acceptedFiles) => {
                    console.log(acceptedFiles)
                    setFiles(acceptedFiles)
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()} className='btn btn-sm btn-primary me-2 '>
                        Dosya Yükle
                        <input
                          style={{opacity: '0', position: 'absolute', zIndex: '9999', left: '70px'}}
                          {...getInputProps()}
                        />
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <span className='form-text fs-6 text-muted'>
                Maksimum dosya boyutu her dosya için 10MB'tır.{' '}
              </span>
            </div>
            <aside>
              <ul className='m-0 p-0'>{filess}</ul>
            </aside>
            <div className='d-flex justify-content-end'>
              {' '}
              {files.length > 0 && (
                <span onClick={removeAll} className='btn btn-sm btn-primary me-2 '>
                  Hepsini Sil
                </span>
              )}
            </div>
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                İptal
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={
                  isUserLoading ||
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.touched ||
                  files.length < 1
                }
              >
                <span className='indicator-label'>Gönder</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal show={showCategoryModal} onHide={closeCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='document'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
