import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import {useSelector, shallowEqual} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import {RootState} from '../../../../setup'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ButtonActive} from '../../Activities/components/ButtonActive'
import {FileItem} from '../../Announcements/components/fileItem'
import {ModalHeader} from '../../components/ModalHeader'
import {DeleteInventory, GetInventory} from '../core/_requests'
import {AddInventory} from '../inventory-edit-modal/AddInventory'
import {UpdateAtachments} from '../inventory-edit-modal/UpdateAtachments'

type LocationState = {
  id: ID
  files: any
  created_by: {
    id: number
    name: string
    email: string
    avatar: string
  }
}
export const InventoryDetail: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  let data = location.state as LocationState
  const [showAtachModal, setShowAtachModal] = useState(false)
  const [controlButtons, setControlbuttons] = useState(false)
  const [inventory, setInventory] = useState({
    id: 1,
    product_name: '',
    product_code: '',
    quantity: '',
    amount: '',
    currency: '',
    category: {
      id: 1,
      name: '',
    },
    supplier: {
      id: 1,
      name: '',
      company_name: '',
      email: '',
    },
    start_date: '',
    end_date: '',
    contract_date: '',
    product_image: '',
    files: data.files,
  })
  const handleClose = () => setShow(false)
  const handleCloseAtach = () => setShowAtachModal(false)
  const handleShow = () => setShow(true)
  const handleShowAtach = () => setShowAtachModal(true)

  useEffect(() => {
    if (data.created_by.id == user.id || user.id === 36) {
      setControlbuttons(true)
    }
    GetInventory(data.id?.toString()).then(({data}) => {
      console.log(data.data)
      setInventory(data.data)
    })
  }, [show, showAtachModal])

  // Delete inventory and route to inventories
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteInventory(inventory.id)
        swal('Silindi!', 'Envanter Yönetimi başarı ile silindi.', 'success')
        navigate('/envanter-yonetimi')
      }
    })
  }
  const imgLink = inventory.files.length
    ? inventory.files[0].path
    : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div>
        <div className='container '>
          {/* <div
            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-325px'
            style={{
              backgroundImage: `url(${imgLink})`,
            }}
          ></div> */}
          {/* <div
            id='kt_project_targets_card_pane'
            className='app-main flex-column flex-row-fluid mt-5'
          >
            <div className='d-flex flex-column flex-column-fluid'>
              <div className='app-content flex-column-fluid'>
                <div className='app-container container-fluid'>
                  <div className='d-flex flex-column flex-xl-row'> */}
                    <div className='card  bg-body  pb-lg-18 '>
                      <div className='card-body  min-w-300px row'>
                        <div className='mb-13 col-xl-6'>
                          <table>
                            <tbody>
                              <tr className='mb-5'>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6 '>
                                  Ürün Adı:
                                </td>
                                <td className='fw-bolder fs-6 text-dark'>
                                  {inventory.product_name}
                                </td>
                              </tr>
                              <tr className='mb-5 '>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  Ürün Kodu:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  {inventory.product_code}
                                </td>
                              </tr>
                              <tr className='mb-5'>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6 '>
                                  Adedi:
                                </td>
                                <td className='fw-bolder fs-6 text-dark'>{inventory.quantity}</td>
                              </tr>
                              <tr className='mb-5 '>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  Tutar:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  <NumberFormat
                                    value={inventory.amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  {inventory.currency}
                                </td>
                              </tr>
                              <tr className='mb-5'>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6 '>
                                  Kategori:
                                </td>
                                <td className='fw-bolder fs-6 text-dark'>
                                  {inventory.category.name}
                                </td>
                              </tr>
                              <tr className='mb-5 '>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  Başlangıç Tarihi:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  {inventory.start_date.substring(8, 10)}.
                                  {inventory.start_date.substring(5, 7)}.
                                  {inventory.start_date.substring(0, 4)}
                                </td>
                              </tr>
                              <tr className='mb-5'>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6 '>
                                  Bitiş Tarihi:
                                </td>
                                <td className='fw-bolder fs-6 text-dark'>
                                  {' '}
                                  {inventory.end_date.substring(8, 10)}.
                                  {inventory.end_date.substring(5, 7)}.
                                  {inventory.end_date.substring(0, 4)}
                                </td>
                              </tr>
                              <tr className='mb-5 '>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  Sözleşme Tarihi:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  {inventory.contract_date.substring(8, 10)}.
                                  {inventory.contract_date.substring(5, 7)}.
                                  {inventory.contract_date.substring(0, 4)}
                                </td>
                              </tr>
                              <br />
                              <tr className='text-center mt-5 mx-auto'>
                                <h3 className='text-center'>Tedarikçi Bilgileri</h3>
                              </tr>
                              <tr className='mb-5 min-w-200px'>
                                <td className='col-lg-4 fw-bold text-muted  fs-6 '> Adı:</td>
                                <td className='fw-bolder fs-6 text-dark'>
                                  {inventory.supplier.name}
                                </td>
                              </tr>
                              <tr className='mb-5  min-h-0px'>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  E-Posta:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  {inventory.supplier.email}
                                </td>
                              </tr>
                              <tr className='mb-5 min-h-20px '>
                                <td className='col-lg-4 fw-bold text-muted min-w-200px fs-6'>
                                  Şirket Adı:
                                </td>
                                <td className='fw-bolder  text-dark fs-6'>
                                  {inventory.supplier.company_name}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded  max-w-75  min-h-325px col-xl-5'
            style={{
              backgroundImage: `url(${imgLink})`,
              backgroundSize: '100% 100%'
            }}
          ></div> 
           </div>
                        {inventory.files.length > 1 ? (
                          <div className='overflow-auto pb-5 m-3'>
                            <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-600px p-7  scroll-x'>
                              {inventory.files.map(
                                (
                                  el: {path: string; name: string; size: string; id: ID},
                                  index: number
                                ) => {
                                  if (index !== 0)
                                    return (
                                      <FileItem
                                        key={el.id}
                                        fileLink={el.path}
                                        name={el.name}
                                        size={el.size}
                                      />
                                    )
                                }
                              )}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {controlButtons ? (
                          <div className='d-flex justify-content-left m-3'>
                            <div className='d-flex justify-content-between'>
                              <div className='nav-item h-10 me-5 mt-10' role='presentation'>
                                <ButtonActive text={'Düzenle'} clickFunc={handleShow} />
                              </div>
                              <div className='nav-item h-10  mt-10' role='presentation'>
                                <ButtonActive text={'Sil'} clickFunc={swalDelete} />
                              </div>
                              <div className='nav-item h-10 w-150px   mt-10' role='presentation'>
                                <ButtonActive text={'Dosya Düzenle'} clickFunc={handleShowAtach} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      
                    
     
                    </div>
                  </div>
                </div>
              {/* </div>
            </div>
          </div>
        </div>
      </div> */}
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddInventory
            inventory={{
              id: inventory.id,
              product_name: inventory.product_name,
              product_code: inventory.product_code,
              quantity: inventory.quantity,
              amount: inventory.amount,
              currency: inventory.currency,
              start_date: inventory.start_date,
              end_date: inventory.end_date,
              contract_date: inventory.contract_date,
              supplier_id: inventory.supplier.id,
              inventory_category_id: inventory.category.id,
              attachments: inventory.files,
            }}
            update={true}
            handleClose={handleClose}
            refreshPage={undefined}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showAtachModal} onHide={handleCloseAtach}>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseAtach} titleHeader={'Dosya Düzenle'} />

          <UpdateAtachments
            handleClose={handleCloseAtach}
            update={true}
            inventory={{
              id: data.id,
              attachments: inventory.files,
              banner: inventory.files[0],
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
