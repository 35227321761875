/* eslint-disable jsx-a11y/anchor-is-valid */
import {userInfo} from 'os'
import React, {useEffect, useState} from 'react'
import {Dropdown, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_theme/helpers'
import {Dropdown1} from '../../../../../../_theme/partials'

import swal from 'sweetalert'
import {deleteTarget, GetTarget} from '../../../core/requests'
import {SquareButton} from '../components/SquareButton'
import {ModalHeader} from '../../../../components/ModalHeader'
import {AddTarget} from '../../add-edit-modals/addTarget'

type Props = {
  className: string
  project_id: any
  refreshPage: any
  targetContent: boolean
}

const ListTargets: React.FC<Props> = ({className, project_id, refreshPage, targetContent}) => {
  const [showTicketModal, setShowticketModal] = useState(false)
  // const [refresh, setRefresh] = useState(false)
  // const refreshComponent = () => (refresh ? setRefresh(false) : setRefresh(true))
  const openTicketModal = (data: any) => {
    setShowticketModal(true)
    setUpdateTarget(data)
  }
  const closeTicketModal = () => {
    setShowticketModal(false)
    refreshPage()
    // refresh ? setRefresh(false) : setRefresh(true)
  }
  const [targets, setTargets] = useState<any>([])
  const [updateTarget, setUpdateTarget] = useState<any>([])
  const [update, setUpdate] = useState(false)
  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        console.log(id)
        await deleteTarget(id).then(() => {
          setUpdate(!update)
          refreshPage()
          swal('Silindi!', 'Blog başarı ile silindi.', 'success', {timer: 2000})
        })
      }
    })
  }
  let id = JSON.parse(localStorage.getItem('projectID') || '{}')
  useEffect(() => {
    setTargets(project_id)
    // GetTarget(id).then(({data}) => {
    //   setTargets(data.data)
    // })
  }, [update, showTicketModal, project_id])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Hedefler</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
          {/* Tümünü Gör
        </button>  */}
          {/* <Link to='stakeholders' state={project_id}>
            Tümünü gör
          </Link> */}

          {/*  <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {targets?.map((target: any, index: React.Key | null | undefined) => {
          return (
            <div key={index} className='d-flex align-items-center justify-content-between mb-7'>
              {/* begin::Avatar */}
              {/* <div className='symbol symbol-55px symbol-circle w-85px'>
            {user.avatar ? ( <div className='symbol symbol-50px me-5'>
              <img src={user.avatar} className='' alt={user.name} />
            </div>):(<div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2  symbol-40px me-5'>
                  {user.name?.substring(0, 1)}
                </div>)}
           </div> */}
              {/* end::Avatar */}
              {/* begin::Text */}

              <div className='flex-grow-1'>
                <div className='row ' style={{height : '50px'}}>
                  <div className='col-xl-5'>
                    <Link
                      to='../target-detail'
                      state={target}
                      className='text-dark fw-bolder fs-6 '
                    >
                      {target?.title}
                    </Link>
                  </div>
                
                  <div className='d-flex col-xl-7 justify-content-end'>
                    {/* <div className='d-flex justify-content-end'> */}
                    <Link
                      to='../target-detail'
                      state={target}
                      className='text-dark fw-bolder fs-6 '
                    >
                      <div
                        className='d-flex my-2 me-2'
                        style={{position: 'relative', width: '35px', height: 'auto'}}
                      >
                        {/* <div className='border border-dashed border-gray-300 rounded py-2 px-3 ms-3'> */}
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='50'
                            height='50'
                            viewBox='0 0 22 22'
                            fill='none'
                          >
                            <path
                              opacity='0.3'
                              d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                              fill='currentColor'
                            />
                            <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                            <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                          </svg>
                        </span>

                        <span
                          className='badge badge-circle bg-primary  fs-8 fw-bolder text-white '
                          style={{position: 'absolute', top: '-6px', right: '0px', width: '20px', height: '20px'}}
                        >
                          {target?.comments?.length}
                        </span>
                        {/* </div> */}
                      </div>
                    {/* </div> */}
                    {/* <div>
                      <div className='border border-dashed border-gray-300 rounded py-2 px-3 me-3'>
                        <span className='svg-icon svg-icon-3'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              opacity='0.3'
                              d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                              fill='currentColor'
                            />
                            <rect x='6' y='12' width='7' height='2' rx='1' fill='currentColor' />
                            <rect x='6' y='7' width='12' height='2' rx='1' fill='currentColor' />
                          </svg>
                        </span>

                        <span className='ms-1 fs-7 fw-bolder text-gray-600 me-3'>
                          {target?.comments?.length}
                        </span>
                      </div>
                    </div> */}
                    </Link>
                    <div>
                      <span
                        className={`badge badge-light-primary fw-bolder me-auto px-4 py-2 mr-10 h-30px me-3`}
                      >
                        {target?.status?.name}
                      </span>
                    </div>

                    <div className='text-end ' style={{marginLeft: '10px'}}>
                      <Dropdown style={{position: 'static'}}>
                        <Dropdown.Toggle
                          className='btn btn-light btn-active-light-primary btn-sm'
                          id='dropdown-basic'
                        >
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {/* <Dropdown.Item>
                            {' '}
                            <SquareButton
                              status={true}
                              issue={target?.id}
                              status_id={target?.status?.id} refreshPage={refreshPage}                            />
                          </Dropdown.Item> */}
                          <Dropdown.Item onClick={() => openTicketModal(target)}>
                            Düzenle
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => swalDelete(target?.id)}
                            className='text-danger'
                          >
                            Sil
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
               
                </div>
                <span className='text-muted d-block fw-bold mt-3'>
                  {targetContent
                    ? target?.content
                    : target?.content?.slice(0, 60) + (target?.content?.length > 60 ? '...' : '')}
                </span>
              </div>

              {/* end::Text */}
            </div>
          )
        })}
      </div>
      {/* end::Body */}
      <Modal
        show={showTicketModal}
        onHide={closeTicketModal}
        style={{position: 'absolute', top: '0px'}}
      >
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Hedef Düzenle'} />
          <AddTarget
            target={updateTarget}
            target_id={updateTarget.id}
            handleClose={closeTicketModal}
            update={true}
            project_id={id}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {ListTargets}
