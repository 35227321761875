import { auto } from '@popperjs/core'
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddDoc} from '../Budget-edit/AddDoc'
import {AddOrder} from '../Budget-edit/AddOrder'
import {AddPurchase} from '../Budget-edit/AddPurchase'
import {AddPurchaseSteps} from '../Budget-edit/AddPurchaseStep'

type Props = {
  active: number
  setActive: any
  budget_id: any
  purchases: any
  orders: any
  purchase_steps: any
  agreements: any
  refresh: boolean
  setRefresh: any
}
export const Slider: FC<Props> = ({
  active,
  setActive,
  purchases,
  budget_id,
  orders,
  refresh,
  setRefresh,
  agreements,
  purchase_steps,
}) => {
  const [showOrderModal, setOrderModal] = useState(false)
  const [showPurchaseModal, setShowPurchaseModal] = useState(false)
  const [showPurchaseStepsModal, setShowPurchaseStepsModal] = useState(false)
  const [showDocModal, setShowDocModal] = useState(false)

  const openOrderModal = () => {
    setOrderModal(true)
  }
  const closeOrderModal = () => {
    setOrderModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const openPurchaseModal = () => {
    setShowPurchaseModal(true)
  }
  const closePurchaseModal = () => {
    setShowPurchaseModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }

  const openDocModal = () => {
    setShowDocModal(true)
  }

  const closeDocModal = () => {
    setShowDocModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const openPurchaseStepsModal = () => {
    setShowPurchaseStepsModal(true)
  }
  const closePurchaseStepsModal = () => {
    setShowPurchaseStepsModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  return (
    <>
      <div className='w-200'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-2'>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_tab'
              onClick={() => setActive(1)}
            >
              Satın Alma
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 4 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
              onClick={() => setActive(4)}
            >
              Satın Alma Takip
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 2 ? 'active' : ''}`}
              data-kt-countup-tabs='true'
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_security'
              onClick={() => setActive(2)}
            >
              Sipariş
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 3 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
              onClick={() => setActive(3)}
            >
              Sözleşme
            </a>
          </li>
         
        
          <li className='d-flex justify-content-end w-200px me-0 pe-0'>
            <div className='d-flex ' style={{position: 'absolute', right: '30px'}}>
              {active == 1 ? (
                <Button className='' variant='primary' onClick={openPurchaseModal}>
                  Satın Alma Ekle
                </Button>
              ) : active == 2 ? (
                <Button className='' variant='primary' onClick={openOrderModal}>
                  Sipariş Ekle
                </Button>
              ) : active == 3 ? (
                <Button className='' variant='primary' onClick={openDocModal}>
                  Sözleşme Ekle
                </Button>
              ) : (
                <Button className='' variant='primary' onClick={openPurchaseStepsModal}>
                  Satın Alma Takip
                </Button>
              )}
            </div>
          </li>
        </ul>
      </div>
      <Modal show={showPurchaseModal} onHide={closePurchaseModal}>
        <Modal.Body>
          <ModalHeader handleClose={closePurchaseModal} titleHeader={'Satın Alma Ekle'} />
          <AddPurchase
            handleClose={closePurchaseModal}
            purchase={{
              budget_id: budget_id,
              supplier: {
                id: 0,
                name: '',
              },
              budget_code: '',
              request_number: '',
              currency: '',
              amount: 0,
              try_amount: 0,
              status: '0',
              purchase_by: {
                id: 0,
                name: '',
              },
            }}
            update={false}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showOrderModal} onHide={closeOrderModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeOrderModal} titleHeader={'Sipariş Ekle'} />
          <AddOrder
            handleClose={closeOrderModal}
            order={{
              budget_id: budget_id,
              order_number: '',
              currency: 'USD',
              amount: 0,
              try_amount: 0,
              saving_currency: 'TRY',
              saving_amount: 0,
            }}
            update={false}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDocModal} onHide={closeDocModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeDocModal} titleHeader={'Sipariş Ekle'} />
          <AddDoc budget_id={budget_id} handleClose={closeDocModal} doc={{}} />
        </Modal.Body>
      </Modal>

      <Modal show={showPurchaseStepsModal} onHide={closePurchaseStepsModal}>
        <Modal.Body>
          <ModalHeader
            handleClose={closePurchaseStepsModal}
            titleHeader={'Satın Alam Takip Ekle'}
          />
          <AddPurchaseSteps
            handleClose={closePurchaseStepsModal}
            purchaseSteps={{
              id: 0,
              budget_id: budget_id,
              sgd_status: '',
              purchase_status: '',
              description: '',
            }}
            update={false}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
