import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {CreateTicket, GetSLACategories, UpdateTicket} from '../core/_requests'
import swal from 'sweetalert'
import clsx from 'clsx'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../modules/auth/models/UserModel'

type Props = {
  ticket: any
  supplier_id: any
  handleClose: any
  update: boolean
}
export const AddTicket: FC<Props> = ({ticket, supplier_id, handleClose, update}) => {
  const [emailBox, setEmailbox] = useState(ticket.email_notification)
  const [phoneBox, setPhonebox] = useState(ticket.phone_notification)
  const [sla, setSla] = useState([
    {
      id: 1,
      title: '',
      response_time: '',
    },
  ])
  useEffect(() => {
    GetSLACategories(supplier_id).then(({data}) => {
      setSla(data.data)
    })
  }, [])

  let formData = new FormData()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  console.log(ticket)
  const [userForEdit] = useState<any>({
    ...ticket,
    id: ticket.id,
    sla_category_id: ticket.sla_category_id,
    case_name: ticket.case_name,
    product_name: ticket.product_name,
    start_date: ticket.start_date,
    end_date: ticket.end_date,
    case_detail: ticket.case_detail,
    is_service_interruption: ticket.is_service_interruption,
    status: ticket.status,
    email_notification: ticket.email_notification,
    phone_notification: ticket.phone_notification,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          let UpdTicket={
            case_name: values.case_name,
            product_name: values.product_name,
            status: values.status =='0' ? false :true,
            is_service_interruption: values.is_service_interruption =='0' ? false :true,
            start_date: values.start_date,
            end_date: values.end_date,
            sla_category_id: values.sla_category_id,
            case_detail: values.case_detail,
            email_notification: emailBox,
            phone_notification: phoneBox,
            supplier_id: supplier_id,
            assignment_id: user.id

          }
          await UpdateTicket(ticket.id, UpdTicket)
            .then(() => {
              swal('Düzenlendi', 'Ticket-Case başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('case_name', values.case_name)
          formData.append('product_name', values.product_name)
          formData.append('status', values.status)
          formData.append('is_service_interruption',values.is_service_interruption)
          formData.append('start_date',values.start_date)
          formData.append('end_date',values.end_date)
          formData.append('sla_category_id', values.sla_category_id)
          formData.append('case_detail', values.case_detail)
          formData.append('email_notification',emailBox ? '1' : '0')
          formData.append('phone_notification',phoneBox ? '1' : '0')
          formData.append('supplier_id',supplier_id)
          formData.append('assignment_id',user.id.toString())
          await CreateTicket(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Ticket-Case başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
     
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Case Adı</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Giriniz...'
                {...formik.getFieldProps('case_name')}
                type='text'
                name='case_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.title && formik.errors.title},
                  // {
                  //   'is-valid': formik.touched.title && !formik.errors.title,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {/* {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )} */}
              {/* end::Input */}
            </div>
            {/* end::Input Başlık */}
            {/* begin::Input kategori */}
            <div className='col-xs-12 col-md-6'>
              <label className='required fw-bold fs-7 mb-2'>Ürün Adı</label>
              {/* end::Label */}
              <input
                placeholder='Giriniz...'
                {...formik.getFieldProps('product_name')}
                type='text'
                name='product_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.title && formik.errors.title},
                  // {
                  //   'is-valid': formik.touched.title && !formik.errors.title,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Case Durumu</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('status')}
                name='status'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Durumu Seç
                </option>
                <option value='0'>Kapalı</option>
                <option value='1'>Açık</option>
              </select>
              {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
            {/* end::Input Sorumlu */}

            {/* begin::Input kategori */}
            <div className='col-xs-12 col-md-6'>
              <label className='required fw-bold fs-7 mb-2'>Hizmet/Servis Kesintisi Var Mı?</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Kategori Seç'
                {...formik.getFieldProps('is_service_interruption')}
                name='is_service_interruption'
                //defaultValue={activity.issue_category_id}
              >
                <option selected disabled hidden>
                  Durum Seç
                </option>
                <option value='0'>Hayır</option>
                <option value='1'>Evet</option>
              </select>
            </div>

            {/* end::Input Kategori */}
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.name && formik.errors.name},
                  // {
                  //   'is-valid': formik.touched.name && !formik.errors.name,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || update}
              />
              {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.name && formik.errors.name},
                  // {
                  //   'is-valid': formik.touched.name && !formik.errors.name,
                  // }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className=' mb-7 fv-row'>
            <label className='required fw-bold fs-7 mb-2'>SLA Kategorisi</label>
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Sla Kategorisi seç'
              {...formik.getFieldProps('sla_category_id')}
              name='sla_category_id'
              defaultValue={ticket.sla_category_id}
            >
              <option hidden>SLA Kategorisi Seç</option>

              {sla.map((s) => {
                return (
                  <option value={s.id} key={s.id}>
                    {s.title}
                  </option>
                )
              })}
            </select>
          </div>

          {/* begin::Input Açıklama */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Case Detayı</label>
            {/* end::Label */}
            <textarea
              placeholder='Type Target Details'
              {...formik.getFieldProps('case_detail')}
              name='case_detail'
              rows={4}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>
          </div>
          {/* end::Açıklama */}

          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirimler</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={emailBox}
                    onChange={() => {
                      emailBox == false ? setEmailbox(true) : setEmailbox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={phoneBox}
                    onChange={() => {
                      phoneBox == false ? setPhonebox(true) : setPhonebox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
