import { USERS } from './../Activities/core/_requests';
import axios, {AxiosResponse} from 'axios'

const API_URL = 'https://sgd.ecodation.com'
export const GET_USERS = `${API_URL}/users`
export const GET_ROLE = `${API_URL}/roles`
export const GET_ASSIGN=  `${API_URL}/assignments`
export const ISSUE_WIDGETS=`${API_URL}/widgets/issues`
export const BUDGET_WIDGETS=`${API_URL}/widgets/budgets`
export const PROJECT_WIDGETS =`${API_URL}/widgets/projects`
export const WIDGETS= `${API_URL}/widgets`


const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  export async function getUsers() {
    return await axios.get<any>(GET_USERS)
}
export async function getUser(user_id:any){
  return await axios.get<any>(GET_USERS+'/'+user_id)
}

export async function getRoles() {
  return await axios.get<any>(GET_ROLE)
}

export async function getAssignments() {
  return await axios.get<any>(GET_ASSIGN)
}

//issue

export async function getIssueStatusWidget() {
  return await axios.get<any>(ISSUE_WIDGETS+'/status')
}

export async function getIssueDirectorateWidget() {
  return await axios.get<any>(ISSUE_WIDGETS+'/statusByDirectorate')
}

export async function getWidgets(){
  return await axios.get<any>(WIDGETS)
}
export async function updateHomeWidgets(user_id:any, data:any) {
  return await axios.patch(USERS+'/'+user_id+'/widgets',data)
}

export async function getIssueStatusByStaff(){
  return await axios.get<any>(ISSUE_WIDGETS+'/statusByStaff')
}

// budgets
export async function getTotalBudgetWidget() {
  return await axios.get<any>(BUDGET_WIDGETS+'/totalBudget',)
}

export async function GetExchangeRates(){
  return await axios.get<any>(BUDGET_WIDGETS+'/exchangeRates')
}
export async function getBudgetByCategoryWidget(){
  return await axios.get<any>(BUDGET_WIDGETS+'/totalBudgetByCategory')
}
export async function GetMonthlyTotalOpex(){
  return await axios.get<any>(BUDGET_WIDGETS+'/monthlyTotalOpex')
}

export async function GetMonthlyTotalCapex(){
  return await axios.get<any>(BUDGET_WIDGETS+'/monthlyTotalCapex')
}
//project


export async function getProjectsPlan(){
  return await axios.get(PROJECT_WIDGETS+'/plan')
}
export async function getProjectsType(){
  return await axios.get(PROJECT_WIDGETS+'/type')
}

export async function getProjectsStatus(){
  return await axios.get(PROJECT_WIDGETS+'/status')
}
export async function getProjectDirectorateWidget(){
  return await axios.get(PROJECT_WIDGETS+'/statusByDirectorate')
}

export async function getProjectDirectorateWidgetByClass(class_id:any){
  return await axios.get(PROJECT_WIDGETS+'/statusByDirectorate?project_class_id='+class_id)
}