import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    item: any
}
export const NODItem : FC<Props>=({item})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {item?.record_type}
        </td>
        <td className='text-gray-500'>{item?.status}</td>
        <td className='text-gray-500'>{item?.assign_full_name}</td>
        <td className='text-gray-500'>{item?.task_duration} </td>
       
       

        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}