import React, {Key} from 'react'
import { AssignmentWithSub} from './AssignmentWithSub'
import {AssignmentItem} from './AssignmentItem'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Collapse} from 'react-bootstrap'

type Props = {
  element: any
  // path: string
}

const AssignmentItems: React.FC<Props> = ({children, element}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      {!element.is_last ? (
        // <AsideMenuItem
        //   key={element.id}
        //   icon='/media/icons/duotune/arrows/arr001.svg'
        //   // to={`/` + path}
        //   title={element.name}
        //   fontIcon='bi-layers'
        // />
        
      
        <AssignmentWithSub
          key={element.id}
          title={element.name}
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/arrows/arr001.svg'
        >
          {element.children.map(
            (e: {
              id: Key | null | undefined
              name: string
              children: any
              path: string
              is_last: boolean
            }) => {
              if (e.is_last) {
                return <AssignmentItem key={e.id} title={e.name} hasBullet={true} />
              } else {
                return <AssignmentItems key={e.id} element={e}></AssignmentItems>
              }
            }
          )}
        </AssignmentWithSub>
      ): ''}
    </>
  )
}

export {AssignmentItems}
