import React, { useState } from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import { checkIsActive, KTSVG } from '../../../../_theme/helpers'
import { useLayout } from '../../../../_theme/layout/core'
import { Button } from 'react-bootstrap'


type Props = {
  // to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AssignmentWithSub: React.FC<Props> = ({
  children,
  title,
  icon,
  fontIcon,
  hasBullet
}) => {
  const {pathname} = useLocation()
  const {config} = useLayout()
  const {aside} = config
  const [active, setActive] = useState(false)
  const Activity =()=>{
    active ? setActive(false) : setActive(true)
  }

  return (
    <div
      className={clsx('menu-item',{'here show': active}, 'menu-accordion')}
      data-kt-menu-trigger='click'
     
    >
      <span className='menu-link'  onClick={Activity} >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon' >
            <KTSVG path={icon} className='svg-icon svg-icon-5' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': false})}>
  {children}
     
      </div>
    </div>
  )
}

export {AssignmentWithSub}
