import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {KTCard} from '../../../../../_theme/helpers'
import {getProject, getProjectUsers} from '../../core/requests'
import {UserItem} from './UserItem'

type LocationState = {
  id: any
}
export const Stakeholder: FC = () => {
  const location = useLocation()
  const data = JSON.parse(localStorage.getItem('projectID') || '{}')
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      roles: [
        {
          id: 1,
          name: '',
        },
      ],
    },
  ])

  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
  
    getProjectUsers(data).then(({data}) => {
      setUsers(data.data)
    })
  }, [refresh])
  // const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <KTCard>
      {/* {loginUser.name==='Süper Admin Kullanıcısı' ? (  <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} />):''} */}

      <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-top-0 border-bottom-0'>
          <div className='card-body pt-3'>
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th></th>
                  <th className=''>AD SOYAD</th>
                  <th className=''>Görev</th>
                </tr>
              </thead>

              <tbody className='fs-6'>
                {users.map((user) => {
                  return (
                    <>
                      <UserItem key={user.id} user={user} refreshPage={refreshPage} />
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </KTCard>
  )
}
