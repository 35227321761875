import {FC, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { EditStatus } from './EditStatus'
import '../../style/style-swal.css'

type Props={
    status:boolean
    issue: any
}
export const SquareButton: FC<Props> = ({status, issue}) => {
    const [modalShow,setModalshow]=useState(false)
    const handleShow=()=>setModalshow(true)
    const handleClose=()=>setModalshow(false)
  return (
    <>
    <button
      type='button'
      className={`btn btn-sm btn-icon btn-color-light-dark ${status ? 'btn-active-light-primary' :''}`}
      data-kt-menu-trigger='click'
      onClick={handleShow}
    >
      {status?
    ( <span className='svg-icon svg-icon-2'>
    <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
        <rect x='14' y='5' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
        <rect x='5' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
        <rect x='14' y='14' width='5' height='5' rx='1' fill='currentColor' opacity='0.3' />
      </g>
    </svg>
  </span> 

    ):
    (
      
      <span
      data-kt-menu-trigger='click'
       className={`badge badge-${
         issue.status.id == '1'
           ? 'light-success'
           : issue.status.id == '0'
           ? 'light-primary'
           : 'light-warning'
       } fw-bolder me-auto`}
     >
       {issue.status.name}
     </span>
    )}
       
      
    </button>
    <Modal show={modalShow} onHide={handleClose} className='w-300px modal m-auto'>
    <Modal.Body >
      <EditStatus issue={{
            id: issue.id,
            title: issue.title,
            content: issue.content,
            start_date: issue.start_date,
            end_date: issue.end_date,
            phone_notification: issue.phone_notification,
            email_notification: issue.email_notification,
            issue_category_id: issue.issue_category_id,
            assigned_by : issue.asigned_by,
            status: issue.status
          }}
          handleClose={handleClose}/>
    </Modal.Body>
    </Modal>  
    </>
  //   <button
  //     type='button'
  //     className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'
  //     data-kt-menu-trigger='click'
  //   >
  // <span className='svg-icon svg-icon-2'>İşlem</span>
  //     </button>

  )
}
