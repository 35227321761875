import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    vnList: any
}
export const VNItem : FC<Props>=({vnList})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {vnList?.incident_id}
        </td>
        <td className='text-gray-500'>{vnList?.technology}</td>
        <td className='text-gray-500'>{vnList?.service}</td>
        <td className='text-gray-500'>{vnList?.category} GBps</td>
        <td className='text-gray-500'>{vnList?.title}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}