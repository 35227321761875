import { FC, useState } from "react"

type Props ={
    setSearchKeyClick : any
    placeholder: string
}
export const SearchComponent : FC<Props> =({setSearchKeyClick, placeholder})=>{
    const[searchKey, setSearchKey] = useState('')
    return(
        <div className='d-flex align-items-center position-relative my-3'>
        <input
          type='text'
          data-kt-filemanager-table-filter='search'
          className='form-control form-control-solid  me-2 w-300px'
          placeholder={placeholder}
          onChange={(e) => {
            setSearchKey(e.target.value)
            e.target.value == '' && setSearchKeyClick('')
          }}
        />
        <button
          type='button'
          className='btn btn-primary '
          onClick={() => {
            setSearchKeyClick(searchKey)
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-search'
            viewBox='0 0 16 16'
          >
            <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
          </svg>
        </button>
      </div>
    )
}