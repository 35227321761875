import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {ID} from '../../../_theme/helpers'
import {CategoryItem} from './CategoryItem'
import {
  CreateAnnouncementCategory,
  CreateBlogCategory,
  CreateBudgetCategory,
  CreateDocumentCategory,
  CreateInventoryCategory,
  CreateIssueCategory,
  CreateProjectCategory,
  CreateProjectClass,
  CreateProjectPlan,
  CreateProjectType,
  CreateSupplierCategory,
  GetAnnouncementCategories,
  GetBlogCategories,
  GetBudgetCategories,
  GetDocumentCategories,
  GetIssueCategories,
  GetProjectCategories,
  GetProjectClasses,
  GetProjectPlans,
  GetProjectTypes,
  GetSupplierCategories,
  UpdateAnnouncementCategory,
  UpdateBlogCategory,
  UpdateBudgetCategory,
  UpdateDocumentCategory,
  UpdateInventoryCategory,
  UpdateIssueCategory,
  UpdateProjectCategory,
  UpdateProjectClass,
  UpdateProjectPlan,
  UpdateProjectType,
  UpdateSupplierCategory,
} from './core/_request'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { GetInventoryCategories } from '../Inventory/core/_requests'

type Props = {
  category: {
    id: ID
    name: string
  }
  update: boolean
  handleClose: any
  numberMenu: string
}
const categorySchema = Yup.object().shape({
  name: Yup.string().required('Zorunlu alan'),
})

export const AddCategory: FC<Props> = ({category, update, handleClose, numberMenu}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    if(numberMenu=='announcement'){
      GetAnnouncementCategories().then(({data}) => {
        setCategories(data.data)
      })
    }
    else if(numberMenu=='inventory'){
      GetInventoryCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='blog'){
      GetBlogCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='document'){
      GetDocumentCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='issue'){
      GetIssueCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='supplier'){
      GetSupplierCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='budget'){
      GetBudgetCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='project_category'){
      GetProjectCategories().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='project_plan'){
      GetProjectPlans().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='project_type'){
      GetProjectTypes().then(({data})=>{
        setCategories(data.data)
      })
    }
    else if(numberMenu=='project_class'){
      GetProjectClasses().then(({data})=>{
        setCategories(data.data)
      })
    }
  }, [refresh])

  const [userForEdit] = useState<any>({
    ...category,
    name: category.name,
  })
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: categorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          if(numberMenu=='announcement'){
            await UpdateAnnouncementCategory(category.id, values)
            .then(() => {
              swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
         else if(numberMenu=='inventory'){
           await UpdateInventoryCategory(category.id, values)
           .then(() => {
            swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
          })
          .catch((err) => {
            swal('Hata', err.response.data.message, 'error', {timer: 2000})
          })
         }
         else if(numberMenu=='blog'){
          await UpdateBlogCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='document'){
          await UpdateDocumentCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='issue'){
          await UpdateIssueCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='supplier'){
          await UpdateSupplierCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='budget'){
          await UpdateBudgetCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='project_category'){
          await UpdateProjectCategory(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='project_plan'){
          await UpdateProjectPlan(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='project_type'){
          await UpdateProjectType(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
        else if(numberMenu=='project_class'){
          await UpdateProjectClass(category.id, values)
          .then(() => {
           swal('Düzenlendi', 'Kategori başarı ile düzenlendi.', 'success', {timer: 2000})
         })
         .catch((err) => {
           swal('Hata', err.response.data.message, 'error', {timer: 2000})
         })
        }
           
          handleClose()
        } else {
          if(numberMenu=='announcement'){
            await CreateAnnouncementCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='inventory'){
            await CreateInventoryCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='blog'){
            await CreateBlogCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='document'){
            await CreateDocumentCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='issue'){
            await CreateIssueCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='supplier'){
            await CreateSupplierCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='budget'){
            await CreateBudgetCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='project_category'){
            await CreateProjectCategory(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='project_plan'){
            await CreateProjectPlan(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='project_type'){
            await CreateProjectType(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
          else if(numberMenu=='project_class'){
            await CreateProjectClass(values)
            .then(() => {
              swal('Eklendi', 'Kategori başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          }
        
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-8 mb-2'>Kategori</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Kategori İsmi'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.name && formik.errors.name},
                // {
                //   'is-valid': formik.touched.name && !formik.errors.name,
                // }
              )}
              autoComplete='off'
            />
            {/* {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          <div id='kt_project_targets_card_pane'>
            <div className='card card-flush  border-top-0 border-bottom-0'>
              <div className='card-body pt-3'>
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                  <tbody className='fs-6'>
                    {categories.map((category) => {
                      return (
                        <>
                          <CategoryItem
                            key={category.id}
                            data={category}
                            refreshPage={refreshPage}
                            numberMenu={numberMenu}
                          />
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
function GetBudgetPlans() {
  throw new Error('Function not implemented.')
}

