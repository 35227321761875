/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_theme/helpers'
import {Dropdown1} from '../../../../_theme/partials'
import {ModalHeader} from '../../components/ModalHeader'
import {ProfilePopUp} from '../../Profile/ProfilePopUp'
import {AddTicket} from '../../Supplier/supplier-edit-modal/AddTicket'
import {getProject, GetTarget} from '../core/requests'
import {AddTarget} from './add-edit-modals/addTarget'
import {CreateGroup} from './add-edit-modals/CreateGroup'

const DetailHeader: React.FC = () => {
  const [showTicketModal, setShowticketModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const refreshComponent = () => (refresh ? setRefresh(false) : setRefresh(true))
  const openTicketModal = () => {
    setShowticketModal(true)
  }
  const closeTicketModal = () => {
    setShowticketModal(false)
    // refresh ? setRefresh(false) : setRefresh(true)
  }
  const openUserModal = () => {
    setShowUserModal(true)
  }
  const closeUserModal = () => {
    setShowUserModal(false)
  }
  const [show, setShow] = useState(false)
  const [showAtachModal, setShowAtachModal] = useState(false)
  const [update, setUpdated] = useState(false)

  const location = useLocation()

  const [projectID, setID] = useState<any>(JSON.parse(localStorage.getItem('projectID') || '{}'))
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }

  const [project, setProject] = useState<any>({
    id: 1,
    code: '',
    name: '',
    start_date: '',
    end_date: '',
    status: {
      id: 1,
      name: '',
    },
    description: '',
    email_notification: false,
    phone_notification: false,
    group_directorate: {
      id: 2,
      name: '',
      order: 1,
      parent_id: 1,
      is_group_directorate: false,
      is_directorate: false,
      is_last: false,
    },
    users: [],
    files: [],
    created_at: '',
    created_by: {
      id: 1,
      name: '',
      email: '',
      avatar: '',
    },
  })
  useEffect(() => {
    setID(JSON.parse(localStorage.getItem('projectID') || '{}'))
    getProject(projectID).then((res) => {
      setID(res.data.data.id)
      setProject(res.data.data)
    })
  }, [show, showAtachModal, projectID, update])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap '>
            {/* <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metornic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6  border-4 border-white h-20px w-20px'></div>
            </div>
          </div> */}

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start mb-1'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3'>
                      {project?.name}
                    </a>
                    <div className='card-toolbar'>
                      <span className={`badge badge-light-primary fw-bolder me-auto px-4 py-3`}>
                        {project.status.name}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex flex-column  py-3 me-4 '>
                        <div className='fw-bolder fs-7'>Proje Amacı</div>
                          <div className='d-flex align-items-center' style={{width:'80%'}}>
                            <div className='fs-6 fw-bold text-gray-400 w-100'>
                              {project?.description}
                            </div>
                          </div>
                        </div>
                  {/* <div className='d-flex flex-wrap fw-bold fs-6  pe-2'>
                  <div className='fw-bolder   fs-7 '>Proje Amacı</div>
                    <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{project?.description}</p>
                  </div> */}
                </div>

                <div className='d-flex my-4'>
                  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'  style={{width:'135px'}}>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label' onClick={openUserModal}>
                      Kullanıcı Ekle
                    </span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                    style={{width:'135px'}}
                    onClick={() => openTicketModal()}
                  >
                    Yeni Hedef
                  </a>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack row'>
                <div className='d-flex flex-column flex-grow-1 pe-4 col'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex'>
                      {project?.risk !== null ? (
                        <div className='min-w-112px py-3  me-4 '>
                          
                          <div className='fw-bolder   fs-7 '>Risk</div>

                          <div className='d-flex align-items-center'>
                            <div className='fs-6 fw-bold  text-gray-400'>{project?.risk}</div>
                          </div>
                       
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='d-flex'>
                      {project?.escalation !== null ? (
                        <div className=' min-w-112px py-3 me-4 '>
                          <div className='fw-bolder fs-7 '>Eskalasyon</div>
                          <div className='d-flex align-items-center'>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {project?.escalation}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='d-flex'>
                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>{project?.code}</div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Proje Kodu</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div
                            className='fs-7 fw-bolder text-hover-primary'
                            onClick={async () => {
                              setProfilUserId(project?.first_owner?.id)
                              await openProfilModal()
                            }}
                          >
                            {project?.first_owner?.name}
                          </div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Proje Yöneticisi</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div
                            className='fs-7 fw-bolder text-hover-primary'
                            onClick={async () => {
                              setProfilUserId(project?.second_owner?.id)
                              await openProfilModal()
                            }}
                          >
                            {project?.second_owner?.name}
                          </div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Proje Teknik Yöneticisi</div>
                      </div>
                    </div>
                    <div className='d-flex '>
                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-2 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>
                            {project?.start_date.substring(8, 10)}.
                            {project?.start_date.substring(5, 7)}.
                            {project.start_date.substring(0, 4)}
                          </div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Başlangıç Tarihi</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-2 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>
                            {project?.end_date.substring(8, 10)}.{project?.end_date.substring(5, 7)}
                            .{project.end_date.substring(0, 4)}
                          </div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Bitiş Tarihi</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>{project?.type?.name}</div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Proje Türü</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>{project?.category?.name}</div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Kategorisi</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>{project?.plan?.name}</div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Plan Durumu</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-112px py-3 px-4 me-4 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-7 fw-bolder'>{project?.class?.name}</div>
                        </div>
                        <div className='fw-bold fs-9 text-gray-400'>Proje Sınıfı</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center w-200px w-sm-200px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2  col'>
                    <div className='symbol symbol-10px symbol-lg-40px symbol-fixed position-relative me-2'>
                      <img
                        src={project?.created_by?.avatar ? project?.created_by?.avatar : ''}
                        alt='Metornic'
                      />
                      <div className='position-absolute translate-middle bottom-0 start-100 mb-6  border-4 border-white h-20px w-20px'></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex flex-column'>
                        <div
                          className='d-flex align-items-center fw-bolder text-hover-primary'
                          onClick={async () => {
                            setProfilUserId(project?.created_by.id)
                            await openProfilModal()
                          }}
                        >
                          {project?.created_by?.name}
                        </div>

                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 '>
                          <p className='text-gray-400 fw-bold fs-8 mt-1'>
                            Son güncelleme: <br />
                            {project.created_at.substring(8, 10)}.
                            {project.created_at.substring(5, 7)}.
                            {project.created_at.substring(0, 4)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bold flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/homepage' && 'active')
                  }
                  to='/projeler/detail-project/homepage'
                  state={project.id}
                >
                  Genel Bakış
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/project-progress' && 'active')
                  }
                  to='/projeler/detail-project/project-progress'
                >
                  Proje İlerleme Durumu
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/budget' && 'active')
                  }
                  to='/projeler/detail-project/budget'
                >
                  Bütçe
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/stakeholders' && 'active')
                  }
                  to='/projeler/detail-project/stakeholders'
                  state={project.id}
                >
                  Paydaşlar
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/documents' && 'active')
                  }
                  state={project?.id}
                  to='/projeler/detail-project/documents'
                >
                  Dosyalar
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/activity' && 'active')
                  }
                  to='/projeler/detail-project/activity'
                  state={project.id}
                >
                  Aktivite
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projeler/detail-project/settings' && 'active')
                  }
                  to='/projeler/detail-project/settings'
                  state={project}
                >
                  Ayarlar
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        show={showTicketModal}
        onHide={closeTicketModal}
        style={{position: 'absolute', top: '0px'}}
      >
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Yeni Hedef Oluştur'} />
          <AddTarget
            target={''}
            target_id={''}
            handleClose={closeTicketModal}
            update={false}
            project_id={project.id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showUserModal}
        onHide={closeUserModal}
        style={{position: 'absolute', top: '0px'}}
        size='xl'
      >
        <Modal.Body>
          <ModalHeader handleClose={closeUserModal} titleHeader={'Kullanıcı Ekle'} />
          <CreateGroup
            setModalShow={closeUserModal}
            project={project}
            update={false}
            oldUsers={project.users}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {DetailHeader}
function setRefresh(arg0: boolean) {
  throw new Error('Function not implemented.')
}
