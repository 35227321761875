import Chart from 'react-apexcharts'
import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { ListsWidget2 } from '../../../../../_theme/partials/profile-widgets/ListsWidget2'
import { TargetItem2 } from '../../../Activities/components/TargetItem2'
import { GetTarget, getTargetStatusWidgets } from '../../core/requests'
import { ListTargets } from './widgets/ListTargets'
import {TimeLine} from './widgets/TimeLine'
import { TimelineWidget1 } from '../../../../../_theme/partials/widgets'
import { Timeline } from '../../../../../_theme/partials/widgets/timeline/Timeline'



export const ProjectProgress = () => {
  const [statusTitle, setStatusTitle] = useState<any>([])
  const statusTitle1: any[] | undefined = []
  const statusCount1: any[] | undefined = []
  const [statusCount, setStatusCount] = useState(0)
  const [statusC, setSC] = useState<any>()
  const [targets, setTargets] = useState<any>([])
  let total = 0
  const chart1 = {
    labels: statusTitle,
    dataLabels: {
      enabled: true,
    },
  }
  const [refresh,setRefresh]=useState(false)
  const refreshPage=()=>{refresh? setRefresh(false) : setRefresh(true)}
  const [activities, setActivities] = useState([
    {
      id:1,
      title: '',
      content: '',
      start_date:'',
      end_date:'',
      phone_notification: false,
      email_notification: false,
      comments: [],
      category: {
        id: 1,
        name: ''
      },
      status: {
        id: '0',
        name :'Planlanan'
      },
      assigned: {
        id :1,
        name: '',
        email: ''
      }

    }
  ])
  let data = JSON.parse(localStorage.getItem('projectID') || '{}')
  useEffect(() => {
    getTargetStatusWidgets(data).then(({data}) => {
      prdSatus(data)
    })
    GetTarget(data).then(({data}) => {
      console.log(data.data);
      setTargets(data.data)
    })
  }, [])
  const prdSatus = (data: any) => {
    total = 0
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total_target
      statusCount1.push(data[i]?.total_target)
    }
    setSC(statusCount1);
    setStatusCount(total);
    for (let i = 0; i < data.length; i++) {
      statusTitle1.push(data[i]?.status_name + ' ' + data[i]?.total_target)
    }
    setStatusTitle(statusTitle1)
  }
  return (
    <div>
      <div className='row g-5 g-xxl-10'>
        {/* begin::Col */}
        <div className='col-xl-5 col-xxl-4 mb-xl-5 mb-xxl-10'>
          <div className='card h-100'>
            <div className='card-body p-3'>
              <div className='fs-3 fw-bolder'>Hedef Özeti</div>
              <div className='fs-7 fw-bold text-gray-400 mb-7'>    Toplamda {statusCount} hedef var</div>
              <Chart options={chart1} series={statusC} type='donut' />
            </div>
          </div>

          {/* <CardsWidget6 className='h-xl-100' chartHeight='100' /> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-6 col-xxl-8 mb-xl-5 mb-xxl-10'>
          
          {/* <TimelineWidget1 className={''} targetData={targets}/> */}
          <Timeline data={targets}/>
        </div>

        {/* end::Col */}
      </div>
      <div className='row'>
        <div className='col-12'>
          <TimeLine day={20} data={targets}/>
   
        </div>
      </div>
      <div id='kt_project_targets_card_pane'>
      <div className='mt-10'>
     
        <ListTargets className='mb-5 mb-xxl-8' project_id={targets} refreshPage targetContent={true} />
       
      </div>
    </div>
    </div>
  )
}
