import React, { FC } from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_theme/layout/core'
import { Blog } from './Blog'
import { BlogDetail } from './Blog-detail/BlogDetail'
import { ViewDetail } from './Blog-detail/ViewDetail'


const BlogPageWrapper: FC = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
      <Route
          path='blog'
          element={
            <>
              <PageTitle>Bilgi Paylaşım Platformu</PageTitle>
             <Blog/>
            </>
          }
        />
         <Route
          path='detail'
          element={
            <>
    
              <PageTitle breadcrumbs={[{title:'Bilgi Paylaşım Platformu',path:'..//blog',isActive:true}]}>/Detay</PageTitle>
              <BlogDetail/>
             
            </>
          }
        />
           <Route
          path='ViewDetail/detail'
          element={
            <>
              <PageTitle>Blog Detay</PageTitle>
              <BlogDetail/>
             
            </>
          }
        />
         <Route
          path='ViewDetail'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Bilgi Paylaşım Platformu',path:'..//blog',isActive:true}]}></PageTitle>
              <ViewDetail/>
             
            </>
          }
          
        />

     
        <Route index element={<Navigate to='/blog' />} />

      </Route>
    </Routes>
  )
}

export {BlogPageWrapper}