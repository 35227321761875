import Chart from 'react-apexcharts'
import React, {useEffect, useState} from 'react'
import { getProjectUsers, GetTarget, getTargetStatusWidgets} from '../../core/requests'
import {ListsWidget2} from './widgets/ListUsers'
import {ListTargets} from './widgets/ListTargets'
import {TimeLine} from './widgets/TimeLine'


export const HomePage = () => {
  const [statusTitle, setStatusTitle] = useState<any>([])
  const statusTitle1: any[] | undefined = []
  const statusCount1: any[] | undefined = []
  const [statusCount, setStatusCount] = useState(0)
  const [statusC, setSC] = useState<any>()
  const [refresh, setRefresh] = useState(false)
  const [targets, setTargets] = useState<any>([])
  const refreshPage =()=>
    refresh ? setRefresh(false) : setRefresh(true)
  
  let total = 0
  const chart1 = {
    labels: statusTitle,
    dataLabels: {
      enabled: true,
      anchor: 'end',
      align: 'end',
    },
  }

  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      roles: [
        {
          id: 1,
          name: '',
        },
      ],
    },
  ])
  let data = JSON.parse(localStorage.getItem('projectID') || '{}')

  useEffect(() => {
    getProjectUsers(data).then(({data}) => {
      setUsers(data.data)
    })

    getTargetStatusWidgets(data).then(({data}) => {
      
      prdSatus(data)
    })
    GetTarget(data).then(({data}) => {
      setTargets(data.data)
    })
  }, [refresh])
  const prdSatus = (data: any) => {
    total = 0
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total_target
      statusCount1.push(data[i]?.total_target)
      statusTitle1.push(data[i]?.status_name + ' ' + data[i]?.total_target)
    }
    setSC(statusCount1);
    setStatusCount(total);
    setStatusTitle(statusTitle1)
  }
  return (
    <div>
      <div className='row '>
        {/* begin::Col */}
        <div className='col-4 mb-xl-5 mb-xxl-10'>
          <div className='card h-100'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder text-dark'>Hedef Özeti</span>
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Toplamda {statusCount} hedef var{' '}
                </span>
              </h3>

              {/* <div className='card-toolbar'>
                <a className='btn btn-sm btn-light'>
                  Hedefleri Görüntüle
                </a>
              </div> */}
            </div>
            <div className='card-body p-3'>
              <Chart options={chart1} series={statusC} type='donut' />
            </div>
          </div>

          {/* <CardsWidget6 className='h-xl-100' chartHeight='100' /> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}

        <div className='col-8 mb-xl-5 mb-xxl-10'>
          <TimeLine day={9} data={targets}/>
        </div>
        {/* end::Col */}
      </div>
      <div className='row'>
        <div className='col-6'>
          {/* {console.log(data)} */}
          <ListTargets className='mb-5 mb-xxl-8' project_id={targets}  refreshPage={refreshPage} targetContent={false} />
        </div>
        <div className='col-6'>
          {/* {console.log(data)} */}
          <ListsWidget2 className='mb-5 mb-xxl-8' users={users} project_id={data} />
        </div>
      </div>
    </div>
  )
}
