import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    item: any
}
export const TableBody : FC<Props>=({item})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {item?.month}
        </td>
        <td className='text-gray-500'>{item?.total}</td>
        <td className='text-gray-500'>{item?.year}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}