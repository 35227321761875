import React, {FC, useEffect, useState} from 'react'
import { NoMatchRecords } from '../../components/NoMatchRecords'
import { getIssues1 } from '../core/_requests'
import {TargetItem} from './TargetItem'

type Props={
show:boolean
period: string
issuesId: string
searchKeyClick: string
widgetId: any
}

export const InProgress: FC <Props> = ({show, period,issuesId,searchKeyClick, widgetId}) => {
  const[issues,setIssues]=useState([
    {
      id:1,
      title: '',
      content: '',
      start_date:'',
      end_date:'',
      phone_notification: false,
      email_notification: false,
      comments: [],
      category: {
        id: 1,
        name: ''
      },
      status: {
        id: '0',
        name :'Planlanan'
      },
      assigned: {
        id :1,
        name: '',
        email: ''
      }

    }
  ])
  useEffect(()=>{
    let link = ''
    if (searchKeyClick !== ''){
      link = '&q=' + searchKeyClick}
    getIssues1(period,link,issuesId,widgetId).then(({data})=>
    {
      setIssues(data.data)
    })
  },[show,period,issuesId,searchKeyClick])

  return (
    <div className='col-md-4 col-lg-12 col-xl-4'>
      {/* <div className='mb-9'>
        <div className='d-flex flex-stack'>
          <div className='fw-bolder fs-4'>
            Devam Eden
            <span className='fs-6 text-gray-400 ms-2'>{issues.length}</span>
          </div>

          <div>
           {/* <SquareButton  status={true}/> */}
           {/* <EditStatus/> */}
          {/* </div>
        </div>
        <div className='h-3px w-100 bg-success'></div>
      </div> */} 
          <div className='mb-9 bg-success rounded p-1'>
      
      <div className='d-flex justify-content-between m-2'>
        <div className='fw-bolder fs-4 text-white '>Devam Eden</div>
        <div className='badge badge-circle bg-light'>
          <span className='fs-6 text-success  p-2'>{issues.length}</span>
        </div>
      </div>

      <div>
        {/* <SquareButton  status={true}/> */}
        {/* <EditStatus issue={issue}/> */}
      </div>

    {/* <div className='h-3px w-100 bg-primary '></div> */}
  </div>

      {
      issues.length<1? <NoMatchRecords isTable={false}/>:
      issues.map((issue)=>{
        return(
          <TargetItem
          key={issue.id}
          issue={issue}
          commentColor={'success'}
          backgroundColor={'#fcfff7'}
        />
        )
      })
    }
    </div>
  )
}
