import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { GetTarget } from '../Project/core/requests';


type Props = {
    setDate:string
    project_id:any
}

export const TimeLineBody: FC<Props> = ({setDate, project_id})=> {
    const [targets, setTargets] = useState<any>([])
    const [updateTarget, setUpdateTarget] = useState<any>([])
    const [update, setUpdate] = useState(false)
    // let id = JSON.parse(localStorage.getItem('projectID') || '{}')
    useEffect(() => {
      let revDate = setDate.split('.');
      let result = revDate[2]+'-'+revDate[1]+'-'+revDate[0];
     
      GetTarget(project_id+'&start_date='+result).then(({data}) => {
        setTargets(data.data)
      })
    }, [update,setDate,project_id])
  return (
    <div className=' mb-2 px-9'>
      {targets.map((target:any)=>{
        return (
            <div className=''>
            <div className='d-flex align-items-center mb-6'>
              <span
                data-kt-element='bullet'
                className='bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success'
              ></span>
    
              <div className='flex-grow-1 me-5'>
                <div className='text-gray-800 fw-bold fs-6'>
                  {target?.start_date} / {target?.end_date}
                  <span className='text-gray-400 fw-bold fs-7'></span>
                </div>
    
                <Link to='../target-detail' state={target} className='text-gray-700 fw-bold fs-3'>{target.title}</Link>
    
                <div className='text-gray-400 fw-bold fs-7'>
              
                  <a className='text-primary opacity-75-hover fw-bold'>
                   {target?.assigned?.name}
                  </a> tarafından yönetiliyor
                </div>
              </div>
    
              {/* <a
                href='#'
                className='btn btn-sm btn-light'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_project'
              >
                View
              </a> */}
            </div>
          </div>
        )
      })}

    </div>
  )
}
