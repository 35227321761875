import { FC } from "react"
import { useListView } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { UsersListToolbar } from "./UserListToolbar"
import { UsersListGrouping } from "./UsersListGrouping"
import { UsersListSearchComponent } from "./UsersListSearchComponent"

type Props ={
  refreshPage :any
  setWord: any
}

const UsersListHeader : FC <Props> = ({refreshPage, setWord}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      <UsersListSearchComponent setWord={setWord}/>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar refreshPage={refreshPage}/>}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
