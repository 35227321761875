import Chart from 'react-apexcharts'
import {FC, useEffect, useState} from 'react'
import {bottom, right} from '@popperjs/core'
import {
  getIssueStatusWidget,
  getProjectsStatus,
  getTotalBudgetWidget,
  updateHomeWidgets,
} from '../../core/requests'
import NumberFormat from 'react-number-format'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'

type Props = {
  widgetId: any
  refresh ?: boolean
  text: string
  plusOrMinus: boolean
  refreshPage ?: any
}
export const PieWidget: FC<Props> = ({widgetId, refresh, text, plusOrMinus, refreshPage}) => {
  const [widgetLabels, setWidgetLabels] = useState<any>([])
  const [widgetData, setWidgetData] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel

  function homeWidgetShow() {
    swal({
      title: '',
      text: plusOrMinus ? 'Widget anasayfanıza eklensin mi?': 'Widget silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
        await refreshPage()
        // await DeleteAnnoun(data.id)
        // swal('Silindi!', 'Duyuru başarı ile silindi.', 'success')
        // navigate('/duyurular/bilgi-guvenligi-politikalari')
      }
    })
  }

  useEffect(() => {
    if (widgetId == 7) {
      getIssueStatusWidget().then(({data}) => {
        prdIssueStatus(data)
      })
    } else if (widgetId == 8) {
      getTotalBudgetWidget().then(({data}) => {
        prdTotalBudget(data)
      })
    } else if (widgetId == 9) {
      getProjectsStatus().then(({data}) => {
        prdProjectSatus(data)
      })
    }
  }, [refresh])

  const prdIssueStatus = (data: any) => {
    let total = 0
    let label = []
    let structuredData = []
    for (let i = 0; i < data.length; i++) {
      total = total + data[i].total_issues
      label.push(data[i].status_name)
      structuredData.push(data[i].total_issues)
    }
    setWidgetData(structuredData)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const prdTotalBudget = (data: any) => {
    setTotalCount(
      data.total_capex_budget +
        data.total_opex_budget -
        (data.total_capex_usage + data.total_opex_usage)
    )
    let label = []
    let structuredData = []
    label.push('Capex')
    label.push('Opex')
    structuredData.push(data.total_capex_usage)
    structuredData.push(data.total_opex_usage)
    setWidgetData(structuredData)
    setWidgetLabels(label)
  }

  const prdProjectSatus = (data: any) => {
    let total = 0
    let structuredData = []
    let label = []
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total_project
      structuredData.push(data[i]?.total_project)
      label.push(data[i]?.status_name)
    }
    setWidgetData(structuredData)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const chart1 = {
    labels: widgetLabels,
    dataLabels: {
      enabled: true,
      font : {
       
        
      }
      
    
    },
    legend: {
      show: true,
      position:  widgetId==9 || widgetId==10 || widgetId== 11 ? right : bottom,
      harizontalAlign: 'center',
      fontSize: '15rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },

    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ''
        },
      },
    },
  }
  return (
    <div className='card h-xl-100 overflow-hidden' style={{overflow:'hidden'}}>
      <div className='card-body p-5 overflow-hidden'>
        <div className='w-100 d-flex justify-content-between'>
          <div>
            {widgetId == 8 ? (
              <div className='fs-2hx fw-bolder'>
                <NumberFormat value={totalCount} displayType={'text'} thousandSeparator={true} />
                <span className='fs-2'> ₺</span>
              </div>
            ) : (
              <div className='fs-2x fw-bolder'>
                {totalCount} <span className='fs-3'>adet</span>
              </div>
            )}
            {/* <div className='fs-2hx fw-bolder'>
                    {totalCount} <span className='fs-2'>adet</span>
                  </div> */}
            <div className='fs-5 fw-bold text-gray-400 mb-7'>{text}</div>
          </div>
          <div
            onClick={() => {
             homeWidgetShow()
            }}
          >
            <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex '>
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-center w-100 h-100'>
          <Chart
            options={chart1}
            series={widgetData}
            // [data[0].total_issues,data[1].total_issues,data[2].total_issues]}
            type='donut'
            height={'320px'}
          />
        </div>
      </div>
    </div>
  )
}
