import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import swal from 'sweetalert'
import {CreateUser, GetAssignments, UpdateUser} from '../core/_requests'
import * as Yup from 'yup'
import {getRoles} from '../../core/requests'
import {ID} from '../../../../_theme/helpers'

type Props = {
  user: {
    id: ID
    name: string
    email: string
    avatar: string
    assignment: {
      id: ID
      name: string
    }
    roles: {
      id: ID
      name: string
    }[]
    mobile_number: string
    phone_number: string
    experience: number
    certificates: string
    competencies: string
    associate_degree: string
    bachelors_degree: string
    masters_degree: string
  }
  update: boolean
  handleClose: any
  refreshPage: any
}
export const AddUser: FC<Props> = ({user, update, handleClose, refreshPage}) => {
  const [assignments, setAssignments] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  let formData = new FormData()
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  useEffect(() => {
    GetAssignments().then(({data}) => {
      setAssignments(data.data)
    })
    getRoles().then(({data}) => {
      setRoles(data.data)
    })
  }, [])

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Lütfen E-Posta adresini doğru şekide giriniz')
      .min(3, 'Lütfen az 3 karakter giriniz')
      .max(50, 'En fazla 50 karakterden oluşmalı')
      .required('Zorunlu alan'),
    password: Yup.string()
      .when('update', {
        is: (update: boolean) => update === true,
        then: Yup.string().required('Zorunlu alan'),
      })
      .min(8, 'Lütfen az 8 karakter giriniz')
      .max(50, 'En fazla 50 karakter olabilir'),
    //  .required('Zorunlu alan')
    name: Yup.string().required('Zorunlu alan'),
    assignment_id: Yup.string().required('Zorunlu alan'),
    experience: Yup.number().required('Zorunlu alan'),
  })
  const [userForEdit] = useState<any>({
    ...user,
    id: user.id,
    name: user.name,
    avatar: user.avatar,
    email: user.email,
    assignment_id: user.assignment !== null ? user.assignment.id : 0,
    associate_degree: user.associate_degree,
    bachelors_degree: user.bachelors_degree,
    masters_degree: user.masters_degree,
    experience: user.experience,
    certificates: user.certificates,
    competencies: user.competencies,
    mobile_number: user.mobile_number,
    phone_number: user.phone_number,
    roles: user.roles.length > 0 ? user.roles[0].id : 0,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log('buraya geldi')
          await UpdateUser(user.id, values)
            .then(() => {
              swal('Düzenlendi', 'Kullanıcı Bilgileri başarı ile düzenlendi.', 'success', {
                timer: 2000,
              })
              refreshPage()
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('name', values.name)
          formData.append('email', values.email)
          formData.append('password', values.password)
          formData.append('assignment_id', values.assignment_id)
          formData.append('experience', values.experience)
          formData.append('certificates', values.certificates)
          formData.append('competiencies', values.competiencies)
          formData.append('associate_degree', values.associate_degree)
          formData.append('bachelors_degree', values.bachelors_degree)
          formData.append('masters_degree', values.masters_degree)
          formData.append('phone_number', values.phone_number)
          formData.append('mobile_number', values.mobile_number)
          formData.append('roles[0]', values.roles)

          // let newUser = {
          //   name: values.name,
          //   email: values.email,
          //   password: values.password,
          //   assignment_id: values.assignment_id,
          //   experience: values.experience,

          // }
          await CreateUser(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Kullanıcı başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              console.log(err.message)
              handleClose()
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_info_form'
      onSubmit={formik.handleSubmit}
      className='form'
      noValidate
    >
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_user_info_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_info_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_info_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Kullanıcı Adı Soyadı</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='row mb-7 d-flex'>
          <div className={`'col-xs-12' ${update ? 'col-md-12' : 'col-md-6'}`}>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>E-Posta Adresi</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('email')}
              type='email'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />

            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6' style={{display: update ? 'none' : ''}}>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Şifresi</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('password')}
              type='password'
              name='password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Telefon Numarası</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('mobile_number')}
              type='text'
              name='mobile_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.email && formik.errors.email},
                // {
                //   'is-valid': formik.touched.email && !formik.errors.email,
                // }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* 
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                 <div className='fv-help-block'>
                 <span role='alert'>{formik.errors.email}</span>
                 </div>
                
              </div>
            )} */}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Dahili Telefon Numarası</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('phone_number')}
              type='text'
              name='phone_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className='row mb-7 d-flex'>
          <div>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Görev</label>
            {/* end::Label */}

            {/* begin::Input */}
            <select
              className={clsx(
                'form-select form-select-solid'
                // {'is-invalid': !formik.touched.assignment_id && formik.errors.assignment_id },
                //   {
                //     'is-valid': formik.touched.assignment_id && !formik.errors.assignment_id
                //   }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Kategori Seç'
              {...formik.getFieldProps('assignment_id')}
              name='assignment_id'
              // defaultValue={user.assignment!==null ? user.assignment.id :''}
            >
              <option hidden>Görev Seç</option>
              {assignments.map((assign: any) => {
                return (
                  <option key={assign.id} value={assign.id}>
                    {assign.name}
                  </option>
                )
              })}
            </select>
            {!formik.touched.assignment_id && formik.errors.assignment_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assignment_id}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-7 mb-2'>Ön Lisans</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('associate_degree')}
            type='text'
            name='associate_degree'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.name && formik.errors.name},
              // {
              //   'is-valid': formik.touched.name && !formik.errors.name,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {/* {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )} */}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-7 mb-2'>Lisans</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('bachelors_degree')}
            type='text'
            name='bachelors_degree'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.name && formik.errors.name},
              // {
              //   'is-valid': formik.touched.name && !formik.errors.name,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {/* {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )} */}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-7 mb-2'>Yüksek Lisans</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('masters_degree')}
            type='text'
            name='masters_degree'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.name && formik.errors.name},
              // {
              //   'is-valid': formik.touched.name && !formik.errors.name,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {/* {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )} */}
          {/* end::Input */}
        </div>

        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className=' required fw-bold fs-7 mb-2'>Tecrübe</label>
            {/* end::Label */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Yıl Seç'
              {...formik.getFieldProps('experience')}
              name='experience'
            >
              {/* {activity.assigned_by!==''} */}
              <option hidden>Yıl Seçiniz</option>
              {numbers.map((i) => {
                return (
                  <option key={i} value={i}>
                    {i} yıl
                  </option>
                )
              })}
            </select>
            {/* begin::Input */}

            {/* {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                 <div className='fv-help-block'>
                 <span role='alert'>{formik.errors.email}</span>
                 </div>
                
              </div>
            )} */}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='fw-bold fs-7 mb-2'>Rol</label>
            {/* end::Label */}
            <select
              className='form-select form-select-solid '
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Rol Seçiniz'
              {...formik.getFieldProps('roles')}
              name='roles'
            >
              {/* {activity.assigned_by!==''} */}
              <option value={0} hidden>
                Rol Seçiniz
              </option>
              {roles.map((role: any) => {
                return (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                )
              })}
            </select>
            {/* begin::Input */}

            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-7 mb-2'>Sertifikalar</label>
          {/* end::Label */}

          {/* begin::Input */}
          {/* <input
            placeholder='Giriniz'
            {...formik.getFieldProps('certificates')}
            type='text'
            name='certificates'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.name && formik.errors.name},
              // {
              //   'is-valid': formik.touched.name && !formik.errors.name,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          /> */}
          <textarea
            placeholder='Sertifika isimlerini giriniz'
            {...formik.getFieldProps('certificates')}
            name='certificates'
            rows={5}
            cols={70}
            className='form-control form-control-solid mb-3 mb-lg-0'
          ></textarea>
          {/* {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )} */}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-7 mb-2'>Yeterlilikler</label>
          {/* end::Label */}

          {/* begin::Input */}
          {/* <input
            placeholder='Giriniz'
            {...formik.getFieldProps('competencies')}
            type='textarea'
            name='competencies'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0'
              // {'is-invalid': formik.touched.name && formik.errors.name},
              // {
              //   'is-valid': formik.touched.name && !formik.errors.name,
              // }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          /> */}
          <textarea
            placeholder='Yeterliliklerinizi giriniz'
            {...formik.getFieldProps('competencies')}
            name='competencies'
            rows={5}
            cols={70}
            className='form-control form-control-solid mb-3 mb-lg-0'
          ></textarea>
          {/* {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )} */}
          {/* end::Input */}
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
            onClick={handleClose}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              (formik.isSubmitting || !formik.isValid || !formik.touched.assignment_id) && !update
            }
          >
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
