import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {downloadAT, downloadDLP, GetAT, GetNOD} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'

import {SearchComponent} from '../components/SearchComponent'

import {IncomeAssuranceDataChart1} from '../../components/widgets/OperationalData/IncomeAssuranceDataChart1'
import {AddAlarmTrakıng} from './add-AlarmTrakıng/Add-AlarmTrakıng'
import {ATItem} from './components/ATItem'
import {PieAlarmChart} from '../../components/widgets/OperationalData/PieAlarmChart'
import {BarAlarmChart2} from '../../components/widgets/OperationalData/BarAlarmChart2'
import {BarAlarmChart1} from '../../components/widgets/OperationalData/BarAlarmChart1'
import {BarAlarmChart4} from '../../components/widgets/OperationalData/BarAlarmChart4'

export const AlarmTrakıng: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [iads, setIADS] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0

  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetAT(link).then((data) => {
      setIADS(data.data.data)
      setSearchKeyClick('')
    })
  }, [refresh, searchKeyClick,show])

  const downloa = () => {
    downloadAT('').then((data) => {
      console.log(data)
      window.open(data?.data?.url)
    })
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>

        <div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
              <PieAlarmChart name={'f'} />
            </div>
            <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
              <BarAlarmChart1
                className={''}
                chartHeight={0}
                type={'1'}
                verticalTitle={''}
                horizentalTitle={'Bölümlere yayınlanan olay rapor sayıları'}
                pointTitle={''}
              />
            </div>

            {/* end::Col */}
          </div>
          <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
              <BarAlarmChart2
                className={''}
                chartHeight={300}
                type={'2'}
                verticalTitle={''}
                horizentalTitle={'Haftalık Toplam Alarm Sayıları'}
                pointTitle={''}
              />
            </div>

            <div className='col-xl-8 col-xxl-8 mb-xl-7 mb-xxl-6 overflow-hidden'>
              <BarAlarmChart4
                className={''}
                chartHeight={300}
                type={'4'}
                verticalTitle={''}
                horizentalTitle={'GELİR GÜVENCE BULGULARI (TT Mobil)'}
                pointTitle={''}
              />
            </div>

            {/* end::Col */}
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3 overflow-auto'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr color='#50c8da' style={{color:'#50c8da'}}>
                    <th className=''>Olayın kısa tanımı </th>
                    <th className=''>Sorumlu ekip/kişi</th>
                    <th className=''> Olay yaşanan sistem kaynağı</th>
                    <th className=''>Aksiyon durumu</th>
                    <th className=''>Aksiyon sahibi</th>

                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {iads?.map((iads: any) => {
                    return (
                      <>
                        <ATItem key={iads?.id} item={iads} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddAlarmTrakıng handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
