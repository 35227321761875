import {FC, Key, useCallback, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Announcement} from '../models/announModel'
import {CreateAnnouncement, getAnnounCategories, UpdateAnnouncement} from '../core/_requests'
import Dropzone from 'react-dropzone'
import {RolesTags} from '../../components/RolesTags'
import {useNavigate} from 'react-router-dom'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCategory} from '../../Categories/AddCategory'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  update: boolean
  announcement: any
  handleClose: any
}
const announcementSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  content: Yup.string().required('Zorunlu alan'),
  announcement_category_id: Yup.number().required('Zorunlu alan'),
  start_date: Yup.date()
    .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
  end_date: Yup.date()
    .when(
      'start_date',
      (start_date, yup) =>
        start_date &&
        yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
    )
    // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
    // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
})


// })
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}
const AddAnnoun: FC<Props> = ({handleClose, announcement, update}) => {
  const navigate = useNavigate()
  const [emailBox, setEmailbox] = useState(announcement.email_notification)
  const [phoneBox, setPhonebox] = useState(announcement.phone_notification)
  const [sliderBox, setSliderbox] = useState(announcement.is_slider)
  const [publicBox, setPublicbox] = useState(announcement.is_public)
  const [files, setFiles] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const [file, setFile] = useState<any>([])
  const [showFile, setShowFile] = useState<any>([]) //banner show
  const [errors, setErrors] = useState('')
  const [errors2, setErrors2] = useState('')
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  const [categories, setCat] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  const getRoles = useCallback(
    (selectRole) => {
      setRoles([...roles, selectRole])
    },
    [roles]
  )
  const deleteRole = useCallback(
    (tagIndex) => {
      setRoles(roles.filter((_: any, i: any) => i !== tagIndex))
    },
    [roles]
  )

  const [userForEdit] = useState<Announcement>({
    ...announcement,
    id: announcement.id,
    title: announcement.title,
    start_date: announcement.start_date,
    end_date: announcement.end_date,
    content: announcement.content,
    is_public: announcement.is_public,
    is_slider: announcement.is_slider,
    email_notification: announcement.email_notification,
    phone_notification: announcement.phone_notification,
    announcement_category_id: announcement.announcement_category_id,
    attachments: announcement.attachments,
    assignments: announcement.assignments,
  })
  //file
  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }
  //banner
  const removeFiles = (f: any) => () => {
    const newFiles = [...showFile]
    newFiles.splice(newFiles.indexOf(f), 1)
    setShowFile(newFiles)
  }
  //file
  const removeAll = () => {
    setFiles([])
  }
  const cancel = () => {
    handleClose()
  }
  //file
  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )

  //banner
  const f = showFile.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span
          className='btn btn-sm btn-primary mr-10 d-flex align-items-center'
          onClick={removeFiles(file)}
        >
          Sil
        </span>
      </li>
    )
  )

  let formData = new FormData()
 

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: announcementSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log(values)
          let assig = []
          for (let i = 0; i < roles.length; i++) {
            assig.push({id: Number(roles[i].id)})
            //formData.append(' assignments[' + i + ']', roles[i].id)
            //console.log(base64);
          }
          const UpdAnnoun = {
            title: values.title,
            announcement_category_id: values.announcement_category_id,
            content: values.content,
            email_notification: emailBox,
            phone_notification: phoneBox,
            is_public: publicBox,
            is_slider: sliderBox,
            start_date: values.start_date,
            end_date: values.end_date,
            assignments: assig,

          }
          console.log(UpdAnnoun)
          await UpdateAnnouncement(announcement.id, UpdAnnoun)
            .then(() => {
              swal('Düzenlendi', 'Duyuru başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          //navigate('/duyurular/bilgi-guvenligi-politikalari')
        } else {
          formData.append('title', values.title)
          formData.append('start_date', values.start_date!)
          formData.append('end_date', values.end_date)
          formData.append('content', values.content)
          formData.append('is_public', publicBox ? '1' : '0')
          formData.append('is_slider', sliderBox ? '1' : '0')
          formData.append('email_notification', values.email_notification ? '1' : '0')
          formData.append('phone_notification', values.phone_notification ? '1' : '0')
          formData.append('announcement_category_id', values.announcement_category_id)
          formData.append('banner', file)
          for (let i = 0; i < roles.length; i++) {
            formData.append('assignments[' + i + ']', roles[i].id)
            //console.log(base64);
          }

          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
            //console.log(base64);
          }
          await CreateAnnouncement(formData)
            .then(() => {
              swal('Eklendi', 'Duyuru başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
          handleClose()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  useEffect(() => {
    getAnnounCategories().then(({data}) => {
      setCat(data.data)
    })
    setRoles(announcement.assignments)
  }, [handleClose, publicBox, showCategoryModal])
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <div className='d-flex justify-content-between w-100'>
              <label className='required fw-bold fs-7 mt-2'>Kategori</label>
              {loginUser.name === 'Süper Admin Kullanıcısı' && (
                <img
                  src='https://cdn-icons-png.flaticon.com/512/992/992651.png'
                  alt='add-icon'
                  width='25px'
                  className='m-1'
                  onClick={openCategoryModal}
                />
              )}
            </div>

            {/* end::Label */}

            {/* begin::Input */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Kategori Seç'
              {...formik.getFieldProps('announcement_category_id')}
              name='announcement_category_id'
              // defaultValue={announcement.announcement_category_id}
            >
              <option hidden>Kategori Seç</option>

              {categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </select>

            {formik.touched.announcement_category_id && formik.errors.announcement_category_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.announcement_category_id}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5 required'>Açıklama</label>
            {/* end::Label */}
            <textarea
              placeholder='Duyuru için bir açıklama giriniz'
              {...formik.getFieldProps('content')}
              name='content'
              rows={8}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Scroll */}

          <div style={{display: update ? 'none' : 'block'}} className='fv-row mb-8'>
            <label className='fs-7 fw-bold mb-2'>Dosya Yükle</label>

            <div className='dropzone' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                      fill='currentColor'
                    />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

                <Dropzone
                  maxSize={10485760}
                  maxFiles={10}
                  onDrop={async (acceptedFiles, fileRejections) => {
                    setFiles(acceptedFiles)
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === 'file-too-large') {
                          setErrors2(`Hata: ${err.message}`)
                        }

                        if (err.code === 'file-invalid-type') {
                          setErrors2(`Hata: ${err.message}`)
                        } else {
                          setErrors2(`Hata: ${err.message}`)
                        }
                      })
                    })
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                            Dosyaları buraya bırakın veya yüklemek için tıklayın
                          </h3>
                          <span className='fw-bold fs-7 text-gray-400'>
                            5 dosyaya kadar yükleyin
                          </span>
                        </div>
                        <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>{errors2}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
            <ul className='m-0 p-0'>{filess}</ul>
          </aside>
          <div className='d-flex justify-content-end'>
            {' '}
            {files.length > 0 && (
              <span onClick={removeAll} className='btn btn-sm btn-primary me-2 '>
                Hepsini Sil
              </span>
            )}
          </div>
          <div style={{display: update ? 'none' : 'block'}} className='fv-row mb-8'>
            <label className='fs-7 fw-bold mb-2s'>Fotoğraf Yükle</label>

            <div className='dropzone' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                      fill='currentColor'
                    />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

                <Dropzone
                  accept={{'image/*': ['.png', '.jpg', '.jpeg']}}
                  multiple={false}
                  maxSize={2097152}
                  maxFiles={1}
                  onDrop={async (acceptedFile, fileRejections) => {
                    setFile(acceptedFile[0])
                    setShowFile(acceptedFile)
                    //setFiles(acceptedFile)
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === 'file-too-large') {
                          setErrors(`Hata: ${err.message}`)
                        }

                        if (err.code === 'file-invalid-type') {
                          setErrors(`Hata: ${err.message}`)
                        } else {
                          setErrors(`Hata: ${err.message}`)
                        }
                      })
                    })
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                            Arka planda gözükmesini istediğiniz fotoğrafı buraya bırakın veya
                            yüklemek için tıklayın
                          </h3>
                          <span className='fw-bold fs-7 text-gray-400'>1 adet foto yükleyin</span>
                        </div>
                        <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>{errors}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
            <ul className='m-0 p-0'>{f}</ul>
          </aside>
          <div className='d-flex flex-column mb-8 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-2 justify-content-between'>
              <span className='required'>Kimler görüntüleyebilsin?</span>
              {/* <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify a target priorty'
              ></i> */}
              <div>
                <input
                  className='form-check-input h-18px w-18px'
                  type='checkbox'
                  {...formik.getFieldProps('is_public')}
                  name='is_public'
                  value='public'
                  defaultChecked={publicBox}
                  onChange={() => {
                    publicBox == false ? setPublicbox(true) : setPublicbox(false)
                  }}
                />
                <span className='form-check-label fw-bold'> Herkes</span>
              </div>
            </label>

            <div
              style={{display: publicBox ? 'none' : 'block'}}
              className={`form-control form-control-solid `}
            >
              <RolesTags
                setRoles={getRoles}
                delRole={deleteRole}
                tags1={announcement.assignments}
              />
            </div>
          </div>

          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='required fs-6'>Görünürlük</label>
                <div className='fs-7 text-gray-400'>
                  Duyuruyu slider alanında görüntülemek için aktif hale getiriniz
                </div>{' '}
              </div>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  id='allowmarketing'
                  {...formik.getFieldProps('is_slider')}
                  name='is_slider'
                  value='is_slider'
                  defaultChecked={sliderBox}
                  onChange={() => {
                    sliderBox == false ? setSliderbox(true) : setSliderbox(false)
                  }}
                />
                {/* <label className='fs-7 text-gray-400'> </label> */}
              </div>
            </div>
          </div>

          {/* begin:: notifications */}
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirimler</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={emailBox}
                    onChange={() => {
                      emailBox == false ? setEmailbox(true) : setEmailbox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={phoneBox}
                    onChange={() => {
                      phoneBox == false ? setPhonebox(true) : setPhonebox(false)
                    }}
                  />
                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          {/* end:: notifications */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={
                (formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.touched ||
                  (showFile.length < 1 && !update) ||
                  (roles.length < 1 &&
                    ((roles.length < 1 && !update && !publicBox) || (!publicBox && update)))) &&
                !update
              }
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
      <Modal show={showCategoryModal} onHide={closeCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='announcement'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AddAnnoun}
