import {FC, useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'

import swal from 'sweetalert'
import {SwalOptions} from 'sweetalert/typings/modules/options'

import {getUsers} from '../../../core/requests'
import { UpdateIssue} from '../../../Activities/core/_requests'

import {UserItem} from './UserItem'




type Props = {
  setModalShow: any
  project: any
  update: boolean
  setNext: any
  setUser:any
}

export const CreateGroup: FC<Props> = ({setModalShow, project, update, setNext,setUser}) => {

  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
      email: '',
      avatar: '',
      assignment: {
        id: 1,
        name: '',
      },
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const [tags, setTags] = useState<any>(project)
  const [query, setQuery] = useState('')
  const search = (data: any[]) => {
    return data.filter(
      (item) => item.name.toLowerCase().includes(query) || item.email.toLowerCase().includes(query)
    )
  }
  const onAddUser = (user: { id: number; name: string; email: string; avatar: string; assignment: { id: number; name: string } })=>{
    if(tags.filter((tag: any) => tag.id === user.id).length==0){
      setTags([...tags, user]);
      setQuery('');
    }
   
  }
  const onDelete = (id:any)=>{
    setTags(tags.filter((tag: any) => tag.id !== id))
  }

  useEffect(() => {
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
  }, [update])

  const [userForEdit] = useState<any>({
    ...project,
    id: project.id,

  })

  const cancel = () => {
    setModalShow(false)
  }

  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log(values)
          await UpdateIssue(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err: {response: {data: {message: string | Partial<SwalOptions>}}}) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          setUser(tags);
          setNext(4)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // setSubmitting(true)
        // cancel()
      }
    },
  })

  return (
    <>
      <div style={{width: '70%', minWidth: '300px'}} className='m-auto'>
        <div className='mb-5'>
          <h3>Proje Grubu Oluştur</h3>
          <span>
            Daha fazla bilgiye ihtiyacınız varsa, lütfen <a>Proje Yönergelerini kontrol edin</a>
          </span>
        </div>
        <form
          id='kt_modal_add_user_form'
          onSubmit={formik.handleSubmit}
          className='form'
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Takım Arkadaşlarını Davet Et</label>
              {/* end::Label */}
              <div className=''>
                <input
                  placeholder='Proje üyelerini adı veya e-postaya göre arayınız'
                  // {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  value={query}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0'
                    // {'is-invalid': formik.touched.title && formik.errors.title},
                    // {
                    //   'is-valid': formik.touched.title && !formik.errors.title,
                    // }
                  )}
                  // autoComplete='off'
                  disabled={formik.isSubmitting}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>

            <div>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>


                <tbody className='fs-6'>
                  {query != ''
                    ? search(users).map((user) => {
                        return (
                          <>
                            <UserItem key={user.id} user={user} refreshPage={refreshPage} type={false} setUser={onAddUser} onDelete={onDelete} />
                          </>
                        )
                      })
                    : ''}
                </tbody>
              </table>
            </div>
            <div className='card card-flush  border-top-0 border-bottom-0'>
              <div className='card-body pt-3'>
                <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder w-100'>
                  <thead className='fs-7 text-gray-400 text-uppercase'>
                    <tr>
                      <th></th>
                      <th className=''>AD SOYAD</th>
                      <th className=''>GÖREV</th>
                    </tr>
                  </thead>

                  <tbody className='fs-6'>
                    {tags.map((user:any) => {
                      return (
                        <>
                          <UserItem key={user.id} user={user} refreshPage={refreshPage} type={true} setUser={onAddUser} onDelete={onDelete} />
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
                onClick={() =>{ setNext(2)
                  setUser(tags)}}
                className='btn btn-light me-3'
                disabled={formik.isSubmitting}
              >
                Geri
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                disabled={tags.length==0}
              >
                <span className='indicator-label'>Doküman Oluştur</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </form>
      </div>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
function setRefresh(arg0: boolean) {
  throw new Error('Function not implemented.')
}
