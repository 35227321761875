import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import { AddDoc } from '../supplier-edit-modal/AddDoc'
import {AddSLA} from '../supplier-edit-modal/AddSLA'
import { AddTicket } from '../supplier-edit-modal/AddTicket'

type Props = {
  active: number
  setActive: any
  sla: any
  supplier_id: any
  ticket: any
  doc: any
  refresh: boolean,
  setRefresh: any
}
export const Slider: FC<Props> = ({active, setActive, sla, supplier_id, ticket, refresh, setRefresh, doc}) => {
  const [showSlaModal, setSlaModal] = useState(false)
  const [showTicketModal, setShowticketModal] = useState(false)
  const [showDocModal, setShowdocModal] = useState(false)

  const openSlaModal = () => {
    setSlaModal(true)
 
  }
  const closeSlaModal = () => {
    setSlaModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const openTicketModal = () => {
      setShowticketModal(true)
  }
  const closeTicketModal = () => {
      setShowticketModal(false)
      refresh ? setRefresh(false) : setRefresh(true)
  }
  const openDocModal = () => {
    setShowdocModal(true)
  }
  const closeDocModal = () => {
    setShowdocModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  return (
    <>
      <div className='w-200'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_tab'
              onClick={() => setActive(1)}
            >
              Genel
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 2 ? 'active' : ''}`}
              data-kt-countup-tabs='true'
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_security'
              onClick={() => setActive(2)}
            >
              Dökümanlar
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 3 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
              onClick={() => setActive(3)}
            >
              SLA Yönetimi
            </a>
          </li>
          <li className='d-flex justify-content-end  me-0 pe-0'>
            <div className='d-flex' style={{position: 'absolute', right:'30px'}}>
              {active == 1 ? (
                <Button className='' variant='primary' onClick={openTicketModal}>
                  Yeni Ticket Case Ekle
                </Button>
              ) : active == 2 ? (
                <Button className='' variant='primary' onClick={openDocModal}>
                  Yeni Döküman Ekle
                </Button>
              ) : (
                <Button className='' variant='primary' onClick={openSlaModal}>
                  Yeni SLA Ekle
                </Button>
              )}
            </div>
          </li>
        </ul>
      </div>
      <Modal show={showSlaModal} onHide={closeSlaModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeSlaModal} titleHeader={'SLA Kategori Ekle'} />
          <AddSLA sla={sla} supplier_id={supplier_id} handleClose={closeSlaModal} update={false}/>
        </Modal.Body>
      </Modal>
      
      <Modal show={showTicketModal} onHide={closeTicketModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeTicketModal} titleHeader={'Ticket-Case Ekle'} />
         <AddTicket ticket={ticket} supplier_id={supplier_id} handleClose={closeTicketModal} update={false}/>
        </Modal.Body>
      </Modal>

      <Modal show={showDocModal} onHide={closeDocModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeDocModal} titleHeader={'Döküman Ekle'} />
         <AddDoc supplier_id={supplier_id} handleClose={closeDocModal} doc={doc}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
