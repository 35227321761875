/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {
  defaultMessages,
  MessageModel,
  UserInfoModel,
  defaultUserInfos,
  messageFromClient,
  toAbsoluteUrl,
} from '../../../../_theme/helpers'

import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import { CreateComment } from '../core/_requests'
import { string } from 'yup'
import { CommentModel, CommentPost } from '../../Activities/models/commentModel'
import { auto } from '@popperjs/core'

type Props = {
  isDrawer?: boolean
  data: any
}

// const bufferMessages = []

const ChatInner: FC<Props> = ({isDrawer = true, data}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  //const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [comments, setComments] = useState<any>([
    {
      commentable_id: 1,
      commentable_type: 'App/Model/Issue',
      content: '',
      created_at: '',
      created_by: {
        id: user.id,
        name: user.name,
        email: user.email,
      },
      id: undefined,
    },
  ])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)

  useEffect(() => {
    setComments(data.comments)
  }, [comment,data])
  const sendComments = async () => {
    console.log(formatDate(new Date()));
    const newComment: CommentModel = {
      commentable_id: data.id,
      commentable_type: 'App/Model/Issue',
      content: comment,
      created_at:formatDate(new Date()),
      created_by: {
        id: user.id,
        name: user.name,
        email: user.email,
      },
      id: undefined,
    }
    const commentPost: CommentPost={
      content: comment
    }

    comments.push(newComment)
    setComments(comments)
    await CreateComment(newComment,newComment.commentable_id)
    //toggleChatUpdateFlat(!chatUpdateFlag)
    setComment('')
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendComments()
    }
  }
  function calTime(date: string) {
    if (date !== undefined) {
      let dateTime = date.split('T')
      // dateTime[1] = '  '+dateTime[1].slice(0,5);
      return dateTime
    }
  }
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }
  return (
    <>
      <div
        className='card-body p-0 m-0 '
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        <div
          className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer},'')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: true, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
          style={{maxHeight:'500px',overflowY:'scroll',scrollbarWidth:'thin'}}
        >
          {comments.map((comm: any, index: any) => {
            const state = 'info'
            const templateAttr = {}
            if (true) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-in`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-start
           mb-10`

            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': false})}
                {...templateAttr} 
              >
                <div className={clsx('d-flex flex-column align-items', `align-items-start`)}>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='ms-3'>
                      <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                        {comm.created_by.name}
                      </a>
                      <span className='text-muted fs-7 mb-1'>   {comm.created_at.substring(8, 10)}.{comm.created_at.substring(5, 7)}.{comm.created_at.substring(0, 4)}, {comm.created_at.substring(11, 16)}</span>
              
                    </div>
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-start`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{__html: comm.content}}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>

        <div
          className='card-footer pt-4'
          id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        >
          <textarea
            className='form-control form-control-flush mb-3'
    
            style={{maxHeight:'300px'}}
      
            data-kt-element='input'
            placeholder='Yorum yaz'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>
          <button
            className='btn btn-primary '
            type='button'
            data-kt-element='send'
            onClick={sendComments}
          >
            Gönder
          </button>
        </div>
      </div>
    </>
  )
}

export {ChatInner}
