import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import swal from 'sweetalert'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {ModalHeader} from '../../components/ModalHeader'
import {DeleteSLACategory} from '../core/_requests'
import {AddSLA} from '../supplier-edit-modal/AddSLA'

type Props = {
  refresh: boolean
  setRefresh: any
  sla: any
  supplier_id: any
}
export const SLA: FC<Props> = ({setRefresh, sla, refresh, supplier_id}) => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(false)
    refresh ? setRefresh(false) : setRefresh(true)
  }
  const handleShow = () => {
    setShowModal(true)
    refresh ? setRefresh(false) : setRefresh(true)
  }

  function swalDelete(id: any) {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteSLACategory(id).then(() => {
          swal('Silindi!', 'SLA Yönetimi başarı ile silindi.', 'success')
          refresh ? setRefresh(false) : setRefresh(true)
        })
      }
    })
  }
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <tr>
        <td colSpan={10} className='fs-4'>
          {sla.title} <br />
          <span className='text-gray-300 fs-6'>{sla.description}</span>
          <br />
          <span className='text-gray-400 fs-8'>{sla.response_time} saat</span>
        </td>
  
          
            <td className='min-w-200px text-end'>
              <Button
                variant='primary mt-2'
                onClick={() => {
                  handleShow()
                }}
                className='me-4'
              >
                Düzenle
              </Button>
              <Button
                variant='primary mt-3'
                onClick={() => {
                  swalDelete(sla.id)
                }}
              >
                Sil
              </Button>
            </td>
          
      </tr>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddSLA sla={sla} supplier_id={supplier_id} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>
    </>
  )
}
