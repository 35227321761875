import {FC, useEffect, useState} from 'react'
import {HeaderAlert} from './components/HeaderAlert'
import {Banner} from './components/Banner'
import {PieWidget} from '../components/widgets/PieWidget'
import {getUser, getWidgets} from '../core/requests'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ProjectPieWidget} from '../components/widgets/ProjectPieWidgets'
import {DirectorateWidget} from '../components/widgets/DirectorateWidget'
import {ExchangeRates} from '../components/widgets/ExchangeRates'
import {BudgetByCategory} from '../components/widgets/BudgetByCategory'
import { OpexCapexWidget } from '../components/widgets/OpexCapexWidget'
export const HomePage: FC = () => {
  const [issueStatusWidget, setIssueStatusWidget] = useState(false)
  const [budgetTotalWidget, setBudgetTotalWidget] = useState(false)
  const [projectStatusWidget, setProjectStatusWidget] = useState(false)
  const [projectTypeWidget, setProjectTypeWidget] = useState(false)
  const [projectPlanWidget, setProjectPlanWidget] = useState(false)
  const [projectDirectorateWidget, setProjectDirectorateWidget] = useState(false)
  const [issueDirectorateWidget, setIssueDirectorateWidget] = useState(false)
  const [exchangeRatesWidget, setExchangeRatesWidget] = useState(false)
  const [budgetCategoryWidget, setBudgetcategoryWidget] = useState(false)
  const [opexBudgetWidget, setOpexBudgetWidget] = useState(false)
  const [capexBudgetWidget, setCapexBudgetWidget] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    setBudgetTotalWidget(false)
    setIssueStatusWidget(false)
    setProjectPlanWidget(false)
    setProjectStatusWidget(false)
    setProjectTypeWidget(false)
    setProjectDirectorateWidget(false)
    setIssueDirectorateWidget(false)
    setExchangeRatesWidget(false)
    setBudgetcategoryWidget(false)
    setOpexBudgetWidget(false)
    setCapexBudgetWidget(false)
    getUserInfo()
  }, [refresh])

  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const getUserInfo = () => {
    getUser(loginUser.id).then(({data}) => {
      if (data.data.home_widgets.includes(8) || data.data.home_widgets == 'all') {
        setBudgetTotalWidget(true)
      }
      if (data.data.home_widgets.includes(7) || data.data.home_widgets == 'all') {
        setIssueStatusWidget(true)
      }
      if (data.data.home_widgets.includes(9) || data.data.home_widgets == 'all') {
        setProjectStatusWidget(true)
      }
      if (data.data.home_widgets.includes(10) || data.data.home_widgets == 'all') {
        setProjectTypeWidget(true)
      }
      if (data.data.home_widgets.includes(11) || data.data.home_widgets == 'all') {
        setProjectPlanWidget(true)
      }
      if (data.data.home_widgets.includes(12) || data.data.home_widgets == 'all') {
        setIssueDirectorateWidget(true)
      }
      if (data.data.home_widgets.includes(13) || data.data.home_widgets == 'all') {
        setProjectDirectorateWidget(true)
      }
      if (data.data.home_widgets.includes(14) || data.data.home_widgets == 'all') {
        setExchangeRatesWidget(true)
      }
      if (data.data.home_widgets.includes(15) || data.data.home_widgets == 'all') {
        setBudgetcategoryWidget(true)
      }
      if (data.data.home_widgets.includes(16) || data.data.home_widgets == 'all') {
        setOpexBudgetWidget(true)
      }
      if (data.data.home_widgets.includes(17) || data.data.home_widgets == 'all') {
        setCapexBudgetWidget(true)
      }
    })
  }

  return (
    <>
      <div>
        <HeaderAlert />
      </div>
      <div className='row g-5 g-xxl-10'>
        {/* begin::Col */}
        {issueStatusWidget && (
          <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
            <PieWidget
              widgetId={7}
              text={'Toplam Faaliyet'}
              plusOrMinus={false}
              refreshPage={refreshPage}
            />
          </div>
        )}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-7 col-xxl-8  mb-5 mb-xxl-10'>
          {/* <div className='card h-100'> */}
          <Banner
            className='h-xl-100 bg-gray-200 border-0'
            text='Siber Güvenlik Haftası'
            textClass='text-white-800'
            buttonClass='btn-danger'
            buttonText='Detay'
          />
          {/* </div> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xxl-10'>
        {/* begin::Col */}
        {budgetTotalWidget && (
          <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
            <PieWidget
              widgetId={8}
              text={'Kalan Bütçe'}
              plusOrMinus={false}
              refreshPage={refreshPage}
            />
          </div>
        )}

        {/* end::Col */}
        {issueDirectorateWidget && (
          <div className='col-xl-8 mb-xl-5 mb-xxl-10 '>
            {/* <AgentStatus
   className='h-xl-100'
   chartHeight={300}
   chartColor='rgba(150, 142, 126, 0.2)'
   title={'Ajan Durumları'}
 /> */}
            <DirectorateWidget
              widgetId={12}
              plusOrMinus={false}
              refreshPage={refreshPage}
              title={'Müdürlük Bazlı Faaliyetler'}
            />
          </div>
        )}
        {/* begin::Col */}

        {/* end::Col */}
      </div>
      <div className='row g-5 g-xxl-10 mt-1'>
        {/* begin::Col */}
        {projectStatusWidget && (
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden' >
            <ProjectPieWidget
              widgetId={9}
              text={''}
              plusOrMinus={false}
              refreshPage={refreshPage}
            />
          </div>
        )}
        {projectTypeWidget && (
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden' >
            <ProjectPieWidget
              widgetId={10}
              text={'Çalışma Türüne Göre'}
              plusOrMinus={false}
              refreshPage={refreshPage}
            />
          </div>
        )}
        {projectPlanWidget && (
          <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
            <ProjectPieWidget
              widgetId={11}
              text={'Proje Aşaması'}
              plusOrMinus={false}
              refreshPage={refreshPage}
            />
          </div>
        )}
      </div>
      <div className='row g-5 g-xxl-10'>
        {/* begin::Col */}
        {exchangeRatesWidget && (
          <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
            <ExchangeRates plusOrMinus={false} widgetId={14} refreshPage={refreshPage} />
          </div>
        )}
        {budgetCategoryWidget && (
          <div className='col-xl-8 mb-xl-5 mb-xxl-10 '>
            <BudgetByCategory plusOrMinus={false} widgetId={15} refreshPage={refreshPage} />
          </div>
        )}
      </div>
      <div className='row g-5 g-xxl-10 mt-3'>
        {/* begin::Col */}
        {opexBudgetWidget && <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <OpexCapexWidget widgetId={16} color={'#87CEFA'} plusOrMinus={false} refreshPage={refreshPage}/>
          </div>}
          {capexBudgetWidget && <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
          <OpexCapexWidget widgetId={17} color={'#FF6347'} plusOrMinus={false} refreshPage={refreshPage}/>
          </div>}
   
      </div>
    </>
  )
}
