import {useFormik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {CreateOrder, UpdateOrder} from '../core/_requests'
import swal from 'sweetalert'
import clsx from 'clsx'
type Props = {
  handleClose: any
  order: any
  update: boolean
}
export const AddOrder: FC<Props> = ({handleClose, order, update}) => {
  const [userForEdit] = useState<any>({
    ...order,
    id: order.id,
    order_number: order.order_number,
    tyr_amount: order.try_amount,
    saving_currency: order.saving_currency,
    currency: order.currency,

  })
  const orderSchema = Yup.object().shape({
    order_number: Yup.string().required('Zorunlu alan'),
    try_amount: Yup.string().required('Zorunlu alan'),
    saving_currency: Yup.string().required('Zorunlu alan'),
    currency: Yup.string().required('Zorunlu alan'),
    amount: Yup.string().required('Zorunlu alan'),
    saving_amount: Yup.number().required('Zorunlu alan'),
  })
  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: orderSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
            const updOrder={
              budget_id: values.budget_id,
              order_number: values.order_number,
              currency: values.currency,
              amount: values.amount,
              try_amount: values.try_amount,
              saving_currency: values.saving_currency,
              saving_amount:values.saving_amount
            }
            await UpdateOrder(values.id, updOrder)
              .then(() => {
                swal('Düzenlendi', 'Sipariş başarı ile düzenlendi.', 'success', {timer: 2000})
                handleClose()
              })
              .catch((err) => {
                swal('Hata', err.response.data.message, 'error', {timer: 2000})
              })
        } else {
          formData.append('budget_id', values.budget_id)
          formData.append('order_number', values.order_number)
          formData.append('currency', values.currency)
          formData.append('amount', values.amount)
          formData.append('try_amount', values.try_amount)
          formData.append('saving_currency', values.saving_currency)
          formData.append('saving_amount', values.saving_amount)
          await CreateOrder(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Sipariş başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Şipariş Numarası</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('order_number')}
              type='text'
              name='order_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.order_number && formik.errors.order_number},
                {
                  'is-valid': formik.touched.order_number && !formik.errors.order_number,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.order_number && formik.errors.order_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.order_number}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tutar</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('amount')}
                type='number'
                name='amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.amount && formik.errors.amount},
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.currency && formik.errors.currency},
                  {
                    'is-valid': formik.touched.currency && !formik.errors.currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('currency')}
                name='currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                Döviz Birimi Seçiniz
              </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>TL Tutarı</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('try_amount')}
              type='number'
              name='try_amount'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.try_amount && formik.errors.try_amount},
                {
                  'is-valid': formik.touched.try_amount && !formik.errors.try_amount,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.try_amount && formik.errors.try_amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.try_amount}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tasarruf Miktarı</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('saving_amount')}
                type='number'
                name='saving_amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.saving_amount && formik.errors.saving_amount},
                  {
                    'is-valid': formik.touched.saving_amount && !formik.errors.saving_amount,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.saving_amount && formik.errors.saving_amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.saving_amount}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
              {/* end::Label */}
              <select
                className={clsx(
                  'form-select form-select-solid',
                  {'is-invalid': formik.touched.saving_currency && formik.errors.saving_currency},
                  {
                    'is-valid': formik.touched.saving_currency && !formik.errors.saving_currency,
                  }
                )}
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('saving_currency')}
                name='saving_currency'
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                Döviz Birimi Seçiniz
              </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.saving_currency && formik.errors.
              saving_currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.saving_currency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
