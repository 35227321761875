import Chart from 'react-apexcharts'
import {FC, useEffect, useState} from 'react'
import {getProjectsPlan, getProjectsStatus, getProjectsType, updateHomeWidgets} from '../../core/requests'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import swal from 'sweetalert'
import { bottom } from '@popperjs/core'
type Props = {
  widgetId: any
  text: string
  plusOrMinus: boolean
  refreshPage?: any
  refresh?: boolean
  setSelectedStatus?: any
  setSelectedPlan?: any
  setSelectedType?: any
  setSelectedName?: any
}
export const ProjectPieWidget: FC<Props> = ({
  widgetId,
  text,
  plusOrMinus,
  refreshPage,
  refresh,
  setSelectedName,
  setSelectedPlan,
  setSelectedStatus,
  setSelectedType,
}) => {
  const [widgetLabels, setWidgetLabels] = useState<any>([])
  const [widgetData, setWidgetData] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const [statusData, setStatusData] = useState<any>([])
  const [typeData, setTypeData] = useState<any>([])
  const [planData, setPlanData] = useState<any>([])
  useEffect(() => {
    if (widgetId == 9) {
      getProjectsStatus().then(({data}) => {
        setStatusData(data)
        prdStatus(data)
      })
    } else if (widgetId == 10) {
      getProjectsType().then(({data}) => {
        setTypeData(data)
        prdType(data)
      })
    } else if (widgetId == 11) {
      getProjectsPlan().then(({data}) => {
        setPlanData(data)
        prdPlan(data)
      })
    }
  }, [refresh])

  const prdPlan = (data: any) => {
    let series = []
    let label = []
    for (let i = 0; i < data.length; i++) {
      series.push(data[i]?.total_project)
      label.push(data[i]?.plan_name)
    }
    setWidgetData(series)
    setWidgetLabels(label)
  }

  const prdType = (data: any) => {
    let type = []
    let label = []
    for (let i = 0; i < data.length; i++) {
      type.push(data[i]?.total_project)
      label.push(data[i]?.type_name)
    }
    setWidgetData(type)
    setWidgetLabels(label)
  }

  const prdStatus = (data: any) => {
    let total = 0
    let status = []
    let label = []
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total_project
      status.push(data[i]?.total_project)
      label.push(data[i]?.status_name)
    }
    setWidgetData(status)
    setWidgetLabels(label)
    setTotalCount(total)
  }

  const chart1 = {
    labels: widgetLabels,
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'center',
      fontSize: '15rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (
          event: any,
          chartContext: any,
          config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}
        ) => {
          setSelectedName(config.w.config.labels[config.dataPointIndex])
          if (widgetId == 9) {
            statusData.map((data: any) => {
              if (data.status_name == config.w.config.labels[config.dataPointIndex]) {
                setSelectedStatus(data.status_id)
              }
            })
            setSelectedPlan('')
            setSelectedType('')
          }
          else if(widgetId==10) {
               typeData.map((data: any) => {
            if (data.type_name == config.w.config.labels[config.dataPointIndex]) {
              setSelectedType(data.type_id)
            }
          })
          setSelectedStatus('')
          setSelectedPlan('')
          }
          else if(widgetId==11){
            planData.map((data: any) => {
                if (data.plan_name == config.w.config.labels[config.dataPointIndex]) {
                  setSelectedPlan(data.plan_id)
                }
              })
              setSelectedStatus('')
              setSelectedType('')
          }
        },
      },
    },
  }
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  function homeWidgetShow() {
    swal({
      title: '',
      text: plusOrMinus ? 'Widget anasayfanıza eklensin mi?': 'Widget silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
        await refreshPage()
        // await DeleteAnnoun(data.id)
        // swal('Silindi!', 'Duyuru başarı ile silindi.', 'success')
        // navigate('/duyurular/bilgi-guvenligi-politikalari')
      }
    })
  }

  const border = {
    border: '1px solid #50c8da'
  }
  return (
    <div className='card h-100' style={widgetId==9? border:{}} >
      <div className='card-body p-3 overflow-scroll'>
        <div className='fs-3 fw-bolder d-flex justify-content-between align-items-center'>
          {widgetId == 9 ? (
            <div>
              <span className='fs-2qx'>{totalCount}</span> <span className='fs-2'>adet</span>{' '}
            </div>
          ) : (
            <div>{text}</div>
          )}
          <div>
           
            {plusOrMinus &&  <span
              className='btn btn-icon btn-color-gray-400 btn-active-color-primary mt-n2 me-2  d-inline-flex '
                onClick={() => {
                    if(widgetId==9){
                        setSelectedStatus('')
                    }
                    else if(widgetId==10){
                        setSelectedType('')
                    }
                    else{
                        setSelectedPlan('')
                    }
                    setSelectedName('')
         
                }}
            >
              Tümü
            </span>
          
            }
             <br />
             <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-2  d-inline-flex '   onClick={() => {
             homeWidgetShow()
            }}>
              {plusOrMinus ? '+' : '-'}
            </span>
          </div>
        </div>
        <div className='fs-4 fw-bold text-gray-400 mb-3 '>Mevcut Projeler</div>
        <Chart options={chart1} series={widgetData} type='donut' height={'400px'} />
      </div>
    </div>
  )
}
