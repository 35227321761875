import React, {useEffect, useState} from 'react'
import { Spinner } from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_theme/helpers'
import {Card2} from '../../../_theme/partials/content/cards/Card2'
import {Project} from './components/Project'
import {getProjects, getProjectsByClass} from './core/requests'

export const ProjeTTT: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [projects, setProjects] = useState<any>([])
  const [project_status, setProjectStatus] = useState('0')
  const [total, setTotal] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () =>{
    refresh ?  setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    // getProjectsByClass('','').then(({data}) => {
    //   setLoading(false);
    //   setProjects(data.data)
    //   setTotal(data.meta.total)
    // })
  }, [project_status])
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '0' && 'active')
                }
                onClick={() => setProjectStatus('0')}
              >
                Aday Projeler
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '1' && 'active')
                }
                onClick={() => setProjectStatus('1')}
              >
                Devam Edenler
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '2' && 'active')
                }
                onClick={() => setProjectStatus('2')}
              >
                Tamamlananlar
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '3' && 'active')
                }
                onClick={() => setProjectStatus('3')}
              >
                Askıya Alınanlar
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '4' && 'active')
                }
                onClick={() => setProjectStatus('4')}
              >
                İptal Edilenler
              </span>
            </li>
          </ul>
        </div>
        <div className='row w-100'>
        {loading ? (
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <Spinner animation='border' variant='info' style={{width: '50px', height: '50px'}} />
          </div>
        ) : 
        total > 0 ? (
          <div className='row w-100 mt-10'>
            {projects.map((project: any) => {
              return (
                <Project
                  key={project.id}
                  id={project.id}
                  icon='/media/svg/brand-logos/plurk.svg'
                  type={project.type}
                  plan={project.plan}
                  status={project.status}
                  title={project.name}
                  description={project.description}
                  date={project.start_date}
                  budget={project.end_date}
                  progress={50}
                  users={project.created_by}
                  refreshPage={refreshPage}
                  refreshCount={undefined}
                />
              )
            })}
          </div>
        ) : (
          <>
            <div
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '10px',
                color: 'black',
                fontSize: '16px',
                paddingLeft: '16px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: '500',
                marginTop: '12px',
                marginBottom: '12px',
                textAlign:'center'
              }} className='d-flex justify-content-center'
            >
                      <img         style={{
                height: '200px',
                width: 'auto',
 
                textAlign:'center'
              }} alt='Logo' src={toAbsoluteUrl('/media/illustrations/dozzy-1/19.png')} className='' />
            </div>

            <div className='d-flex  justify-content-center w-100'>
              <h2 className='d-flex  text-center'>Hiç Proje Bulunamadı!</h2>

            </div>
          </>
        )}
        </div>
        
      </div>
    </>
  )
}
