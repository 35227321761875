/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {ID, KTSVG, toAbsoluteUrl} from '../../../../_theme/helpers'
import {UserItem} from '../../components/UserItem'
import {Dropdown} from 'react-bootstrap'
import swal from 'sweetalert'
import {DeleteProject} from '../core/requests'
import {SquareButton} from './SquareButton'

type Props = {
  id: ID
  icon: string
  type: any
  plan: any
  status: any
  title: string
  description: string
  date: string
  budget: string
  progress: number
  users?: any
  refreshPage: any
  refreshCount: any
}

const Project: FC<Props> = ({
  id,
  icon,
  type,
  plan,
  status,
  title,
  description,
  date,
  budget,
  progress,
  users = undefined,
  refreshPage,
  refreshCount,
}) => {
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteProject(id)
        swal('Silindi!', 'Proje başarı ile silindi.', 'success', {timer: 2000})
        refreshPage()
        refreshCount()
      }
    })
  }
  const setId = (id: any) => {
    localStorage.setItem('projectID', JSON.stringify(id))
  }
  return (
    <div className='col-12 col-md-4 mb-3'>
      <div className='card border border-2 border-gray-300 border-hover'>
        <div className='card-header border-0 pt-9 d-flex '>
          {/* <div className='card-title m-0'>
            <div className='symbol symbol-50px w-55px bg-light text-center'>
              <img src={toAbsoluteUrl('/media/logos/unnamed.png')} alt='card2' className='p-3' />
            </div>
          </div> */}
          <div className='card-toolbar d-flex flex-column align-items-start justify-content-start '>
            {/* <div >
              {' '}
              <span
                className={`badge badge-light-primary fw-bolder mr-10 px-4 py-3 `}
                style={{marginLeft: ''}}
              >
                {status?.name}
                {status?.name.length > 6 ? '...' : ''}
              </span>
            </div> */}

            {/* <div className=''>
              <span
                className={`badge badge-light-primary fw-bolder  px-4 py-3 mt-1`}
                style={{marginLeft: ''}}
              >
                {type?.name}
                {type?.name.length > 6 ? '...' : ''}
              </span>
            </div> */}

            {/* <div className=''>
              <span
                className={`badge badge-light-primary fw-bolder  px-4 py-3 mt-1 `}
                style={{marginLeft: ''}}
              >
                {plan?.name}
                {plan?.name.length > 6 ? '...' : ''}
              </span>
            </div> */}
            <div style={{width:'90%',minWidth:'250px'}}>
              <Link
                state={id}
                to='../../../projeler/detail-project'
                className='fs-3 fw-bolder text-dark w-75'
                onClick={() => setId(id)}
              >
                {title}
              </Link>

              <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
                {description?.slice(0, 30)}
                {description?.length > 30 ? '...' : ''}
              </p>
            </div>

            <div className='me-2' style={{position: 'absolute', right: '0px', top: '32px'}}>
              <Dropdown className=''>
                <Dropdown.Toggle
                  className='btn btn-light btn-active-light-primary btn-sm '
                  id='dropdown-basic'
                >
                  {/* <span className='svg-icon svg-icon-4'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20px'
                              height='20px'
                              viewBox='0 0 24 24'
                            >
                              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                                <rect
                                  x='14'
                                  y='5'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x='5'
                                  y='14'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x='14'
                                  y='14'
                                  width='5'
                                  height='5'
                                  rx='1'
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                              </g>
                            </svg>
                          </span> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    {' '}
                    <SquareButton status={true} issue={id} status_id={status?.id} />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={swalDelete}>Sil</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
              <div className='fs-6 text-gray-800 fw-bolder'>
                {date.substring(8, 10)}.{date.substring(5, 7)}.{date.substring(0, 4)}
              </div>
              <div className='fw-bold text-gray-400'>Başlangıç T.</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
              <div className='fs-6 text-gray-800 fw-bolder'>
                {budget.substring(8, 10)}.{budget.substring(5, 7)}.{budget.substring(0, 4)}
              </div>
              <div className='fw-bold text-gray-400'>Bitiş T.</div>
            </div>
          </div>

          {/* <div
            className='h-4px w-100 bg-light mb-5'
            data-bs-toggle='tooltip'
            title='This project completed'
          >
            <div
              className={`bg-${statusColor} rounded h-4px`}
              role='progressbar'
              style={{width: `${progress}%`}}
            ></div>
          </div> */}

          <UserItem id={users.id} name={users.name} email={''} />
        </div>
      </div>
    </div>
  )
}

export {Project}
function refreshPage() {
  throw new Error('Function not implemented.')
}
