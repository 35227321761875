import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'

import Chart from 'react-apexcharts'
import {downloadDLP, downloadVN, GetVN, GetVNStatistics} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'

import {ChartsWidget3} from '../components/ChartsWidget3'
import {SearchComponent} from '../components/SearchComponent'
import {AddVN} from './add-CA/Add-VN'
import {VNItem} from './components/VNItem'
import {bottom} from '@popperjs/core'
import {ChartsWidget4} from '../components/ChartsWidget4'
import {ChartsWidget5} from '../components/ChartsWidget5'

export const VN: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [vnList, setVnList] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [statistic, setStatistic] = useState<any>({total_close: 0, total_open: 0})
  const [statistic3, setStatistic3] = useState<any>([])
  const [statistic4, setStatistic4] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0

  const [title, setTitle] = useState<any>([])
  let count1: any = []
  let count2: any = []
  let title1: any = []
  const [totalOpen, setTotalOpen] = useState<any>([])
  const [totalClose, setTotalClose] = useState<any>([])

  const [title2, setTitle1] = useState<any>([])
  let count3: any = []
  let count4: any = []
  let title3: any = []
  const [totalOpen2, setTotalOpen1] = useState<any>([])
  const [totalClose2, setTotalClose1] = useState<any>([])

  useEffect(() => {
    GetVNStatistics('1').then((data) => {
      setStatistic(data.data)
    })
    GetVNStatistics('3').then((data) => {
      pstatisticType3(data.data)
      setStatistic3(data.data)
    })
    GetVNStatistics('2').then((data) => {
      pstatisticType2(data.data)
      setStatistic4(data.data)
    })
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetVN(link).then((data) => {
      setVnList(data.data.data)
      setSearchKeyClick('')
    })
  }, [refresh, searchKeyClick,show])

  const downloa = () => {
    downloadVN('').then((data) => {
      window.open(data?.data?.url)
    })
  }

  const state = {
    options: {},
    dataLabels: {
      enabled: true,
    },
    series: [Number(statistic?.total_open), Number(statistic?.total_close)],
    labels: ['Açık Kayıt', 'Kapalı Kayıt'],
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'center',
      fontSize: '16px',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    tooltip: {
      style: {
        fontSize: '20px',
      },
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ' Adet'
        },
      },
    },
  }
  const pstatisticType3 = (data: any) => {
    setTitle([])
    setTotalClose([])
    setTotalOpen([])
    for (let i = 0; i < data.length; i++) {
      count1.push(data[i]?.total_open)
      count2.push(data[i]?.total_close)
      title1.push(data[i]?.directorate_name.split(' '))
    }

    setTotalOpen(count1)
    setTotalClose(count2)
    setTitle(title1)
  }
  const pstatisticType2 = (data: any) => {
    setTitle1([])
    setTotalClose1([])
    setTotalOpen1([])

    for (let i = 0; i < data.length; i++) {
      count3.push(data[i]?.total_open)
      count4.push(data[i]?.total_close)
      title3.push(data[i]?.directorate_name)
    }
    setTotalOpen1(count3)
    setTotalClose1(count4)
    setTitle1(title3)

  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>

        <div>
          <div className='row g-5 g-xxl-10 mb-5'>
            <div className='col-xl-4 mb-xl-5 mb-xxl-10'>
              <div className='card h-xl-100'>
                <div className='card-body p-9 d-flex flex-column align-self-center align-items-center justify-content-center'>
                  <div className='fs-6 fw-bold text-gray-400 mb-2 text-center'>
                    Genel Zafiyet Bildirim Durum Adetleri
                  </div>
                  <div className='fs-2hx fw-bolder text-center'></div>
                  <div className='d-flex justify-content-center'>
                    <Chart options={state} series={state?.series} type='donut' width='380' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-8 mb-xl-7 mb-xxl-12'>
            <ChartsWidget4
                  className={''}
                  chartHeight={0}
                  categoriesData={title}
                  verticalTitle={''}
                  horizentalTitle={'Direktörlük Zafiyet Durum Dağılımı'}
                  pointTitle={''}
                  totalClose={totalClose}
                  totalOpen={totalOpen}
                ></ChartsWidget4>
            </div>
            {/* end::Col */}
          </div>
          {statistic3.length > 0 ? (
            <div className='row g-5 g-xxl-10  mb-5'>
              <div className='col-12'>
             
              </div>
              {/* end::Col */}
            </div>
          ) : (
            ''
          )}
          {statistic4.length > 0 ? (
            <div className='row g-5 g-xxl-10  mb-5'>
              <div className='col-12'>
                <ChartsWidget5
                  className={''}
                  chartHeight={0}
                  categoriesData={title2}
                  verticalTitle={''}
                  horizentalTitle={'Network Zafiyet Bildirim Durumu'}
                  pointTitle={''}
                  totalClose={totalClose2}
                  totalOpen={totalOpen2}
                ></ChartsWidget5>
              </div>
              {/* end::Col */}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder' >
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color:'#50c8da'}}>
                    <th className=''>Incident Id</th>
                    <th className=''>Technology</th>
                    <th className=''>Servis</th>
                    <th className=''>Category</th>
                    <th className=''>Title</th>
                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {vnList?.map((vn: any) => {
                    return (
                      <>
                        <VNItem key={vn?.id} vnList={vn} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddVN handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
