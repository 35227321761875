import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {ModalHeader} from '../../../components/ModalHeader'
import {AddUser} from '../../edit-modal/AddUser'

type Props = {
  refreshPage: any
}
const UsersListToolbar: FC<Props> = ({refreshPage}) => {
  const [show, setShow] = useState(false)
  const openAddUserModal = () => {
    setShow(true)
  }
  const closeAddUserModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Kullanıcı Ekle
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show} onHide={closeAddUserModal} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddUserModal} titleHeader={'Yeni Kullanıcı Ekle'} />
          <AddUser
            user={{
              id: 1,
              name: '',
              email: '',
              avatar: '',
              assignment :{
                id: 0,
                name: ''
              },
              certificates:'',
              experience:0,
              competencies: '',
              associate_degree:'',
              bachelors_degree:'',
              masters_degree:'',
              roles:[{
                id:0,
                name:''
              }],
              mobile_number:'',
              phone_number:''
            }}
            update={false}
            handleClose={closeAddUserModal}
            refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {UsersListToolbar}
