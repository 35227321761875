import axios, {AxiosResponse} from 'axios'



const API_URL = 'https://sgd.ecodation.com'
const ODATA = `${API_URL}/reports` 

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};



export async function addDLP(body:any) {
  return await axios.post(ODATA+'/dlp_statistics',body,config)
}
export async function GetDLP(q:string){
  return await axios.get(ODATA+'/dlp_statistics'+q)
}
export async function downloadDLP(q:string){
  return await axios.get(ODATA+'/dlp_statistics/download')
}
export async function GetDLPStatistics(){
    return await axios.get(ODATA+'/dlp_statistics/1')
}

//customer attackt

export async function addCA(body:any) {
  return await axios.post(ODATA+'/customer_attack_statistics',body,config)
}
export async function GetCA(q:string){
  return await axios.get(ODATA+'/customer_attack_statistics'+q)
}
export async function downloadCA(q:string){
  return await axios.get(ODATA+'/customer_attack_statistics/download')
}
export async function GetCAStatistics(type:string){
    return await axios.get(ODATA+'/customer_attack_statistics/'+type)
}

// Vulnerability Notification

export async function addVN(body:any) {
  return await axios.post(ODATA+'/vulnerability_notifications',body,config)
}
export async function GetVN(q:string){
  return await axios.get(ODATA+'/vulnerability_notifications'+q)
}
export async function downloadVN(q:string){
  return await axios.get(ODATA+'/vulnerability_notifications/download')
}
export async function GetVNStatistics(type:string){
    return await axios.get(ODATA+'/vulnerability_notifications/'+type)
}


// Data Securıty Request Number

export async function addDSRN(body:any) {
  return await axios.post(ODATA+'/data_security_request_numbers',body,config)
}
export async function GetDSRN(q:string){
  return await axios.get(ODATA+'/data_security_request_numbers'+q)
}
export async function downloadDSRN(q:string){
  return await axios.get(ODATA+'/data_security_request_numbers/download')
}
export async function GetDSRNStatistics(type:string){
    return await axios.get(ODATA+'/data_security_request_numbers/'+type)
}

// Income Assurance Data Summary

export async function addIADS(body:any) {
  return await axios.post(ODATA+'/income_assurance_data',body,config)
}
export async function GetIADS(q:string){
  return await axios.get(ODATA+'/income_assurance_data'+q)
}
export async function downloadIADS(q:string){
  return await axios.get(ODATA+'/income_assurance_data/download')
}
export async function GetIADSStatistics(type:string){
    return await axios.get(ODATA+'/income_assurance_data/'+type)
}


// Number of Demand

export async function addNOD(body:any) {
  return await axios.post(ODATA+'/number_of_demands',body,config)
}
export async function GetNOD(q:string){
  return await axios.get(ODATA+'/number_of_demands'+q)
}
export async function downloadNOD(q:string){
  return await axios.get(ODATA+'/number_of_demands/download')
}
export async function GetNODStatistics(type:string){
    return await axios.get(ODATA+'/number_of_demands/'+type)
}

// Alarm Trekıng

export async function addAT(body:any) {
  return await axios.post(ODATA+'/alarm_tracking',body,config)
}
export async function GetAT(q:string){
  return await axios.get(ODATA+'/alarm_tracking'+q)
}
export async function downloadAT(q:string){
  return await axios.get(ODATA+'/alarm_tracking/download')
}
export async function GetALStatistics(type:string){
    return await axios.get(ODATA+'/alarm_tracking/'+type)
}

// Mirroring

export async function addMirroring(body:any) {
  return await axios.post(ODATA+'/mirroring',body,config)
}
export async function GetMirroring(q:string){
  return await axios.get(ODATA+'/mirroring'+q)
}
export async function downloadMirroring(q:string){
  return await axios.get(ODATA+'/mirroring/download')
}
export async function GetMirroringStatistics(type:string){
    return await axios.get(ODATA+'/mirroring/'+type)
}

// Services

export async function addReport(name:string,body:any) {
  return await axios.post(ODATA+name,body,config)
}
export async function GetReport(name:string,q:string){
  return await axios.get(ODATA+name+q)
}
export async function downloadReport(name:string,q:string){
  return await axios.get(ODATA+name+'/download')
}
export async function GetReportStatistics(name:string,type:string){
    return await axios.get(ODATA+name+type)
}