import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {ISAMItem} from './components/ISAMItem'
import Chart from 'react-apexcharts'
import {downloadDLP, GetDLP, GetDLPStatistics} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {AddDLP} from './add-DLP/Add-DLP'

import {SearchComponent} from '../components/SearchComponent'
import { ChartsWidget3 } from '../components/ChartsWidget3'
import { PieChart } from '../../components/widgets/OperationalData/PieChart'

export const ISAM: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [dlp, setDLP] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [statistic, setStatistic] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0
  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()



  useEffect(() => {
    GetDLPStatistics().then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetDLP(link).then((data) => {
      setDLP(data.data.data)
    })
  }, [refresh,searchKeyClick,show])

  const downloa = ()=>{
    downloadDLP('').then((data)=>{
      window.open(data?.data?.url)
    })
  }

  const prdType = (data: any) => {
    setTitle([])
    total = 0
    for (let i = 0; i < data.length; i++) {
      total = total + data[i]?.total
      count1.push(data[i]?.total)
      title1.push(data[i]?.client_installation_version)
    }
    setPC(count1)
    setTitle(title1)
  }
  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        {statistic.length > 0 ? (
          <div className='row g-5 g-xxl-10'>
            {/* begin::Col */}

            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-5 col-xxl-4 mb-xl-5 mb-xxl-10'>
             <PieChart name={'nn'}/>
              {/* <div className='card h-100'>
                <div className='card-body p-3'>
                  <div className='fs-2 fw-bolder'> DLP İstatistikleri </div>
                  <div className='fs-4 fw-bold text-gray-400 mb-7'>Haftalık Durum</div>
                  <div>
                    <Chart options={chart3} series={planC} type='pie' />
                  </div>
                </div>
              </div> */}
            </div>
            <div className='col-xl-7 col-xxl-8 mb-xl-6 mb-xxl-12'>
              <ChartsWidget3
                className='h-100'
                chartHeight={200}
                categoriesData={title}
                data={planC} verticalTitle={'CLIENT ADETI'} horizentalTitle={'Forcepoint Ajan Versiyonlari'} pointTitle={'Adet'}              />
            </div>
            {/* end::Col */}
          </div>
        ) : (
          ''
        )}
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>
                  
                    <Button className='h-40px mt-3 mt-3' style={{marginRight:'10px'}} variant='primary' onClick={downloa}>
                        Dışarı Aktar
                     </Button>
                    <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                      Rapor Dosyası Ekle
                    </Button>
                  
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color:'#50c8da'}}>
                    <th className=''>Tarih</th>
                    <th className=''>Client Status</th>
                    <th className=''>Endpoint Server</th>
                    <th className=''>Client Installation Versir-</th>
                    <th className=''>Policy Engine Version</th>
                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {dlp?.map((dlp: any) => {
                    return (
                      <>
                        <ISAMItem key={dlp?.id} isam={dlp} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddDLP handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
