import axios from 'axios'
import { PagesModel } from '../models/PagesModel'


const API_URL = 'https://sgd.ecodation.com'

export const GET_ASIDE = `${API_URL}/pages`


// Server should return AuthModel
export async function getPages() {
  return await axios.get<any>(GET_ASIDE)
}