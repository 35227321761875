import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {downloadDLP, downloadIADS, GetIADS} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'

import {SearchComponent} from '../components/SearchComponent'
import { AddIADS } from './add-IADS/Add-IADS'
import { IADSItem } from './components/IADSItem'
import { IncomeAssuranceDataChart1 } from '../../components/widgets/OperationalData/IncomeAssuranceDataChart1'


export const IADS: FC = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [iads, setIADS] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0
 

  useEffect(() => {

    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetIADS(link).then((data) => {
      setIADS(data.data.data)
      setSearchKeyClick('');
    })
  }, [refresh, searchKeyClick,show])

  const downloa = () => {
    downloadIADS('').then((data) => {
      console.log(data)
      window.open(data?.data?.url)
    })
  }


  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
    
          <div>
            <div className='row g-5 '>
              <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
                <IncomeAssuranceDataChart1 className={''} chartHeight={0} type={'2?company=TTMOBIL'} verticalTitle={''} horizentalTitle={'GELİR GÜVENCE BULGULARI (TT Mobil)'} pointTitle={''}/>
              </div>
              <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <IncomeAssuranceDataChart1 className={''} chartHeight={0} type={'2?company=TT'} verticalTitle={''} horizentalTitle={'GELİR GÜVENCE BULGULARI (TT)'} pointTitle={''}/>
              </div>
              <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 overflow-hidden'>
              <IncomeAssuranceDataChart1 className={''} chartHeight={0} type={'2?company=TTNET'} verticalTitle={''} horizentalTitle={'GELİR GÜVENCE BULGULARI (TTNET)'} pointTitle={''}/>
            </div>
              {/* end::Col */}
            </div>

          </div>

      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3 overflow-auto'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder text-center'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color:'#50c8da'}}>
                    <th className=''>case_no</th>
                    <th className=''>ra_user</th>
                    <th className=''>company</th>
                    <th className=''>case_title</th>
                    <th className=''>effected_amount</th>
                    <th className=''>loss</th>
                    <th className=''>correction</th>
                    <th className=''>assured</th>
                    <th className=''>informed_departments</th>
    
                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {iads?.map((iads: any) => {
                    return (
                      <>
                        <IADSItem key={iads?.id} item={iads} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <AddIADS handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
