import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import { AddBlog } from '../../../Blog/blog-edit-modal/AddBlog'
import {ModalHeader} from '../../../components/ModalHeader'
import { AddBudget } from '../../Budget-edit/AddBudget'
type Props = {
  refreshPage: any
}
const BudgetToolbar: FC<Props> = ({refreshPage}) => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  const [show, setShow] = useState(false)
  const openAddSupModal = () => {
    setShow(true)
  }
  const closeAddSupModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={openAddSupModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Bütçe Ekle
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show} onHide={closeAddSupModal} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddSupModal} titleHeader={'Yeni Bütçe Ekle'} />
          <AddBudget budget={
            {
              title: '',
              amount: '',
              currency: '',
              assignment:{
                id: 0
              } ,
              owner :{
                id: 0
              },
              type:{
                id: 0
              },
              category:{
                id: 0
              } ,
              company:{
                id: 0
              } ,
              purchase_type:{
                id:0
              } ,
              year: ''
            }
          } update={false} handleClose={closeAddSupModal} refreshPage={refreshPage}/>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {BudgetToolbar}
