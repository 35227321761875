import React, {FC} from 'react'

type Props = {
  data: any
}

export const TableData: FC<Props> = ({data}) => {
  const border = {
    border:'1px',
    borderColor:'gray'
  }
  const overflow = {
   overflow:'scroll',

  }

  return (
    <div className='w-100 d-flex ' >
      <div className=''>

        <div className='h-30px border ' style={border}></div>
        <div className='d-flex align-items-center justify-content-start p-1 border border-top-0' style={border}>
          <div className='m-1' style={{width: '20px', height: '2px', backgroundColor: '#56BDFF'}}></div>
          Data Transfer Permission
        </div>
        <div className='d-flex align-items-center justify-content-start p-1 border border-top-0' style={border}>
          <div className='m-1' style={{width: '20px', height: '2px', backgroundColor: '#6BFFC7'}}></div>
          Web Access
        </div>
        <div className='d-flex align-items-center justify-content-start p-1 border border-top-0' style={border}>
          <div className='m-1' style={{width: '20px', height: '2px', backgroundColor: '#FFE263'}}></div>
          Disable Mod
        </div>
      </div>
      <div className='d-flex ' style={overflow}>
        {data?.map((item: any) => {
          return (
            <div className='text-center'>
              <div className='p-1 h-30px border border-left-0' style={border}>{item?.month} </div>
              <div className=' p-1 border border-top-0 border-left-0' style={border}>
                {item?.total_data_transfer}
              </div>
              <div className=' p-1 border border-top-0 border-left-0' style={border}>
                {item?.total_web_access}
              </div>
              <div className=' p-1 border border-top-0 border-left-0' style={border}>
                {item?.total_disable_mod}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
