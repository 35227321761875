import React, {FC} from 'react'

type Props = {
    time:string
    title: string
}

export const HeaderAlertItem: FC<Props> = ({time,title}) => {
  return (
    <div className='d-flex  align-items-center pb-1'>
      <span className='badge badge-danger p-3' style={{borderRadius:'15px'}}>{time}</span>
      <span className='text-gray-800 m-3'>
          {title}
      </span>
    </div>
  )
}
