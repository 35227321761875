import React, {useEffect, useState} from 'react'
import { Spinner } from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_theme/helpers'
import {Card2} from '../../../_theme/partials/content/cards/Card2'
import { DirectorateWidget } from '../components/widgets/DirectorateWidget'
import {Project} from './components/Project'
import { ProjectsTableHeader } from './components/ProjectsTableHeader'
import {getProjects, getProjectsByClass} from './core/requests'

const ClassB: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [type, setType] = useState('aday')
  const [listShow, setShow] = useState(false)
  const location = useLocation()
  const [projects, setProjects] = useState<any>([])
  const [total, setTotal] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const refreshPage = () =>{
    refresh ?  setRefresh(false) : setRefresh(true)
  }
  const [selectedDirectorate, setSelectedDirectorate] = useState('')
  const [directorateId, setDirectorateId] = useState<any>('')
  const getDirectorateId = (id: string) => {
    setDirectorateId(id)
  }
  useEffect(() => {
    getProjectsByClass('2','1',directorateId).then(({data}) => {
      setLoading(false);
      setProjects(data.data)
      setTotal(data.meta.total)
    })
  }, [directorateId])
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
      {/* <div className='d-flex overflow-auto h-55px justify-content-between w-100'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '0' && 'active')
                }
                onClick={() => setProjectStatus('0')}
              >
                Aday Projeler
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '1' && 'active')
                }
                onClick={() => {setProjectStatus('1')
              setType('devam')}}
              >
                Devam Edenler
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '2' && 'active')
                }
                onClick={() => {setProjectStatus('2') 
                 setType('tamamlanan')}}
              >
                Tamamlananlar
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '3' && 'active')
                }
                onClick={() => {setProjectStatus('3')
                setType('aski')}}
              >
                Askıya Alınanlar
              </span>
            </li>
            <li className='nav-item'>
              <span
                className={
                  `nav-link text-active-primary me-6 ` + (project_status === '4' && 'active')
                }
                onClick={() => {setProjectStatus('4')
                setType('aski')}}
              >
                İptal Edilenler
              </span>
            </li>
          </ul>
          <ul className='nav nav-pills me-5'>
            <li className='nav-item m-0'>
              <a
                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${
                  listShow ? 'active' : ''
                }`}
                //data-bs-toggle='tab'
                onClick={() => setShow(true)}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                      <rect
                        x='14'
                        y='5'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                      <rect
                        x='5'
                        y='14'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                      <rect
                        x='14'
                        y='14'
                        width='5'
                        height='5'
                        rx='1'
                        fill='currentColor'
                        opacity='0.3'
                      />
                    </g>
                  </svg>
                </span>
              </a>
            </li>
            <li className='nav-item m-0'>
              <a
                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${
                  listShow ? '' : 'active'
                }`}
                // data-toggle='tab'
                onClick={() => setShow(false)}
              >
                <span className='svg-icon svg-icon-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                      fill='currentColor'
                    />
                    <path
                      opacity='0.3'
                      d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div> */}
        <div className='row  w-100' >
          <div className='col-12'>
            <DirectorateWidget
              title='Müdürlük Bazlı Projeler'
              getSelectedId={getDirectorateId}
              setSelectedDirectorate={setSelectedDirectorate}
              widgetId={24}
              plusOrMinus={true}
            />
          </div>
        </div>
        <div className='row w-100'>
        {loading ? (
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <Spinner animation='border' variant='info' style={{width: '50px', height: '50px'}} />
          </div>
        ) : 
        total > 0 ? (
          <div className='row w-100 mt-10'>
      { listShow ?
            projects.map((project: any) => {
              return (
                <Project
                  key={project.id}
                  id={project.id}
                  icon='/media/svg/brand-logos/plurk.svg'
                  type={project.type}
                  plan={project.plan}
                  status={project.status}
                  title={project.name}
                  description={project.description}
                  date={project.start_date}
                  budget={project.end_date}
                  progress={50}
                  users={project.created_by}
                  refreshPage={refreshPage}
                  refreshCount={undefined}
                />
              )
            })
            :
            <ProjectsTableHeader
                  modalShow={undefined}
                  setCount={undefined}
                  directorateID={undefined}
                  searchKeyClick={''}
                  selectedType={''}
                  selectedStatus={''}
                  selectedPlan={''}
                  refreshPage={undefined}
                  projects={projects} type={type}            />}
          </div>
        ) : (
          <>
            <div
              style={{
                height: '200px',
                width: '100%',
                borderRadius: '10px',
                color: 'black',
                fontSize: '16px',
                paddingLeft: '16px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: '500',
                marginTop: '12px',
                marginBottom: '12px',
                textAlign:'center'
              }} className='d-flex justify-content-center'
            >
                      <img         style={{
                height: '200px',
                width: 'auto',
 
                textAlign:'center'
              }} alt='Logo' src={toAbsoluteUrl('/media/illustrations/dozzy-1/19.png')} className='' />
            </div>

            <div className='d-flex  justify-content-center w-100'>
              <h2 className='d-flex  text-center'>Hiç Proje Bulunamadı!</h2>

            </div>
          </>
        )}
        </div>
      </div>
    </>
  )
}
const users2 = [
  {name: 'Alan Warden', initials: 'A', color: 'warning'},
  {name: 'Brian Cox', avatar: '/media/avatars/300-5.jpg'},
]

export {ClassB}
