import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {User} from '../components/User'
import {DeleteAnnoun, getAnnounDetail} from '../core/_requests'
import swal from 'sweetalert'
import {useNavigate} from 'react-router-dom'
import {AddAnnoun} from '../announcement-edit-modal/AddAnnoun'
import {ModalHeader} from '../../components/ModalHeader'
import {ButtonActive} from '../../Activities/components/ButtonActive'
import {FileItem} from '../components/fileItem'
import '../../style/style-swal.css'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {Users} from '../components/Users'
import {UpdateAtachments} from '../announcement-edit-modal/UpdateAtachments'

type LocationState = {
  id: ID
  // title: string
  // content: string
  // is_public: boolean
  // is_slider: boolean
  // email_notification: boolean
  // phone_notification: boolean
  // attachments: any
  // banner: any
  files: any
  // category: {
  //   id: string
  //   name: string
  // }
  created_by: {
    id: number
    name: string
    email: string
    avatar: string
  }
  // start_date: string
  // end_date: string
  // assignments: [
  //   {
  //     id: 1
  //     name: ''
  //   }
  // ]
  // readers: any
}

export const AnnouncementDetail: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    ' Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ]
  const [show, setShow] = useState(false)
  let data = location.state as LocationState
  const [announcement, setAnnouncement] = useState({
    id: 1,
    title: '',
    content: '',
    is_public: false,
    is_slider: false,
    email_notification: false,
    phone_notification: false,
    start_date: '',
    end_date: '',
    category: {
      id: 1,
      name: '',
    },
    attachments: '',
    assignments: [
      {
        id: 1,
        name: '',
      },
    ],
    created_by: {
      id: 1,
      name: '',
      email: '',
      avatar: '',
    },
    files: data.files,
    readers: [
      {
        id: 1,
        name: '',
      },
    ],
  })
  const [showAtachModal, setShowAtachModal] = useState(false)
  const [controlButtons, setControlbuttons] = useState(false)
  const handleClose = () => setShow(false)
  const handleCloseAtach = () => setShowAtachModal(false)
  const handleShow = () => setShow(true)
  const handleShowAtach = () => setShowAtachModal(true)

  useEffect(() => {
    if (data.created_by.id == user.id || user.id === 36) {
      setControlbuttons(true)
    }
    getAnnounDetail(data.id?.toString()).then(({data}) => {
      // data = data.data
      setAnnouncement(data.data)
    })
  }, [show, showAtachModal])

  // Delete announcement and route to announcement page
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        await DeleteAnnoun(data.id)
        swal('Silindi!', 'Duyuru başarı ile silindi.', 'success')
        navigate('/duyurular/tum-duyurular')
      }
    })
  }
  const imgLink = announcement.files.length
    ? announcement.files[0].path
    : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <div>
        <div className='container'>
          <img
            src={imgLink}
            alt='announcement photo'
            className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded  w-100'
            style={{backgroundSize: '100% 100%'}}
          />
          <div className='card bg-body me-9 pb-lg-18 w-100 mt-5'>
            <div className='card-body pb-lg-20'>
              <div className='mb-13 '>
                <div className='mb-9 d-flex flex-row justify-content-between'>
                  <div>
                    <h3 className='fs-2qx fw-bolder text-dark'>{announcement.title}</h3>
                    <span className='fs-5 fw-bold text-gray-400'>
                      {/* {announcement.end_date} */}
                      {announcement.end_date.substring(8, 10)}{' '}
                      {months[parseInt(announcement.end_date.substring(5, 7)) - 1]}{' '}
                      {announcement.end_date.substring(0, 4)}
                    </span>
                  </div>
                  <User
                    id={announcement.created_by.id}
                    name={announcement.created_by.name}
                    email={announcement.created_by.email}
                    avatar={announcement.created_by.avatar}
                  />
                </div>
                <div className='fs-5 fw-bold text-gray-600 mt-4'>
                  <p className='mb-8'>{announcement.content}</p>
                </div>
              </div>
              {announcement.files.length > 1 ? (
                <div className='overflow-auto pb-5'>
                  <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7  scroll-x'>
                    {announcement.files.map(
                      (el: {path: string; name: string; size: string; id: ID}, index: number) => {
                        if (index !== 0)
                          return (
                            <FileItem
                              key={el.id}
                              fileLink={el.path}
                              name={el.name}
                              size={el.size}
                            />
                          )
                      }
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}

              {controlButtons ? (
                <div className='d-flex justify-content-left'>
                  <div className='d-flex justify-content-between'>
                    <div className='nav-item h-10 me-5 mt-10' role='presentation'>
                      <ButtonActive text={'Düzenle'} clickFunc={handleShow} />
                    </div>
                    <div className='nav-item h-10  mt-10' role='presentation'>
                      <ButtonActive text={'Sil'} clickFunc={swalDelete} />
                    </div>
                    <div className='nav-item h-10 w-150px   mt-10' role='presentation'>
                      <ButtonActive text={'Dosya Düzenle'} clickFunc={handleShowAtach} />
                    </div>
                  </div>
                  {announcement.readers.length > 1 && (
                    <div className='w-100 d-flex flex-column align-items-end'>
                      <p>içeriğe erişim sağlayanlar</p>
                      <Users users={announcement.readers.filter((u) => u.id != user.id)} />
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />

          <AddAnnoun
            handleClose={handleClose}
            update={true}
            announcement={{
              id: announcement.id,
              title: announcement.title,
              start_date: announcement.start_date,
              end_date: announcement.end_date,
              content: announcement.content,
              is_public: announcement.is_public,
              is_slider: announcement.is_slider,
              email_notification: announcement.email_notification,
              phone_notification: announcement.phone_notification,
              announcement_category_id: announcement.category.id,
              attachments: announcement.attachments,
              assignments: announcement.assignments,
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showAtachModal} onHide={handleCloseAtach}>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseAtach} titleHeader={'Dosya Düzenle'} />

          <UpdateAtachments
            handleClose={handleCloseAtach}
            update={true}
            announcement={{
              id: data.id,
              attachments: announcement.files,
              banner: announcement.files[0],
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
