import {FC, useState} from 'react'
import swal from 'sweetalert'
import {DeleteSupplier} from '../core/_requests'
import {KTSVG} from '../../../../_theme/helpers'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddSupp} from '../supplier-edit-modal/AddSupp'
import {Link, NavLink} from 'react-router-dom'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'

type Props = {
  supplier: any
  refreshPage: any
}

function calTime(date: string) {
  if (date !== undefined) {
    let dateTime = date.split('T')
    // dateTime[1] = '  '+dateTime[1].slice(0,5);
    return dateTime
  }
}

const deleteSupp = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteSupplier(id).then(() => {
        swal('Silindi!', 'Tedarki bilgileri başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const ListItem: FC<Props> = ({supplier, refreshPage}) => {
  const [showModal, setShowmodal] = useState(false)
  const handleClose = () => {
    setShowmodal(false)
    refreshPage()
  }
  const handleShow = () => {
    setShowmodal(true)
  }

  return (
    <>
      <tr>
        <td className='fw-bolder'>
          <NavLink to='../detail' state={supplier} className='text-gray-900 text-hover-primary'>
            {supplier.name}
          </NavLink>
        </td>
        <td className='text-gray-500'>{supplier.email}</td>
        <td className='text-gray-500'>{supplier.company_name}</td>
        <td className='text-gray-500'>
          {supplier.created_at.substring(8, 10)}.{supplier.created_at.substring(5, 7)}.
          {supplier.created_at.substring(0, 4)}
        </td>
        <td className='text-end'>
          <a
            href='#'
            className='btn btn-light btn-active-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            İşlem
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
          </a>
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <NavLink to='../detail' state={supplier} className='menu-link px-3'>
                Görüntüle
              </NavLink>
            </div>
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={async () => await handleShow()}>
                Düzenle
              </a>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                data-kt-users-table-filter='delete_row'
                onClick={async () => await deleteSupp(supplier.id, refreshPage)}
              >
                Sil
              </a>
            </div>

            {/* end::Menu item */}
          </div>
        </td>
      </tr>
      <Modal show={showModal} onHide={handleClose} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddSupp
            supplier={{
              id: supplier.id,
              name: supplier.name,
              email: supplier.email,
              company_name: supplier.company_name,
              phone: supplier.phone,
              tax_number: supplier.tax_number,
              country_id: supplier.country.id,
              supplier_category_id: supplier.category.id,
              address: supplier.address,
              attachments: supplier.files,
              phone_notification: supplier.phone_notification,
              email_notification: supplier.email_notification,
            }}
            refreshPage={refreshPage}
            handleClose={handleClose}
            update={true}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
