import { FC, useEffect, useState } from "react";
import { getUserIssues } from "./core/_request";
import { ListIssues } from "./ListIssues";
import { TimeLine } from "./TimeLine";

type Props={
    id: any
}
export const Overview :FC<Props>=({id})=>{
    const [issues, setIssues]= useState([{
        id:1,
        title: '',
        content: '',
        start_date:'',
        end_date:'',
        phone_notification: false,
        email_notification: false,
        comments: [],
        category: {
          id: 1,
          name: ''
        },
        status: {
          id: '0',
          name :'Planlanan'
        },
        assigned: {
          id :1,
          name: '',
          email: ''
        }
  
    }])
    useEffect(()=>{
        getUserIssues(id).then(({data})=>{
            setIssues(data.data)
        })
    },[])
    return(
        <>
        {/* <div id='kt_project_targets_card_pane'>
            <div className='card card-flush'>
                <div className='card-body pt-3'> */}
                    <TimeLine day={9} user_id={id}/>
                {/* </div></div></div> */}
                <div id='kt_project_targets_card_pane'>
                <div className='card card-flush'>
                    <div className='card-body pt-3'>
                        <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                            <thead className='fs-7 text-gray-400 text-uppercase'>
                                <tr>
                                    <th className=''>FAALIYET</th>
                                    <th className=''>KATEGORİ</th>
                                    <th className=''>BAŞlANGIÇ TARİHİ</th>
                                    <th className=''>BİTİŞ TARİH</th>
                                    <th className=''>SORUMLU</th>
                                    <th className=''>DURUM</th>

                                </tr>
                            </thead>

                            <tbody className='fs-6'>
                                {issues.map((activity) => {
                                    return (
                                        <ListIssues
                                            key={activity.id}
                                            id={activity.id}
                                            title={activity.title}
                                            categoryName={activity.category.name}
                                            start_date={activity.start_date}
                                            end_date={activity.end_date}
                                            userName={activity.assigned?.name}
                                            status={'In Progress'}
                                            issue={activity}
                                            assigned_id={activity.assigned?.id} />
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div></>
    )
}