import {FC, useEffect, useState} from 'react'
import { KTCard } from '../../../../_theme/helpers'
import { NoMatchRecords } from '../../components/NoMatchRecords'
import {GetSuppliers} from '../core/_requests'
import {ListItem} from './ListItem'
import { UsersListHeader } from './userHeader/UsersListHeader'

export const SupplierList: FC = () => {
  const [supplier, setSupplier] = useState([
    {
      id: 1,
      name: '',
      emial: '',
      company_name: '',
      phone: '',
      tax_number: '',
      country: {
        id: 1,
        name: '',
      },
      category: {
        id: 1,
        name: '',
      },
      address: '',
      attachments: [],
      email_notification: false,
      phone_notification: false,
      created_at: '',
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  useEffect(() => {
    let link = '?q=' + searchKeyClick
    GetSuppliers(link).then(({data}) => {
      setSupplier(data.data)
    })
  }, [refresh, searchKeyClick])
  return (
    <>
    {/* <KTCard> */}
    <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick}/>
    <div id='kt_project_targets_card_pane'>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
           
            <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th className=''>AD SOYAD</th>
                  <th className=''>E-POSTA</th>
                  <th className=''>ŞİRKET</th>
                  <th className=''>OLUŞTURMA TARİHİ</th>
                </tr>
              </thead>

              <tbody className=''>
                {supplier.length<1 ? <NoMatchRecords isTable={true}/> : supplier.map((supplier) => {
                  return (
                    <ListItem key={supplier.id} supplier={supplier} refreshPage={refreshPage} />
                  )
                })}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    {/* </KTCard> */}
  
    </>
  )
}
