import {title} from 'process'
import React, {FC, Suspense} from 'react'
import {Link, Navigate, NavLink, Outlet, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_theme/assets/ts/_utils'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {HomePage} from '../Home/HomePage'
import { Canceled } from './Canceled'
import {ClassA} from './ClassA'
import {ClassB} from './ClassB'
import {ClassC} from './ClassC'

import {Completed} from './Completed'
import {Suspended} from './Suspended'
import DetailPagesWrapper from './project-details/DetailPagesWrapper'
import {ProjectHomePage} from './ProjectHomePage'
import {ProjeTTT} from './ProjetTTT'
import {Candidates} from './Candidates'

const projectsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projeler',
    path: '/projeler/sdlc-proje-bazli-birim-eforlari',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectsPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='sdlc-proje-bazli-birim-eforlari'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / Aday Projeler
              </PageTitle>
              <Candidates/>
            </>
          }
        />
        <Route
          path='a-sinifi-projeler'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / A sınıfı projeler
              </PageTitle>
              <ClassA />
            </>
          }
        />
        <Route
          path='b-sinifi-projeler'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / B sınıfı projeler
              </PageTitle>
              <ClassB />
            </>
          }
        />
        <Route
          path='c-sinifi-projeler'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / C sınıfı projeler
              </PageTitle>
              <ClassC />
            </>
          }
        />
        <Route
          path='pentest'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / Tamamlanan Projeler
              </PageTitle>
              <Completed/>
            </>
          }
        />
        <Route
          path='projettt'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / Proje-TTT
              </PageTitle>
              <ProjeTTT />
            </>
          }
        />
        <Route
          path='poc-fizibilite'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/tum-projeler', isActive: true},
                ]}
              >
                / Askıya Alınan Projeler
              </PageTitle>
              <Suspended />
            </>
          }
        />
           <Route
          path='iptal-edilen-projeler'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {title: 'Projeler', path: '../projeler/iptal-edilen-projeler', isActive: true},
                ]}
              >
                / İptal Edilen Projeler
              </PageTitle>
              <Canceled />
            </>
          }
        />
 
        <Route
          path='tum-projeler'
          element={
            <>
              <PageTitle>Projeler</PageTitle>
              <ProjectHomePage />
            </>
          }
        ></Route>

        <Route index element={<Navigate to='/sdlc-proje-bazli-birim-eforlari' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {ProjectsPageWrapper}
