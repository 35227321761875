import { FC } from "react"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import { PageTitle } from "../../../_theme/layout/core"
import { BudgetDetail } from "./Budget-detail/BudgetDetail"
import { Budgets } from "./Budgets"
import { Homepage } from "./Homepage"


export const BudgetsPageWrapper : FC =()=>{
    return(
        <Routes>
        <Route element={<Outlet />}>
          <Route
            path='butceler'
            element={<>
              <PageTitle>Bütçeler</PageTitle>
              <Budgets />
            </>} />
            <Route
            path='anasayfa'
            element={<>
              <PageTitle>Bütçeler</PageTitle>
              <Homepage />
            </>} />
          <Route
            path='detail'
            element={<>
              <PageTitle breadcrumbs={[{title:'Bütçe',path:'../butce/butceler',isActive:true}]}>/Detay</PageTitle>
              <BudgetDetail/>
            </>} />
          </Route>

        {/* <Route
          path=''
          element={<>
            <PageTitle>Faaliyetler</PageTitle>
            <Activities />
          </>} /> */}
      <Route index element={<Navigate to='/butce/butceler' />} /></Routes>
    )
}