import {FC, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSSVariableValue } from '../../../../_theme/assets/ts/_utils'
import { KTUtil } from '../../../../_theme/assets/ts'




type Props = {
  className: string
  chartHeight: number
  categoriesData:any
  totalClose:any
  totalOpen:any
  verticalTitle: string
  horizentalTitle: string
  pointTitle: string
}

export const ChartsWidget4: FC<Props> = ({className, chartHeight,categoriesData,totalClose,totalOpen, verticalTitle,horizentalTitle,pointTitle}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
 
  useEffect(() => {

     if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight,categoriesData,totalClose,totalOpen,verticalTitle,pointTitle))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  
  
  }, [chartRef,categoriesData])

  return (
    <div className={`card card-flush overflow-hidden h-100 ${className}`}>
      {/* begin::Header */}
      <div className='card-header text-center'>
        {/* begin::Title */}
        <h3 className='card-title align-items-center flex-column text-center w-100 m-1'>
          <span className='card-label fw-bolder text-dark'>{horizentalTitle}</span>
         
        </h3>
        {/* end::Title */}
        {/* begin::Toolbar */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column'>
        {/* begin::Statistics */}
        {/* end::Statistics */}
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptions = (chartHeight: number,categoriesData:any,totalClose:any,totalOpen:any,verticalTitle:string,pointTitle: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')
  var secondaryColor  = getCSSVariableValue('--bs-success');
  //var height = parseInt(KTUtil.css(Element, 'height'));

  return {
    
      series: [
        {
          name: 'Açık Kayıt',
          data: totalClose
      },
        {
          name: 'Kapalı Kayıt',
          data: totalOpen
      } ],
      chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: 400,
          toolbar: {
              show: false
          }
      },
      plotOptions: {
          bar: {
              horizontal: true,
              columnWidth: '20px',
         
          },
      },
      legend: {
     
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Açık Kayıt', 'Kapalı Kayıt'],
          markers: {
            fillColors: ['#6BFFC7', '#008FFB']
          },
        
      },
      dataLabels: {
          enabled: true,
       
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
          

      },
   
      stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
      },
      xaxis: {
          categories: categoriesData,
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false
          },
          labels: {
              style: {
                  colors: labelColor,
                  fontSize: '12px'
              }
          }
      },
      yaxis: {
          labels: {
              style: {
                  colors: labelColor,
                  fontSize: '12px'
              }
          }
      },
      fill: {
          opacity: 1
      },
      states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0
              }
          },
          hover: {
              filter: {
                  type: 'none',
                  value: 0
              }
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0
              }
          }
      },
      tooltip: {
          style: {
              fontSize: '12px'
          },
          y: {
              formatter: function (val) {
                  return val + ' adet'
              }
          }
      },
      colors: ['#6BFFC7', '#008FFB'],
      grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
              lines: {
                  show: true
              }
          }
      }
  };
  
}
