import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {getSuppliers} from '../../Supplier/core/_requests'
import {CreateInventory, GetInventoryCategories, UpdateInventory} from '../core/_requests'
import swal from 'sweetalert'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'
import {AddCategory} from '../../Categories/AddCategory'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import * as Yup from 'yup'

type Props = {
  inventory: any
  update: boolean
  handleClose: any
  refreshPage: any
}
const inventorySchema = Yup.object().shape({
  supplier_id: Yup.number().required('Zorunlu alan'),
  inventory_category_id: Yup.number().required('Zorunlu alan'),
  product_name: Yup.string().required('Zorunlu alan'),
  product_code: Yup.string().required('Zorunlu alan'),
  quantity: Yup.number().required('Zorunlu alan'),
  amount: Yup.number().required('Zorunlu alan'),
  currency: Yup.string().required('Zorunlu alan'),
  start_date: Yup.date()
  .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
  .required('Zorunlu alan'),
  end_date: Yup.date()
  .required('Zorunlu alan')
  .when(
    'start_date',
    (start_date, yup) =>
      start_date &&
      yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
  ),
  contract_date: Yup.date()
  .required('Zorunlu alan')
  .when(
    'start_date',
    (start_date, yup) =>
      start_date &&
      yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
  )
 
})

export const AddInventory: FC<Props> = ({inventory, update, handleClose, refreshPage}) => {
  const [file, setFile] = useState<any>([])
  const [files, setFiles] = useState<any>([])
  const [showFile, setShowFile] = useState<any>([]) //banner show
  const [errors, setErrors] = useState('')
  const [errors2, setErrors2] = useState('')
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [suppliers, setSuppliers] = useState([
    {
      id: 0,
      name: '',
      company_name: '',
      email: '',
    },
  ])
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  let formData = new FormData()

  useEffect(() => {
    GetInventoryCategories().then(({data}) => {
      setCategories(data.data)
    })

    getSuppliers().then(({data}) => {
      setSuppliers(data.data)
    })
  }, [showCategoryModal])

  const removeFile = (f: any) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(f), 1)
    setFiles(newFiles)
  }
  //banner
  const removeFiles = (f: any) => () => {
    const newFiles = [...showFile]
    newFiles.splice(newFiles.indexOf(f), 1)
    setShowFile(newFiles)
  }
  //file
  const removeAll = () => {
    setFiles([])
  }
  //file
  const filess = files.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span className='btn btn-sm btn-primary mr-10' onClick={removeFile(file)}>
          Sil
        </span>
      </li>
    )
  )

  //banner
  const f = showFile.map(
    (
      file: {
        path: {} | null | undefined
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      },
      index: React.Key | null | undefined
    ) => (
      <li key={index} className='d-flex justify-content-between m-5'>
        <span className='w-50 ml-10'>
          {file.path} - {file.size} bytes{' '}
        </span>{' '}
        <span
          className='btn btn-sm btn-primary mr-10 d-flex align-items-center'
          onClick={removeFiles(file)}
        >
          Sil
        </span>
      </li>
    )
  )
  const [userForEdit] = useState<any>({
    ...inventory,
    id: inventory.id,
    product_name: inventory.product_name,
    product_code: inventory.product_code,
    quantity: inventory.quantity,
    amount: inventory.amount,
    currency: inventory.currency,
    inventory_category_id: inventory.inventory_category_id,
    supplier_id: inventory.supplier_id,
    start_date: inventory.start_date,
    end_date: inventory.end_date,
    contract_date: inventory.contract_date,
    product_image: inventory.attachments[0],
    attachments: inventory.attachments,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: inventorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          let supplier = suppliers.filter((s) => s.id == values.supplier_id)
          let UpdInventory = {
            product_name: values.product_name,
            product_code: values.product_code,
            quantity: values.quantity,
            amount: values.amount,
            currency: values.currency,
            start_date: values.start_date,
            end_date: values.end_date,
            contract_date: values.contract_date,
            supplier_id: values.supplier_id,
            inventory_category_id: values.inventory_category_id,
            contact_name: supplier[0].name,
            contact_email: supplier[0].email,
          }
          await UpdateInventory(inventory.id, UpdInventory)
            .then(() => {
              swal('Düzenlendi', 'Envanter başarı ile düzenlendi.', 'success', {timer: 2000})
              handleClose()
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          let supplier = suppliers.filter((s) => s.id == values.supplier_id)

          formData.append('product_name', values.product_name)
          formData.append('product_code', values.product_code)
          formData.append('quantity', values.quantity)
          formData.append('amount', values.amount)
          formData.append('currency', values.currency)
          formData.append('start_date', values.start_date)
          formData.append('end_date', values.end_date)
          formData.append('contract_date', values.contract_date)
          formData.append('supplier_id', values.supplier_id)
          formData.append('inventory_category_id', values.inventory_category_id)
          formData.append('product_image', file)
          formData.append('contact_name', supplier[0].name)
          formData.append('contact_email', supplier[0].email)
          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[' + i + ']', files[i])
          }
          await CreateInventory(formData)
            .then(() => {
              handleClose()
              swal('Eklendi', 'Envanter başarı ile eklendi.', 'success', {timer: 2000})
              refreshPage()
            })
            .catch((err) => {
              console.log(err.message)
              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  return (
    <>
      <form
        id='kt_modal_add_inventory_form'
        onSubmit={formik.handleSubmit}
        className='form'
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_inventory_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_inventory_header'
          data-kt-scroll-wrappers='#kt_modal_add_inventory_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Ürün Adı</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('product_name')}
              type='text'
              name='product_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.product_name && formik.errors.product_name},
                {
                  'is-valid': formik.touched.product_name && !formik.errors.product_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.product_name && formik.errors.product_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.product_name}</span>
            </div>
          </div>
        )}
            {/* end::Input */}
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Ürün Kodu</label>
              {/* end::Label */}

              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('product_code')}
                type='text'
                name='product_code'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.product_code && formik.errors.product_code},
                  {
                    'is-valid': formik.touched.product_code && !formik.errors.product_code,
                  }
                )}
                autoComplete='off'
              />
               {formik.touched.product_code && formik.errors.product_code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.product_code}</span>
            </div>
          </div>
        )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <div className='d-flex justify-content-between'>
                <label className='required fw-bold fs-7 mb-2'>Envanter Kategorisi</label>
                {loginUser.name === 'Süper Admin Kullanıcısı' && (
                  <img
                    src='https://cdn-icons-png.flaticon.com/512/992/992651.png'
                    alt='add-icon'
                    width='25px'
                    className=''
                    onClick={openCategoryModal}
                  />
                )}
              </div>
              {/* end::Label */}

              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Envanter kategorisi'
                {...formik.getFieldProps('inventory_category_id')}
                name='inventory_category_id'
                defaultValue={update ? inventory.inventory_category_id : ''}
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Envanter Kategorisi Seç
                </option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.inventory_category_id && formik.errors.inventory_category_id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.inventory_category_id}</span>
            </div>
          </div>
        )}
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.start_date}</span>
          </div>
        </div>
      )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
              />
              {formik.touched.end_date && formik.errors.end_date && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.end_date}</span>
          </div>
        </div>
      )}
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Sözleşme tarihi</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('contract_date')}
                type='date'
                name='contract_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.contract_date && formik.errors.contract_date},
                  {
                    'is-valid': formik.touched.contract_date && !formik.errors.contract_date,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || update}
              />
              {formik.touched.contract_date && formik.errors.contract_date && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.contract_date}</span>
          </div>
        </div>
      )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Adedi</label>
              {/* end::Label */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Envanter kategorisi'
                {...formik.getFieldProps('quantity')}
                name='quantity'
                defaultValue={update ? inventory.quantity : ''}
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Adet
                </option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
              </select>

              {formik.touched.quantity && formik.errors.quantity && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.quantity}</span>
          </div>
        </div>
      )}
            </div>
          </div>
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Tutar</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder='Giriniz'
                {...formik.getFieldProps('amount')}
                type='text'
                name='amount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.name && formik.errors.name},
                  // {
                  //   'is-valid': formik.touched.name && !formik.errors.name,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || update}
              />
              {formik.touched.amount && formik.errors.amount && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.amount}</span>
          </div>
        </div>
      )}
            </div>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Para Birimi</label>
              {/* end::Label */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Para Birimi'
                {...formik.getFieldProps('currency')}
                name='currency'
                defaultValue={update ? inventory.inventory_category_id : ''}
              >
                {/* {activity.assigned_by!==''} */}
                <option value='0' hidden>
                  Para Birimi Seçiniz
                </option>
                <option value='TRY'>TRY</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </select>

              {formik.touched.currency && formik.errors.currency && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.currency}</span>
          </div>
        </div>
      )}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Tedarikçi Bilgileri</label>
            {/* end::Label */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Tedarikçi Bilgileri'
              {...formik.getFieldProps('supplier_id')}
              name='supplier_id'
              defaultValue={update ? inventory.supplier_id : ''}
            >
              {/* {activity.assigned_by!==''} */}
              <option value='0' hidden>
                Tedarikçi Seçiniz
              </option>
              {suppliers.map((supplier) => {
                return (
                  <option value={supplier.id} key={supplier.id}>
                    {supplier.name}-{supplier.company_name}
                  </option>
                )
              })}
            </select>
            {formik.touched.supplier_id && formik.errors.supplier_id && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.supplier_id}</span>
          </div>
        </div>
      )}
          </div>

          <div style={{display: update ? 'none' : 'block'}} className='fv-row mb-8'>
            <label className='fs-7 fw-bold mb-2 required'>Fotoğraf Yükle</label>

            <div className='dropzone' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                      fill='currentColor'
                    />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                {/* <input type='file' onChange={handleChange} />
    <input type='file' onChange={handleChange2} multiple /> */}

                <Dropzone
                  accept={{'image/*': ['.png', '.jpg', '.jpeg']}}
                  multiple={false}
                  maxSize={2097152}
                  maxFiles={1}
                  onDrop={async (acceptedFile, fileRejections) => {
                    setFile(acceptedFile[0])
                    setShowFile(acceptedFile)
                    //setFiles(acceptedFile)
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === 'file-too-large') {
                          setErrors(`Hata: ${err.message}`)
                        }

                        if (err.code === 'file-invalid-type') {
                          setErrors(`Hata: ${err.message}`)
                        } else {
                          setErrors(`Hata: ${err.message}`)
                        }
                      })
                    })
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                            Ürün fotoğrafını buraya bırakın veya yüklemek için tıklayın
                          </h3>
                          <span className='fw-bold fs-7 text-gray-400'>1 adet foto yükleyin</span>
                        </div>
                        <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>{errors}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
            <ul className='m-0 p-0'>{f}</ul>
          </aside>
          <div style={{display: update ? 'none' : 'block'}} className='fv-row mb-8'>
            <label className='fs-7 fw-bold mb-2'>Dosya Yükle</label>

            <div className='dropzone' id='kt_modal_create_ticket_attachments'>
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                      fill='currentColor'
                    />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                {/* <input type='file' onChange={handleChange} />
    <input type='file' onChange={handleChange2} multiple /> */}

                <Dropzone
                  maxSize={10485760}
                  maxFiles={10}
                  onDrop={async (acceptedFiles, fileRejections) => {
                    setFiles(acceptedFiles)
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === 'file-too-large') {
                          setErrors2(`Hata: ${err.message}`)
                        }

                        if (err.code === 'file-invalid-type') {
                          setErrors2(`Hata: ${err.message}`)
                        } else {
                          setErrors2(`Hata: ${err.message}`)
                        }
                      })
                    })
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                            Dosyaları buraya bırakın veya yüklemek için tıklayın
                          </h3>
                          <span className='fw-bold fs-7 text-gray-400'>
                            5 dosyaya kadar yükleyin
                          </span>
                        </div>
                        <p style={{color: 'red', padding: 5, margin: 0, fontSize: 14}}>{errors2}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <aside>
            <ul className='m-0 p-0'>{filess}</ul>
          </aside>
          <div className='d-flex justify-content-end'>
            {' '}
            {files.length > 0 && (
              <span onClick={removeAll} className='btn btn-sm btn-primary me-2 '>
                Hepsini Sil
              </span>
            )}
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched ||
                (showFile.length < 1 && !update) 
                // (files.length < 1 && !update)
              }
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      <Modal show={showCategoryModal} onHide={closeCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='inventory'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
