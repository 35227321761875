import {FC, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { updateProject } from '../core/requests'


type Props = {
  issue: any
  handleClose: any
}

export const EditStatus: FC<Props> = ({issue, handleClose}) => {
  const navigate= useNavigate()
  const [notText, setNottext] = useState('Aktif')
  const [selectedStatus, setSelectedstatus] = useState(issue.id)
  console.log(issue.id)
  console.log(issue)

  const handleChange = () => {
    setNottext(notText === 'Aktif' ? 'Pasif' : 'Aktif')
  }


  const handleSubmit = async () => {
    const statuseUpd={
      status: selectedStatus
    }
    await updateProject(issue.prd_id, statuseUpd)
    handleClose(false)
    window.location.reload();
    // navigate('/faaliyetler/gorevler')
  
  }
  return (
   

    <div
      className='d-flex flex-column scroll-y me-n7 pe-7 menu menu-sub'
      id='kt_modal_add_user_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_user_header'
      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
      data-kt-scroll-offset='100px'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>İşlem</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Durum:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-dropdown-parent='#kt_menu_6279569cd15cc'
              data-allow-clear='true'
              onChange={(e) => setSelectedstatus(e.target.value)}
              value={selectedStatus}
            >
              {console.log(selectedStatus)}
              <option hidden> Durum Seç </option>
              <option value='0'>Aday Projeler</option>
              <option value='1'>Devam Edenler</option>
              <option value='2'>Tamamlananlar</option>
              <option value='3'>Askıya Alınanlar</option>
              <option value='4'>İptal Edilenler</option>
            </select>
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Bildirim:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked
              onChange={handleChange}
            />
            <label className='form-check-label'>{notText}</label>
          </div>
        </div> */}

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleClose}
          >
            İptal
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={handleSubmit}
          >
            Gönder
          </button>
        </div>
      </div>
    </div>

    //</form>
  )
}
