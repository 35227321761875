import { FC, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { KTSVG, toAbsoluteUrl } from "../../_theme/helpers"
import { Dropdown1 } from "../../_theme/partials"

import { ProfileHeader } from "../modules/profile/ProfileHeader"
import ProfilePage from "../modules/profile/ProfilePage"
import { getUserById } from "./Profile/core/_request"
import { Documents } from "./Profile/Documents"
import { Overview } from "./Profile/Overview"
import { ProfileInfo } from "./Profile/ProfileInfo"
import { Slider } from "./Profile/Slider"

type LocationState={
    id: any
}
export const Profile : FC =()=>{
    const location = useLocation()
    let data = location.state as LocationState
    const [active, setActive] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [user, setUser] = useState<any>({
      id:'',
      name: '',
      email: '',
      avatar: '',
      assignment :{
        id: 0,
        name: ''
      },
      certificates:'',
      experience:0,
      competencies: '',
      associate_degree:'',
      bachelors_degree:'',
      masters_degree:'',
      roles:[{
        id:0,
        name:''
      }]
      
    })
    const refreshPage=()=>{
      refresh ? setRefresh(false) : setRefresh(true)
    }

    useEffect(()=>{
      getUserById(data.id).then(({data})=>{
        console.log(data.data)
       setUser(data.data)
      })
    },[refresh])

    return(
        // <><ProfileHeader /><ProfilePage /></>
       <>
       <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            <div id='kt_app_content_container' className='app-container container-fluid p-0'>
              <div className='d-flex flex-column flex-lg-row '>
       <ProfileInfo user={user} refreshPage={refreshPage}/>
       <div className='w-100 pt-0 p-10 pe-0'>
           <Slider active={active} setActive={setActive}  refresh={false} setRefresh={undefined}/>
         
           {/* <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='kt_user_view_overview_tab'
                      role='tabpanel'
                    >
                      <div className='card card-flush mb-6 mb-xl-9'>
                        <div className='card-header mt-6'>
                          <div className='card-title flex-column w-100'> */}
        {
            active ==1 ? <Overview id={data.id}/>:
            <Documents id={data.id}/>
            
        }
          </div></div></div></div></div></div>
       {/* </div></div></div></div></div> */}
      
       </>
    )
}