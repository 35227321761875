import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_theme/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {getActivityCategories, UpdateIssue} from '../../../../Activities/core/_requests'
import {
  getAssignments,
  getProjectCat,
  getProjectClass,
  getProjectType,
  updateProject,
} from '../../../core/requests'
import {getUsers} from '../../../../core/requests'
import swal from 'sweetalert'
import {SwalOptions} from 'sweetalert/typings/modules/options'
import { useNavigate } from 'react-router-dom'
import state from 'sweetalert/typings/modules/state'

type Props = {
  project: any
}
const loginSchema = Yup.object().shape({
  name: Yup.string().required('Zorunlu alan'),
  code: Yup.string().required('Zorunlu alan'),
  description: Yup.string().required('Zorunlu alan'),
  group_directorate_id: Yup.string().required('Zorunlu alan'),
  directorate_id: Yup.string().required('Zorunlu alan'),
  first_owner: Yup.string().required('Zorunlu alan'),
  second_owner: Yup.string().required('Zorunlu alan'),
  start_date: Yup.string().required('Zorunlu alan'),
  end_date: Yup.string().required('Zorunlu alan'),
  email_notification: Yup.string().required('Zorunlu alan'),
  phone_notification: Yup.string().required('Zorunlu alan'),
  project_category_id: Yup.string().required('Zorunlu alan'),
  project_type_id: Yup.string().required('Zorunlu alan'),
  project_class_id: Yup.string().required('Zorunlu alan'),
})

const ProfileDetails: React.FC<Props> = ({project}) => {

  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])

  const [userForEdit] = useState<any>({
    ...project,
    id: project.id,
    code: project.code,
    name: project.name,
    description: project.description,
    group_directorate_id: project.group_directorate?.id,
    directorate_id: project.directorate?.id,
    first_owner: project.first_owner?.id,
    second_owner: project.second_owner?.id,
    start_date: project.start_date,
    end_date: project.end_date,
    project_status_id: project.status?.id,
    email_notification: project.email_notification,
    phone_notification: project.phone_notification,
    project_category_id: project.category?.id,
    project_type_id: project.type?.id,
    project_class_id: project.class?.id,
    status:project.status?.id,
    risk:project?.risk,
    escalation:project?.escalation
  })

  const [loading, setLoading] = useState(false)

  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [assignments, setAssignments] = useState<any>([])
  const [types, setTypes] = useState<any>([])
  const [cats, setCats] = useState<any>([])
  const [classes, setClasses] = useState<any>([])

  useEffect(() => {
    getActivityCategories().then(async ({data}) => {
      await setCategories(data.data)
    })
    getAssignments().then((res) => {
      setAssignments(res.data.data)
    })
    getProjectType().then((res) => {
      setTypes(res.data.data)
    })
    getProjectCat().then((res) => {
      setCats(res.data.data)
    })
    getProjectClass().then((res) => {
      setClasses(res.data.data)
    })
    getUsers().then(({data}) => {
      setUsers(data.data)
    })
  }, [])
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await updateProject(values.id, values)
          .then(() => {
      
            swal('Düzenlendi', 'Proje başarı ile düzenlendi.', 'success', {timer: 2000})
            
            navigate('../../../../projeler/detail-project/homepage',{state:values.id});
            window.location.reload()
          })
          .catch((err: {response: {data: {message: string | Partial<SwalOptions>}}}) => {
            swal('Hata', err.response.data.message, 'error', {timer: 2000})
          })
      } catch (ex) {
        console.error(ex)
      } finally {
        // setSubmitting(true)
        // cancel()
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Proje Detayı</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show p-10'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input Başlık */}
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}

                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Proje Kodu</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Proje Kodu'
                  {...formik.getFieldProps('code')}
                  type='text'
                  name='code'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.code && formik.errors.code},
                    {
                      'is-valid': formik.touched.code && !formik.errors.code,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.code && formik.errors.code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.code}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Proje Adı</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Proje Adı'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>

              {/* end::Input Kategori */}
            </div>

            {/* end::Input Başlık */}
            {/* begin::Input Sorumlu */}
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Grup Müdürlüğü*</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Sorumlu Seç'
                  {...formik.getFieldProps('group_directorate_id')}
                  name='group_directorate_id'
                  //defaultValue={activity.assigned_by}
                >
                  {/* {activity.assigned_by!==''} */}
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_group_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
                {formik.touched.group_directorate_id && formik.errors.group_directorate_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.group_directorate_id}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                <label className='required fw-bold fs-7 mb-2'>Müdürlük</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('directorate_id')}
                  name='directorate_id'
                  //defaultValue={activity.issue_category_id}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>
                  {assignments.map((as: any) => {
                    if (as.is_directorate) {
                      return (
                        <option key={as.id} value={as.id}>
                          {as.name}
                        </option>
                      )
                    }
                  })}
                </select>
                {formik.touched.directorate_id && formik.errors.directorate_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.directorate_id}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* end::Input Kategori */}
            </div>
            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Proje Sorumlusu (Asil)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('first_owner')}
                name='first_owner'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Seçiniz
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.first_owner && formik.errors.first_owner && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.first_owner}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-7'>
              {/* begin::Label */}
              <label className=' fw-bold fs-7 mb-2'>Proje Sorumlusu (Yedek)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('second_owner')}
                name='second_owner'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Seçiniz
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.second_owner && formik.errors.second_owner && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.second_owner}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Proje Kategorisi</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori seç'
                  {...formik.getFieldProps('project_category_id')}
                  name='project_category_id'
                  //  value={project.category?.id}
                >
                  {/* {activity.assigned_by!==''} */}
                  <option selected disabled hidden>
                    Proje Kategorisi Seçiniz
                  </option>
                  {cats.map((cat: any) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.project_category_id && formik.errors.project_category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.project_category_id}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              <div className='col-xs-12 col-md-6'>
                <label className='required fw-bold fs-7 mb-2'>Proje Türü</label>
                {/* end::Label */}
                {/* begin::Input */}
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Kategori Seç'
                  {...formik.getFieldProps('project_type_id')}
                  name='project_type_id'
                  //defaultValue={activity.issue_category_id}
                >
                  <option selected disabled hidden>
                    Proje Türü Seç
                  </option>
                  {types.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              {formik.touched.project_type_id && formik.errors.project_type_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_type_id}</span>
                  </div>
                </div>
              )}

              {/* end::Input Kategori */}
            </div>

            <div className='row mb-7 d-flex'>
              {/* Begin::Input Başlangıç Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Proje Hedef Başlangıç Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('start_date')}
                  type='date'
                  name='start_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                    {
                      'is-valid': formik.touched.start_date && !formik.errors.nastart_dateme,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.start_date && formik.errors.start_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.start_date}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Başlangıç tarihi */}
              {/* Begin::Input Bitiş Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Proje Hedef Bitiş Tarihi</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('end_date')}
                  type='date'
                  name='end_date'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                    {
                      'is-valid': formik.touched.end_date && !formik.errors.end_date,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.end_date && formik.errors.end_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.end_date}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Bitiş tarihi */}
            </div>

            <div className='row mb-7 d-flex'>
              {/* Begin::Input Başlangıç Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Risk</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('risk')}
                  type='text'
                  name='risk'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                    {
                      'is-valid': formik.touched.start_date && !formik.errors.nastart_dateme,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.risk && formik.errors.risk && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.risk}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Başlangıç tarihi */}
              {/* Begin::Input Bitiş Tarihi */}
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-7 mb-2'>Eskalasyon</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder=''
                  {...formik.getFieldProps('escalation')}
                  type='text'
                  name='escalation'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.escalation && formik.errors.escalation},
                    {
                      'is-valid': formik.touched.escalation && !formik.errors.escalation,
                    }
                  )}
                  autoComplete='off'
                  //disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.escalation && formik.errors.escalation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.escalation}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input Bitiş tarihi */}
            </div>

            {/* begin::Input Açıklama */}
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-5'>Proje Amacı</label>
              {/* end::Label */}
              <textarea
                placeholder='Type Target Details'
                {...formik.getFieldProps('description')}
                name='description'
                rows={8}
                cols={70}
                className='form-control form-control-solid mb-3 mb-lg-0'
              ></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Açıklama */}

            <div className='mb-7'>
              {/* <div className='col-xs-12 col-md-6'> */}
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Proje Sınıfı</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('project_class_id')}
                name='project_class_id'
                // value={project.project_class_id}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Proje Sınıfı Seçiniz
                </option>
                {classes.map((cl: any) => {
                  return (
                    <option key={cl.id} value={cl.id}>
                      {cl.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.project_class_id && formik.errors.project_class_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.project_class_id}</span>
                  </div>
                </div>
              )}
              {/* </div> */}
              {/* end::Input Sorumlu */}

              {/* begin::Input kategori */}
              {/* <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
              {/* <label className='required fw-bold fs-6 mb-2'>Proje Adı</label> */}
              {/* end::Label */}

              {/* begin::Input */}
              {/* <input
                  placeholder='Başlık'
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
                {/* end::Input */}
              {/* </div>  */}

              {/* end::Input Kategori */}
            </div>

            {/* <div className='mb-7'>
              {/* begin::Label */}
            {/* <label className=' fw-bold fs-7 mb-2'>Proje Bütçe Kategorisi*</label>
              end::Label */}
            {/* begin::Input */}
            {/* <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('assigned_by')}
                name='assigned_by'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
            {/* <option selected disabled hidden>
                  Seçiniz
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select> */}
            {/* {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div> */}
            {/* begin:: Bildirimler */}
            <div className='mb-7 fv-row'>
              <div className='d-flex flex-stack'>
                <div className='fw-bold me-5'>
                  <label className='fs-6'>Bildirim</label>
                  <div className='fs-7 text-gray-400'>
                    Eklendiğinde bildirim gönderilecektir
                  </div>{' '}
                </div>

                <div className='d-flex align-items-center'>
                  <label className='form-check form-check-custom form-check-solid me-10'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      {...formik.getFieldProps('email_notification')}
                      name='email_notification'
                      value='email_notification'
                      defaultChecked={project.email_notification}
                    />
                    <span className='form-check-label fw-bold'>Email</span>
                  </label>

                  <label className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      {...formik.getFieldProps('phone_notification')}
                      name='phone_notification'
                      value='phone_notification'
                      defaultChecked={project.phone_notification}
                    />

                    <span className='form-check-label fw-bold'>Phone</span>
                  </label>
                </div>
              </div>
              {/* {formik.touched.phone_notification && formik.errors.phone_notification && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
            {/* end:: Bildirimler */}

            {/* begin::Actions */}
            <div className='d-flex justify-content-end pt-15 text-right'>


              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting}
              >
                <span className='indicator-label'>Kaydet</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
