import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    isam: any
}
export const DSRNItem : FC<Props>=({isam})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {isam?.task_number}
        </td>
        <td className='text-gray-500'>{isam?.request_id}</td>
        <td className='text-gray-500'>{isam?.tt_sd_id}</td>
        <td className='text-gray-500'>  {isam?.tt_tech}</td>
        <td className='text-gray-500'>{isam?.assigned_group}</td>
        <td className='fw-bolder'>
            {isam?.submit_date.slice(0,10)}
        </td>
        <td className='text-gray-500'>{isam?.close_date}</td>
        <td className='text-gray-500'>{isam?.status}</td>
        <td className='text-gray-500'>  {isam?.tt_catalog_name}</td>
        <td className='text-gray-500'>{isam?.leave_period}</td>
        <td className='text-gray-500'>{isam?.action}</td>
        <td className='text-gray-500'>  {isam?.hour}</td>
        <td className='text-gray-500'>{isam?.closure_code}</td>

        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}