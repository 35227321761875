import { FC } from "react"
import { Button } from "react-bootstrap"

type Props={
    text:string,
    clickFunc:any
}

export const ButtonActive: FC<Props> = ({text,clickFunc})=>{
return(
    <div className='d-flex flex-row-reverse'>
    <Button className='w-15 ' variant='primary' onClick={clickFunc}>
      {text}
    </Button>
  </div>
)
}