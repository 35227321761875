import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    inventory: any
}
export const InventoryItem : FC<Props>=({inventory})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
          <NavLink to='envanter-detail' state={inventory} className='text-gray-900 text-hover-primary'>
            {inventory.product_name}
          </NavLink>
        </td>
        <td className='text-gray-500'>{inventory.product_code}</td>
        <td className='text-gray-500'>{inventory.quantity}</td>
        <td className='text-gray-500'> <NumberFormat value= {inventory.amount} displayType={'text'} thousandSeparator={true} /> {inventory.currency}</td>
        <td className='text-gray-500'>{inventory.category.name}</td>
        <td className='text-gray-500'>{inventory.supplier.company_name}</td>
     
       
      </tr>
       </>
    )
}