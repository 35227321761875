import Chart from 'react-apexcharts'
import React, {FC, useEffect, useState} from 'react'
import {GetALStatistics, GetDLPStatistics} from '../../../OperationalData/core/request'
import {bottom} from '@popperjs/core'

type Props = {
  name: string
}

export const PieAlarmChart: FC<Props> = ({name}) => {
  let [total, setTotal] = useState<number>(0)
  const count1: any[] | undefined = []
  const [planC, setPC] = useState<any>()
  const [statistic, setStatistic] = useState<any>([])
  const chart3 = {
    labels: ['Devam Ediyor', 'Tamamlamdı', 'Askıya Alındı'],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: 'left',
      fontSize: '13rem',
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    chart: {
      events: {
        dataPointSelection: (
          event: any,
          chartContext: any,
          config: {w: {config: {labels: {[x: string]: any}}}; dataPointIndex: string | number}
        ) => {},
      },
      size: {
        height: 300,
        width: 200
    }
    },
    tooltip: {
      style: {
        fontSize: '20px',
      },
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + ' Adet'
        },
      },
    },
  }
  useEffect(() => {
    GetALStatistics('3').then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })
  }, [name])
  const prdType = (data: any) => {
    setTotal(Number(data?.total_open) + Number(data?.total_completed) + Number(data?.total_pending))
    count1.push(data?.total_open)
    count1.push(data?.total_completed)
    count1.push(data?.total_pending)

    setPC(count1)
  }
  return (
    <div className='card h-100 ' style={{maxHeight:'350px'}}>
      <div className='card-body p-3'>
        <div className='fs-4 fw-bolder'> Aksiyon Durumları ve Sayıları </div>
        <div className='fs-6 fw-bold text-gray-400 mb-7'>Toplam {total} Adet</div>
        <div>
          <Chart options={chart3} series={planC} type='pie' height={300}/>
        </div>
      </div>
    </div>
  )
}
