import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {ID, toAbsoluteUrl} from '../../../../_theme/helpers'
import {BlogModel} from '../model/blogModel'

type Props = {
  blogType: string
  blogName: string
  el: any
  path: any
}

export const BlogItem: FC<Props> = ({blogType, blogName, el, path}) => {
  const link = path.length > 0 ? path[0].path : toAbsoluteUrl('/media/stock/600x400/img-80.jpg')

  return (
    <div className='col-md-4'>
      <div className='card-xl-stretch me-md-6'>
        <Link
          className='d-block overlay'
          data-fslightbox='lightbox-hot-sales'
          to='detail'
          state={el}
        >
          <div
            className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px'
            style={{backgroundImage: `url(${link}`}}
          ></div>

          <div className='overlay-layer card-rounded bg-dark bg-opacity-25'>
            <i className='bi bi-eye-fill fs-2x text-white'></i>
          </div>
        </Link>

        <div className='mt-5'>
          <Link
            to='detail'
            state={el}
            className='fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base'
          >
            {el.title}
          </Link>

          <div
            style={{
              maxHeight: '95px',
              height: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className='fw-bold fs-5 text-gray-600 my-3'
          >
            {el.content}
          </div>

          {blogType === 'latest' ? (
            <div className='d-flex justify-content-between align-items-center'>
              <div className='fs-6 fw-bolder'>
                <span className='text-gray-500'>{el.start_date?.substring(8,10)}.{el.start_date?.substring(5,7)}. {el.start_date?.substring(0,4)}</span>
              </div>
              {blogName !== '' ? (
                <span className='badge badge-light-primary fw-bolder my-2'>{blogName}</span>
              ):''
              // ) : blogName == 'blog' ? (
              //   <span className='badge badge-light-info fw-bolder my-2'>BLOG</span>
              // ) : (
              //   <span className='badge badge-light-warning fw-bolder my-2'>NEWS</span>
              // )
            }
            </div>
          ) : blogType === 'video' ? (
            <div className='fs-6 fw-bolder'>
              <a
                href='../dist/pages/user-profile/overview.html'
                className='text-gray-700 text-hover-primary'
              >
                Cris Morgan
              </a>

              <span className='text-gray-500'>on {el.start_date}</span>
            </div>
          ) : (
            <div className='d-flex flex-stack flex-wrap pt-4 gap-2'>
              <span className='rounded border border-dashed fs-2 fw-bolder text-dark py-1 px-2'>
                <span className='fs-6 fw-bold text-gray-400'>$</span>28
              </span>

              <a href='#' className='btn btn-sm btn-primary'>
                Purchase
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
