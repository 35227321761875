import {FC, useEffect, useState} from 'react'
import {ButtonActive} from './ButtonActive'
import {SquareButton} from './SquareButton'
import swal from 'sweetalert'
import {DeleteIssue} from '../core/_requests'
import {ID} from '../../../../_theme/helpers'
import {ModalHeader} from '../../components/ModalHeader'
import {Dropdown, Modal} from 'react-bootstrap'
import '../../style/style-swal.css'
import {AddActivity} from '../activity-edit-modal/addActivity'
import { Link } from 'react-router-dom'
import { ProfilePopUp } from '../../Profile/ProfilePopUp'

type Props = {
  id: ID
  title: string
  categoryName: string
  start_date:string
  end_date: string
  userName: string
  status: string
  issue: any
  assigned_id: any
  setRefresh: any
}
export const TargetItem2: FC<Props> = ({
  id,
  title,
  categoryName,
  start_date,
  end_date,
  issue,
  assigned_id,
  setRefresh,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const handleShow = () => setModalShow(true)
  const handleClose = () => setModalShow(false)
  const [showProfilModal,setShowProfilModal]= useState(false)
  const [profilUserId, setProfilUserId] =  useState(0)
  const closeProfilModal=()=>{
    setShowProfilModal(false)
  }
  const openProfilModal=async ()=>{
    await setShowProfilModal(true)
  }
  useEffect(() => {}, [setRefresh, modalShow])

  
  function swalDelete() {
    swal({
      title: '',
      text: 'Kayıt silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        swal('Silindi!', 'Görev başarı ile silindi.', 'success')
        await DeleteIssue(id)
     
        //setRefresh(id)
        window.location.reload();
      }
    })
  }
  return (
    <>
      <tr>
        <td className='fw-bolder '>
        
          <Link
              to='/faaliyetler/detail'
              state={issue}
              className='text-black'
             
            >
            <span className='text-hover-primary'>{title}</span>
            </Link>
        
        </td>
        <td>
          <span className='badge badge-light fw-bold me-auto'>{categoryName}</span>
        </td>
        <td>{start_date.substring(8, 10)}.{start_date.substring(5, 7)}.{start_date.substring(0, 4)}</td>
        <td>{end_date.substring(8, 10)}.{end_date.substring(5, 7)}.{end_date.substring(0, 4)}</td>
        <td>
          <div className='symbol-group symbol-hover fs-8'>
            <div
              className=' text-hover-primary'
              // data-bs-toggle='tooltip'
              // title='Susan Redwood'
              onClick={async ()=>{setProfilUserId(issue.assigned.id);
                await openProfilModal()}}
            >
              {/* <span className='symbol-label bg-primary text-inverse-primary fw-bolder'>
                {issue.assigned!==null ? issue.assigned.name : ''}
              </span> */}
              <span className=''>{issue.assigned !== null ? issue.assigned.name : ''}</span>
            </div>
          </div>
        </td>
        <td>
          
         <SquareButton status={false} issue={issue} />  
         {/* <EditStatus issue={issue} handleClose={undefined} />  */}
        </td>
        {/* <td> */}
          {/* <a
            href='#'
            className='btn btn-bg-light btn-active-color-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            
          >
            İşlem
          </a> */}
          {/* <SquareButton status={false} issue={issue} /> */}
          {/* <EditStatus issue={issue} />  */}
        {/* </td> */}
        <td className='text-end' data-kt-filemanager-table=''>
          <Dropdown>
            <Dropdown.Toggle
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              id='dropdown-basic'
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={handleShow}
              >
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={swalDelete}>
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
       
      </tr>
      <Modal show={modalShow} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
          <AddActivity
            setModalShow={handleClose}
            activity={{
              id: id,
              title: title,
              content: issue.content,
              start_date: start_date,
              end_date: end_date,
              phone_notification: issue.phone_notification,
              email_notification: issue.email_notification,
              comments: issue.comments,
              issue_category_id: issue.category.id,
              assigned_by: assigned_id,
            }}
            update={true}
          />
          {setRefresh(id)}
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
         <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
