import React, {Key} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

type Props = {
  element: any
  path: string
}

const AsideMenuItems: React.FC<Props> = ({children, element, path}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>

      {element.is_last ? (
    
        user.visible_pages === 'all' || user.visible_pages.includes(element.id) ? (
          <AsideMenuItem
            key={element.id}
            icon={element?.icon? element?.icon :'/media/icons/duotune/arrows/arr001.svg'} 
            to={`/` + path}
            title={element.name}
            fontIcon='bi-layers'
          />
        ) : (
          ''
        )
      ) : user.visible_pages === 'all' || user.visible_pages.includes(element.id) ? (
        <AsideMenuItemWithSub
          key={element.id}
          to={`/` + element.path}
          title={element.name}
          fontIcon='bi-chat-left'
          icon={element?.icon? element?.icon :'/media/icons/duotune/arrows/arr001.svg'} 
        >
          {element.children.map(
            (e: {
              id: Key | null | undefined
              name: string
              children: any
              path: string
              is_last: boolean
            }) => {
              if (e.is_last) {
                let check = false
                user.visible_pages === 'all' || user.visible_pages.includes(e.id)
                  ? (check = true)
                  : (check = false)
                if (check)
                  return (
                    <AsideMenuItem
                      key={e.id}
                      to={`/` + path + `/` + e.path}
                      title={e.name}
                      hasBullet={true}
                    />
                  )
              } else {
                let check = false
                user.visible_pages === 'all' || user.visible_pages.includes(e.id)
                  ? (check = true)
                  : (check = false)
                if (check)
                  return <AsideMenuItems key={e.id} element={e} path={e.path}></AsideMenuItems>
              }
            }
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}
    </>
  )
}

export {AsideMenuItems}
