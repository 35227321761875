import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSSVariableValue } from '../../../../../_theme/assets/ts/_utils'





type Props = {
  className: string
  chartHeight: number
  categoriesData:any
  data:any
  data1:any
  verticalTitle: string
  horizentalTitle: string
  pointTitle: string
}

export const ChartsWidget4: FC<Props> = ({className, chartHeight,categoriesData,data,data1, verticalTitle,horizentalTitle,pointTitle}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight,categoriesData,data,data1,verticalTitle,pointTitle))
  
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  
  }, [categoriesData,data])

  return (
    <div className={`card card-flush overflow-hidden ${className}`}>
      {/* begin::Header */}
      <div className='card-header text-center'>
        {/* begin::Title */}
        <h3 className='card-title align-items-center flex-column text-center w-100 m-1 p-1'>
          <span className='card-label fw-bolder text-dark'>{horizentalTitle}</span>
         
        </h3>
        {/* end::Title */}
        {/* begin::Toolbar */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Card body */}
      <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
        {/* begin::Statistics */}
        {/* end::Statistics */}
        {/* begin::Chart */}
        <div ref={chartRef} className='min-h-auto ps-4 pe-6' style={{height: 'auto'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Card body */}
    </div>
  )
}

const chartOptions = (chartHeight: number,categoriesData:any,data:any,data1:any,verticalTitle:string,pointTitle: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success')


  return {
    series: [
      {
        name: pointTitle,
        data: data,
      },
      {
        name: pointTitle,
        data: data1,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      showForSingleSeries: true,
      
      customLegendItems: ['Toplam Tesis','Toplam iptal'],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.7,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
      //colors: ['#00E396','#775DD0'],
 
    },

    xaxis: {
      categories: categoriesData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,
      labels: {
        rotate: 0,
        rotateAlways: true,
        style: {
          //colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
         // color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title:{text: verticalTitle},
      tickAmount: 5,
      max: Math.max.apply(Math, [Math.max.apply(Math, data),Math.max.apply(Math, data1)]),
      min: 0,
      labels: {
        style: {
          //colors: labelColor,
          fontSize: '12px',
        },
        // formatter: function (val) {
        //   return '$' + Number(val / 10).toFixed(1) + 'K'
        // },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return  val.toString();
        },
      },
    },
   // colors: [lightColor],
    grid: {
      //borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
     // strokeColors: [baseColor],
      strokeWidth: 3,
      size: 5,
    },
  }
}
