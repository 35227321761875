import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_theme/helpers'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {Social} from '../../Activities/models/widgetModel'
import {
  getIssueDirectorateWidget,
  getIssueStatusByStaff,
  getProjectDirectorateWidget,
  getProjectDirectorateWidgetByClass,
  updateHomeWidgets,
} from '../../core/requests'
import swal from 'sweetalert'

type Props = {
  widgetId: any
  title?: string
  getSelectedId?: any
  setSelectedDirectorate?: any
  plusOrMinus: boolean
  refreshPage?: any
}

export const DirectorateWidget: FC<Props> = ({
  title,
  getSelectedId,
  setSelectedDirectorate,
  widgetId,
  plusOrMinus,
  refreshPage,
}) => {
  const [widgetData, setWidgetData] = useState<any>([])
  useEffect(() => {
    if (widgetId == 12) {
      getIssueDirectorateWidget().then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 13 || widgetId == 19 || widgetId == 20 || widgetId == 21 || widgetId == 22) {
      getProjectDirectorateWidget().then(({data}) => {
        setWidgetData(data)
      })
    } else if (widgetId == 18) {
      getIssueStatusByStaff().then(({data}) => {
        setWidgetData(data)
      })
    }
    else if(widgetId == 23) {
      getProjectDirectorateWidgetByClass(1).then(({data})=>{
        setWidgetData(data)
      })
    }
    else if(widgetId == 24) {
      getProjectDirectorateWidgetByClass(2).then(({data})=>{
        setWidgetData(data)
      })
    }
    else if(widgetId == 25) {
      getProjectDirectorateWidgetByClass(3).then(({data})=>{
        setWidgetData(data)
      })
    }
  }, [])
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel

  function homeWidgetShow() {
    swal({
      title: '',
      text: plusOrMinus ? 'Widget anasayfanıza eklensin mi?' : 'Widget silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
        await refreshPage()
        // await DeleteAnnoun(data.id)
        // swal('Silindi!', 'Duyuru başarı ile silindi.', 'success')
        // navigate('/duyurular/bilgi-guvenligi-politikalari')
      }
    })
  }

  return (
    <div className={`card card-flush h-100`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          {title && <span className='card-label fw-bolder text-dark fs-3'>{title}</span>}
        </h3>
        {/* end::Title */}
        {/* begin::Toolbar */}
        {/* <div className='card-toolbar'>
          {/* begin::Menu */}
        <div>
          <span
            className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-2  d-inline-flex '
            onClick={async () => {
              homeWidgetShow()
            }}
          >
            {plusOrMinus ? '+' : '-'}
          </span>
          {plusOrMinus && (
            <button
              className='btn btn-icon btn-color-gray-400 btn-active-color-primary mt-n2 me-n3'
              onClick={() => {
                getSelectedId('')
                setSelectedDirectorate('')
              }}
            >
              Tümü
            </button>
          )}
        </div>

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body  scroll-y' style={{height: '185px'}}>
        {widgetData.map((item: any, index: any) => {
          return (
            <div key={index}>
              <div className='d-flex flex-stack'>
                {/* begin::Section */}
                <div className='d-flex align-items-center me-3'>
                  {/* begin::Icon */}
                  {/* <img src={toAbsoluteUrl(item.image)} className='me-3 w-30px' alt='' /> */}
                  {/* end::Icon */}
                  {/* begin::Section */}
                  <div className='flex-grow-1' style={{cursor: 'pointer'}}>
                    {/* eslint-disable-next-line */}
                    <a
                      onClick={() => {
                        getSelectedId(item.id)
                        setSelectedDirectorate(item.name)
                      }}
                      className='text-gray-800 text-hover-primary fs-5 fw-bolder lh-0'
                    >
                      {item.name}
                    </a>
                    {/* <span className='text-gray-400 fw-bold d-block fs-6'>{item.text}</span> */}
                  </div>
                  {/* end::Section */}
                </div>

                {/* end::Section */}
                {/* begin::Statistics */}
                {widgetId == 12 || widgetId == 18 ? (
                  <>
                    <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                      <div>
                        <span className=' text-gray-600'>{item.completed_issues}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_issues}</span>
                      </div>
                      {/* begin::Progress */}
                      <div className='progress h-6px w-100 me-2 bg-light-success'>
                        <div
                          className='progress bg-success'
                          style={{
                            width: `${
                              item.total_issues == 0
                                ? '0'
                                : ((item.completed_issues * 100) / item.total_issues).toFixed(0)
                            }%`,
                          }}
                        ></div>
                      </div>
                      {/* end::Progress */}
                      {/* begin::Value */}

                      <span className='text-gray-400 fw-bold'>
                        {item.total_issues == 0
                          ? '0'
                          : ((item.completed_issues * 100) / item.total_issues).toFixed(0)}
                        %
                      </span>
                      {/* end::Value */}
                    </div>
                  </>
                ) : widgetId == 19 ?
                (
                  <div className='text-gray-600'>{item.candidate_projects}</div>
                  
                ) 
                : widgetId == 20 ?
                (
                  <div className='text-gray-600'>{item.suspended_projects}</div>
                  
                ):
                 widgetId == 21 ?
                (
                  <div className='text-gray-600'>{item.canceled_projects}</div>
                  
                ):
                widgetId == 22 ?
                (
                  <div className='text-gray-600'>{item.completed_projects}</div>
                  
                ): 
                widgetId == 23 || widgetId == 24 || widgetId == 25 ?
                (
                  <div className='text-gray-600'>{item.ongoing_projects}</div>
                  
                ):   (
                  <>
                    <div className='d-flex justify-content-between align-items-center w-100 mw-125px'>
                      <div>
                        <span className=' text-gray-600'>{item.completed_projects}</span>
                      </div>
                      <div className='text-gray-600'>{'/'}</div>
                      <div>
                        <span className='me-5 text-gray-600'>{item.total_project}</span>
                      </div>
                      {/* begin::Progress */}
                      <div className='progress h-6px w-100 me-2 bg-light-success'>
                        <div
                          className='progress-bar bg-success'
                          style={{
                            width: `${
                              item.total_project == 0
                                ? '0'
                                : ((item.completed_projects * 100) / item.total_project).toFixed(0)
                            }%`,
                          }}
                        ></div>
                      </div>
                      {/* end::Progress */}
                      {/* begin::Value */}

                      <span className='text-gray-400 fw-bold'>
                        {item.total_project == 0
                          ? '0'
                          : ((item.completed_projects * 100) / item.total_project).toFixed(0)}
                        %
                      </span>
                      {/* end::Value */}
                    </div>
                  </>
                )}

                {/* end::Statistics */}
              </div>
              <div className='separator separator-dashed my-4'></div>
            </div>
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}
