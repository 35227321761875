import React, {FC, Suspense} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_theme/assets/ts/_utils'
import {PageLink, PageTitle} from '../../../_theme/layout/core'
import {AlarmTrakıng} from './AlarmTrakıng/AlarmTrakıng'
import {CAttact} from './CustamerAttack/CA'
import {DSRN} from './DataSecurıtyRequestNumber/DSRN'
import {FileIntegrityMonitoring} from './FileIntegrityMonitoring/FileIntegrityMonitoring'
import {IADS} from './IncomeAssuranceDataSummary/IADS'
import {ISAM} from './InformationSecurityData/ISAM'
import { KGHCustomerData } from './KGHCustomerData/KGHCustomerData'
import {Mirroring} from './Mirroring/Mirroring'
import { NumberOfAlarmCases } from './NumberOfAlarmCases/NumberOfAlarmCases'
import {NOD} from './NumberOfDemond/NOD'
import {ProjectDemandTracking} from './ProjectDemandTracking/ProjectDemandTracking'
import {ServerChangeData} from './ServerChangeData/ServerChangeData'
import { TTVMCustomerData } from './TTVMCustomerData/TTVMCustomerData'
import {VN} from './VulnerabilityNotification/VN'

const OperationalDataWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dlp-metrikleri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / DLP İstatistikleri</PageTitle>
              <ISAM />
            </>
          }
        />
        <Route
          path='musteri-atak-istatistikleri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Müşteri Atak İstatistikleri</PageTitle>
              <CAttact />
            </>
          }
        />
        <Route
          path='network-zafiyet-bildirim-durumu'
          element={
            <>
              <PageTitle>Operasyonel Veriler / NW Zafiyet Bildirim Raporu </PageTitle>
              <VN />
            </>
          }
        />
        <Route
          path='farkindalik-istatistikleri'
          element={
            <>
              <PageTitle>
                Operasyonel Veriler / Veri Güvenliği Bütünlüğü ve DAM Talepleri
              </PageTitle>
              <DSRN />
            </>
          }
        />
        <Route
          path='gelir-guvence-verileri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Gelir Güvence Finansal Veriler</PageTitle>
              <IADS />
            </>
          }
        />
        <Route
          path='proje-ttt-ve-talep-takibi'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Proje ve Proje TTT Talep Takibi</PageTitle>
              <ProjectDemandTracking />
            </>
          }
        />
        <Route
          path='alarm-durumlarinin-takibi'
          element={
            <>
              <PageTitle>Operasyonel Veriler / SOC Alarm Takibi</PageTitle>
              <AlarmTrakıng />
            </>
          }
        />
        <Route
          path='aynalama-talepleri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Aynalama Talebi</PageTitle>
              <Mirroring />
            </>
          }
        />

        <Route
          path='dosya-butunluk-izleme-sunucu-degisim-verileri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Dosya Bütünlük İzleme - Sunucu Değişim Verileri </PageTitle>
              <ServerChangeData />
            </>
          }
        />
        <Route
          path='dosya-butunluk-izleme'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Dosya Bütünlük İzleme - Aylık Veriler </PageTitle>
              <FileIntegrityMonitoring />
            </>
          }
        />
        <Route
          path='talep-sayilari-ve-karsilama-talepleri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Talep Sayılar ve Karşılama Süresi </PageTitle>
              
              <NOD />
            </>
          }
        />
           <Route
          path='kgh-ve-ttvm-musteri-durumu-verileri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / KGH Müşteri Raporu </PageTitle>
              <KGHCustomerData />
            </>
          }
        />
           <Route
          path='ttvm-musteri-durumu-verileri'
          element={
            <>
              <PageTitle>Operasyonel Veriler / TTVM Müşteri Raporu </PageTitle>
              <TTVMCustomerData />
            </>
          }
        />
            <Route
          path='alarm-vaka-sayilari'
          element={
            <>
              <PageTitle>Operasyonel Veriler / Alarm Vaka Sayıları</PageTitle>
              <NumberOfAlarmCases />
            </>
          }
        />
        

        <Route index element={<Navigate to='/dlp-istatistikleri' />} />
      </Route>
    </Routes>
  )
}

export {OperationalDataWrapper}
