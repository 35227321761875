import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {getSuppliers} from '../../Supplier/core/_requests'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {
    CreateBudget,
  GetBudgetCategories,
  GetBudgetCompanies,
  GetBudgetPurchaseTypes,
  GetBudgetTypes,
  UpdateBudget,
} from '../core/_requests'
import {getAssignments, getUsers} from '../../core/requests'
import { ModalHeader } from '../../components/ModalHeader'
import { Modal } from 'react-bootstrap'
import { AddCategory } from '../../Categories/AddCategory'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'

type Props = {
  budget: any
  update: boolean
  handleClose: any
  refreshPage: any
}
const budgetSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  amount: Yup.number().required('Zorunlu alan'),
  currency: Yup.string().required('Zorunlu alan'),
  assignment_id: Yup.number().required('Zorunlu alan'),
  owner_by: Yup.string().required('Zorunlu alan'),
  budget_type_id: Yup.number().required('Zorunlu alan'),
  budget_category_id: Yup.number().required('Zorunlu alan'),
  budget_company_id: Yup.number().required('Zorunlu alan'),
  budget_purchase_type_id: Yup.number().required('Zorunlu alan'),
  year: Yup.number().required('Zorunlu alan'),
})

export const AddBudget: FC<Props> = ({budget, update, handleClose, refreshPage}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [assignments, setAssignments] = useState<any>([
    {
      id: 1,
      name: '',
      is_directorate: false
    },
  ])
  const [types, setTypes] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [companies, setCompanies] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [purchaseTypes, setPurchaseTypes] = useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers]= useState<any>([
    {
      id: 1,
      name: '',
    },
  ])
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }
  let formData = new FormData()

  useEffect(() => {
    GetBudgetCategories().then(({data}) => {
      setCategories(data.data)
    })
    GetBudgetCompanies().then(({data}) => {
      setCompanies(data.data)
    })
    GetBudgetPurchaseTypes().then(({data}) => {
      setPurchaseTypes(data.data)
    })
    GetBudgetTypes().then(({data}) => {
      setTypes(data.data)
    })

    getAssignments().then(({data}) => {
      setAssignments(data.data)
    })
    getUsers().then(({data})=>{
        setUsers(data.data)
    })
  }, [showCategoryModal])

  const [userForEdit] = useState<any>({
    ...budget,
    id: budget.id,
    owner_by: budget.owner.id,
    assignment_id: budget.assignment.id,
    budget_type_id: budget.type.id,
    budget_category_id: budget.category.id,
    budget_company_id: budget.company.id,
    budget_purchase_type_id: budget.purchase_type.id,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: budgetSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
         
        if (update) {
            let BudgetData={
                title: values.title,
                amount: values.amount,
                currency: values.currency,
                assignment_id: values.assignment_id,
                owner_by: values.owner_by,
                budget_type_id: values.budget_type_id,
                budget_category_id: values.budget_category_id,
                budget_company_id: values.budget_company_id,
                budget_purchase_type_id: values.budget_purchase_type_id,
                year: values.year
            }
      
             await UpdateBudget(budget.id, BudgetData)
                .then(() => {
                  swal('Düzenlendi', 'Bütçe başarı ile düzenlendi.', 'success', {timer: 2000})
                  handleClose()
                })
                .catch((err) => {
                  swal('Hata', err.response.data.message, 'error', {timer: 2000})
                })
        } else {
            formData.append('title', values.title)
            formData.append("amount", values.amount)
            formData.append("currency",values.currency)
            formData.append("assignment_id", values.assignment_id)
            formData.append("owner_by",values.owner_by)
            formData.append("budget_type_id",values.budget_type_id)
            formData.append("budget_category_id", values.budget_category_id)
            formData.append("budget_company_id",values.budget_company_id)
            formData.append("budget_purchase_type_id", values.budget_purchase_type_id)
            formData.append("year",values.year)
            await CreateBudget(formData) .then(() => {
                handleClose()
                swal('Eklendi', 'Bütçe başarı ile eklendi.', 'success', {timer: 2000})
                refreshPage()
              })
              .catch((err) => {
                console.log(err.message)
                swal('Hata!', err.response.data.message, 'error', {timer: 2000})
              })
        }
        
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
    <form
      id='kt_modal_add_inventory_form'
      onSubmit={formik.handleSubmit}
      className='form'
      noValidate
    >
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7 '
        id='kt_modal_add_inventory_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_inventory_header'
        data-kt-scroll-wrappers='#kt_modal_add_inventory_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Bütçe Başlığı</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Giriniz'
            {...formik.getFieldProps('title')}
            type='text'
            name='title'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.title && formik.errors.title},
              {
                'is-valid': formik.touched.title && !formik.errors.title,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.title && formik.errors.title && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.title}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Tutar</label>
            {/* end::Label */}

            <input
              placeholder='Giriniz'
              {...formik.getFieldProps('amount')}
              type='number'
              name='amount'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.amount && formik.errors.amount},
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
              autoComplete='off'
              //disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                   <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Döviz Birimi</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.currency && formik.errors.currency},
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Para Birimi'
              {...formik.getFieldProps('currency')}
              name='currency'
            >
              {/* {activity.assigned_by!==''} */}
              <option value='0' hidden>
                Döviz Birimi Seçiniz
              </option>
              <option value='TRY'>TRY</option>
              <option value='USD'>USD</option>
              <option value='EUR'>EUR</option>
            </select>

            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                   <div className='fv-help-block'>
                <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='fv-row mb-7'>
            <label className='required fw-bold fs-7 mb-2'>Görevli</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.assignment_id && formik.errors.assignment_id},
                {
                  'is-valid': formik.touched.assignment_id && !formik.errors.assignment_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Görevli'
              {...formik.getFieldProps('assignment_id')}
              name='assignment_id'
            >
              {/* {activity.assigned_by!==''} */}
              <option value='0' hidden>
                Görevli Seçiniz
              </option>
              {assignments.map((assignment: any) => {
                return (
                  assignment.is_directorate ? <option key={assignment.id} value={assignment.id}>
                  {assignment.name}
                </option> : ''
                  
                )
              })}
            </select>
            {formik.touched.assignment_id && formik.errors.assignment_id && (
              <div className='fv-plugins-message-container'>
                   <div className='fv-help-block'>
                <span role='alert'>{formik.errors.assignment_id}</span>
                </div>
              </div>
            )}
          </div>

        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
          <label className='required fw-bold fs-7 mb-2 required'>Sahip</label>
            {/* end::Label */}
            <select
              className='form-select form-select-solid'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Sahip'
              {...formik.getFieldProps('owner_by')}
              name='owner_by'
            >
              {/* {activity.assigned_by!==''} */}
              <option hidden>
                Bütçe Sahibini Seçiniz
              </option>
              {users.map((user: any) => {
                return (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.owner_by && formik.errors.owner_by && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.owner_by}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Bütçe Tipi</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                // {'is-invalid': formik.touched.budget_type_id && formik.errors.budget_type_id},
                // {
                //   'is-valid': formik.touched.budget_type_id&& !formik.errors.budget_type_id,
                // }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Bütçe Tipi'
              {...formik.getFieldProps('budget_type_id')}
              name='budget_type_id'
            >
     
              <option value='0' hidden>
                Bütçe Tipini Seçiniz
              </option>
              {types.map((type: any) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.budget_type_id && formik.errors.budget_type_id && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.budget_type_id}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        
        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
            <div className='d-flex justify-content-between'>
            <label className='required fw-bold fs-7 mb-2'>Bütçe Kategorisi</label>
            {
              loginUser.name==='Süper Admin Kullanıcısı' &&
                          <img src="https://cdn-icons-png.flaticon.com/512/992/992651.png" alt='add-icon' width='25px' className='m-1' onClick={openCategoryModal}/>
            }
            </div>
      
            {/* end::Label */}
            <select
              className='form-select form-select-solid'
                
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Sahip'
              {...formik.getFieldProps('budget_category_id')}
              name='budget_category_id'
            >
              
              <option  hidden>
                Bütçe Kategorisini Seçiniz
              </option>
              {categories.map((category: any) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.budget_category_id && formik.errors.budget_category_id && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.budget_category_id}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Satınalma Türü</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.budget_purchase_type_id && formik.errors.budget_purchase_type_id},
                {
                  'is-valid': formik.touched.budget_purchase_type_id&& !formik.errors.budget_purchase_type_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Bütçe Tipi'
              {...formik.getFieldProps('budget_purchase_type_id')}
              name='budget_purchase_type_id'
            >
     
              <option value='0' hidden>
                Satın Alma Türünü Seçiniz
              </option>
              {purchaseTypes.map((type: any) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.budget_purchase_type_id && formik.errors.budget_purchase_type_id && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.budget_purchase_type_id}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7 d-flex'>
          <div className='col-xs-12 col-md-6'>
          <label className='required fw-bold fs-7 mb-2'>Şirket adı</label>
            {/* end::Label */}
            <select
              className={clsx(
                'form-select form-select-solid',
                {'is-invalid': formik.touched.budget_company_id && formik.errors.budget_company_id},
                {
                  'is-valid': formik.touched.budget_company_id && !formik.errors.budget_company_id,
                }
              )}
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Sahip'
              {...formik.getFieldProps('budget_company_id')}
              name='budget_company_id'
            >
           
              <option value='0' hidden>
                Şirket Seçiniz
              </option>
              {companies.map((company: any) => {
                return (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                )
              })}
            </select>
            {formik.touched.budget_company_id && formik.errors.budget_company_id && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.budget_company_id}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xs-12 col-md-6'>
            {/* begin::Label */}
            <label className='required fw-bold fs-7 mb-2'>Yıl</label>
            {/* end::Label */}
            <input
            placeholder='Giriniz'
            {...formik.getFieldProps('year')}
            type='number'
            name='year'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.year && formik.errors.year},
              {
                'is-valid': formik.touched.year && !formik.errors.year,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
            {formik.touched.year && formik.errors.year && (
              <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                <span role='alert'>{formik.errors.year}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
            onClick={handleClose}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
          >
            <span className='indicator-label'>Gönder</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
    <Modal show={showCategoryModal} onHide={closeCategoryModal}>
    <Modal.Body>
      <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
      <AddCategory
        category={{
          id: undefined,
          name: '',
        }}
        update={false}
        handleClose={closeCategoryModal}
        numberMenu='budget'
      />
    </Modal.Body>
  </Modal>
  </>
  )
}
