import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

type Props = {
  active: number
}
export const HeaderModel: FC<Props> = ({active}) => {
  const [showSlaModal, setSlaModal] = useState(false)
  const openProjeModal = () => {
    setSlaModal(true)
  }

  return (
    <>
      <div className='w-200 '>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8 m-auto d-flex justify-content-center'>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_tab'
            >
              Proje Bilgileri
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 ${active == 2 ? 'active' : ''}`}
              data-kt-countup-tabs='true'
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_security'
            >
              Bütçe
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 3 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
            >
              Proje Grubu Oluştur
            </a>
          </li>
          {/* <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 4 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
            >
              İş Kırılım Listesi
            </a>
          </li> */}
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 4 ? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
            >
              Dosyaları yükle
            </a>
          </li>
          {/* <li className='nav-item'>
            <a
              className={`nav-link text-active-primary pb-4 me-5 ${active == 5? 'active' : ''}`}
              data-bs-toggle='tab'
              // href='#kt_user_view_overview_events_and_logs_tab'
            >
              Tamamlanmış
            </a>
          </li> */}
        </ul>
      </div>
    </>
  )
}
