import { useEffect, useState } from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_theme/layout/core'
import { getProject } from '../core/requests'
import {Activity} from './components/Activity'
import {Budget} from './components/Budget'
import {Document} from './components/Document'
import {HomePage} from './components/HomePage'
import {ProjectProgress} from './components/ProjectProgress'
import {SettingsPage} from './components/SettingsPage'
import {Stakeholder} from './components/Stakeholder'

import {DetailHeader} from './DetailHeader'
import { TargetDetail } from './TargetDetail'


type LocationState = {
  id: string
}
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const DetailPagesWrapper = () => (
  

    <Routes>
      <Route
        element={
          <>
            <DetailHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='homepage'
          element={
            <>
            
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true}]}>/Proje Detay (Karne)</PageTitle>
              <HomePage />
            </>
          }
        />
        <Route
          path='target-detail'
          element={
            <>
      
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true}]}>/Proje Detay (Karne)</PageTitle>
              <TargetDetail/>
            </>
          }
        />
        <Route
          path='project-progress'
          element={
            <>
            
              
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true}]}>/Proje Detay (Karne)</PageTitle>
              <ProjectProgress />
            </>
          }
        />
        <Route
          path='budget'
          element={
            <>

              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true},{title:'/ Proje Detay ',path:'../../projeler/detail-project/homepage',isActive:true}]}>/ Hedefler</PageTitle>
              <Budget />
            </>
          }
        />
        <Route
          path='stakeholders'
          element={
            <>
  
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true},{title:'/ Proje Detay ',path:'../../projeler/detail-project/homepage',isActive:true}]}>/ Paydaşlar</PageTitle>
              <Stakeholder />
            </>
          }
        />
        <Route
          path='documents'
          element={
            <>

              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true},{title:'/ Proje Detay ',path:'../../projeler/detail-project/homepage',isActive:true}]}>/ Dosyalar</PageTitle>
              <Document />
            </>
          }
        />
        <Route
          path='activity'
          element={
            <>
 
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true},{title:'/ Proje Detay ',path:'../../projeler/detail-project/homepage',isActive:true}]}>/ Hedefler</PageTitle>
              <Activity />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
             
              <PageTitle breadcrumbs={[{title:'Projeler',path:'../../projeler/tum-projeler',isActive:true},{title:'/ Proje Detay ',path:'../../projeler/detail-project/homepage',isActive:true}]}>/ Ayarlar</PageTitle>
              <SettingsPage />
            </>
          }
        />
        <Route index element={<Navigate to='/projeler/detail-project/homepage' />} />
      </Route>
    </Routes>
    
  
)

export default DetailPagesWrapper
