/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState} from 'react'
type Props = {
  setWord: any
}

const UsersListSearchComponent: FC<Props> = ({setWord}) => {
  const [searchKey, setSearchKey] = useState('')

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  // const debouncedSearchTerm = useDebounce(searchTerm, 550)
  // Effect for API call
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1 me-2'>
        {/* <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        /> */}
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px'
          placeholder='Personel Ara'
          onChange={(e) => {
            setSearchKey(e.target.value)
            e.target.value=='' && setWord('')
           }}
        />
      </div>
      <button
        type='button'
        className='btn btn-primary '
        onClick={() => {
          setWord(searchKey)
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-search'
          viewBox='0 0 16 16'
        >
          <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
        </svg>
      </button>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
