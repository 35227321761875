import {FC, useState} from 'react'
import {Supplier} from '../../Supplier/Supplier'
import swal from 'sweetalert'
import { DeleteOrder } from '../core/_requests'
import { Button, Modal } from 'react-bootstrap'
import { ModalHeader } from '../../components/ModalHeader'
import { AddOrder } from '../Budget-edit/AddOrder'
import NumberFormat from 'react-number-format'

type Props = {
  refreshPage: any
  order: any
}

const deleteOrder =(id: any, refreshPage: any)=>{
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteOrder(id).then(() => {
        swal('Silindi!', 'Bütçe başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const Order: FC<Props> = ({refreshPage, order}) => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () =>{
    setShowModal(false)
    refreshPage()
  }
  const handleShow = () =>{
    setShowModal(true)
  }
  return (
    <>
      <tr>
        <td className='text-gray-500'>{order.order_number}</td>
        <td className='text-gray-500'>
          {order.amount} {order.currency}
        </td>
        <td className='text-gray-500'> <NumberFormat value= {order.try_amount} displayType={'text'} thousandSeparator={true} />
         </td>
        <td className='text-gray-500'>{order.saving_currency}</td>
        <td className='text-gray-500'><NumberFormat value= {order.saving_amount} displayType={'text'} thousandSeparator={true} /> </td>
        <td className='min-w-200px text-end'><Button className='me-3' onClick={handleShow}>Düzenle</Button> <Button  onClick={()=>{deleteOrder(order.id, refreshPage)}}>Sil</Button></td>
        
      </tr>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
            <AddOrder handleClose={handleClose} order={order} update={true}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
