import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {
  GetCheckedPermissions,
  GetPermissionGroups,
  GetPermissions,
  SetPermissions,
} from '../core/_requests'

type Props = {
  role_id: any
}
export const SetPermission: FC<Props> = ({role_id}) => {
  const [permissionGroups, setPermissionGroups] = useState<any>([])
  const [permissions, setPermissions] = useState<any>([])
  const [initalValue, setInitialValue] = useState(false)
  const [checkedPermissions, setCheckedPermissions] = useState<any>([])
  const [groupName, setGroupName] = useState('Alarm Tracking')
  useEffect(() => {
    GetPermissionGroups().then(({data}) => {
      setPermissionGroups(data)
    })
    GetPermissions(groupName).then(({data}) => {
      setPermissions(data.data)
    })
    {
      GetCheckedPermissions(role_id, groupName).then(({data}) => {
        setCheckedPermissions(data.permissions)
      })
    }
  }, [])

  const handleChange = (group_name: string) => {
  

    setPermissions([])
    GetCheckedPermissions(role_id, group_name).then(async ({data}) => {
      setCheckedPermissions(data.permissions)
     await GetPermissions(group_name).then(({data}) => {
        setPermissions(data.data)
      })
    })
     
    
    
   
  }
  const setPer = (e: any, value: boolean) => {
    let permission = {
      permissions: [
        {
          id: e.target.name,
          value: value,
        },
      ],
    }
    SetPermissions(role_id, permission).then(async () => {
      console.log(role_id, permission)
      await 
      GetCheckedPermissions(role_id,groupName).then(({data})=>{
        setCheckedPermissions(data.permissions)
      })
    })

  }
  return (
    <div className='m-auto' style={{width: '90%'}}>
      <div className='modal-body  w-80 scroll-y mx-5 my-7' style={{width: '100%'}}>
        <form id='kt_modal_update_role_form' className='form ' action='#'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_update_role_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_update_role_header'
            data-kt-scroll-wrappers='#kt_modal_update_role_scroll'
            data-kt-scroll-offset='300px'
          >
          

            <div className='fv-row'>


              <div className='row mb-5 d-flex'>
                <div>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-7 mb-2'>Yetki Grup İsmi</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <select
                    className={clsx(
                      'form-select form-select-solid'
                      // {'is-invalid': !formik.touched.assignment_id && formik.errors.assignment_id },
                      //   {
                      //     'is-valid': formik.touched.assignment_id && !formik.errors.assignment_id
                      //   }
                    )}
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Kategori Seç'
                    name='assignment_id'
                    onChange={(e) => {
                      setGroupName(e.target.value)
                      handleChange(e.target.value)
                    }}
                  >
                    {/* <option hidden>Görev Seç</option> */}
                    {permissionGroups.map((assign: any) => {
                      return (
                        <option key={assign.id} value={assign.group_name}>
                          {assign.group_name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='d-flex scroll-x mt-5'>
                {permissions.map((permission: any) => {
                  // setInitialValue((checkedPermissions.filter ((p:any)=>p.id==permission.id)).length<1 ? false : true)
                  return (
                    <label className='form-check form-check-custom form-check-solid me-5 me-lg-20'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name={permission.id}
                        defaultChecked={checkedPermissions.some ((p:any)=>p.id==permission.id)}
                        onChange={async (e) => {
                          // console.log('first inital: ' + initalValue)
                          // console.log('default: '+ checkedPermissions.some ((p:any)=>p.id==permission.id))
                          setInitialValue(!(checkedPermissions.some ((p:any)=>p.id==permission.id)))
                          // console.log(!(checkedPermissions.some ((p:any)=>p.id==permission.id)))
                          await  setPer(e,!(checkedPermissions.some ((p:any)=>p.id==permission.id)))}}
                      />
                      {/* {console.log((checkedPermissions.filter ((p:any)=>p.id==permission.id)).length==0 ? false : true)} */}
                      {/* {console.log(permission)}
                      {console.log(checkedPermissions.some((p: any) => p.id == permission.id))} */}
                      <span className='form-check-label'>{permission.name}</span>
                    </label>
                  )
                })}
              </div>

              {/* </tbody>
                    </table> */}
            </div>
          </div>

        
        </form>
      </div>
    </div>
    //     </div>
    //   </div>
  )
}
