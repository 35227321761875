import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_theme/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {ModalHeader} from '../../../components/ModalHeader'
import { AddInventory } from '../../inventory-edit-modal/AddInventory'

type Props ={
  refreshPage: any
}
const UsersListToolbar: FC<Props> = ({refreshPage}) => {

  const [show, setShow] = useState(false)
  const openAddInvModal = () => {
    setShow(true)
  }
  const closeAddInvModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Çek
        </button> */}
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={openAddInvModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Envanter Ekle
        </button>
        {/* end::Add user */}
      </div>
      <Modal show={show} onHide={closeAddInvModal} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={closeAddInvModal} titleHeader={'Yeni Envanter Ekle'} />
          <AddInventory inventory={
            {
              id:1,
              product_name: '',
              product_code: '',
              quantity: '',
              amount: '',
              currency: '',
             category:{
               id:0,
               name:''
             },
              supplier: {
                id:0,
                name: '',
                company_name: '',
                email: ''
              },
              start_date: '',
              end_date: '',
              contract_date: '',
              product_image: '',
              attachments: []
            }
          
          } update={false}
          handleClose={closeAddInvModal}
          refreshPage={refreshPage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {UsersListToolbar}
