import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useSelector, shallowEqual} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {isNonNullExpression} from 'typescript'
import {ID} from '../../../../../_theme/helpers'
import {ProfilePopUp} from '../../../Profile/ProfilePopUp'

type Props = {
  user: any
  // {
  //     id: ID,
  //     name: string,
  //     email: string,
  //     avatar: string,
  //     roles:[{
  //         id: ID,
  //         name: string
  //     }]
  // }
  refreshPage: any
}
export const UserItem: FC<Props> = ({user, refreshPage}) => {
  // const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const [showPhoto, setShowphoto] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => setShow(false)
  const openPhotoModal = () => {
    setShowphoto(true)
  }
  const closePhotoModal = () => {
    setShowphoto(false)
    refreshPage()
  }
  const [showProfilModal, setShowProfilModal] = useState(false)
  const [profilUserId, setProfilUserId] = useState(0)
  const closeProfilModal = () => {
    setShowProfilModal(false)
  }
  const openProfilModal = async () => {
    await setShowProfilModal(true)
  }
  return (
    <>
      <tr>
        <td width={'10%'}>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-65px symbol-circle w-65px'>
              {user.avatar ? (
                <div className='symbol symbol-65px symbol-circle w-65px'>
                  <img src={user.avatar} alt={user.name} className='w-100' />
                </div>
              ) : (
                <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-2'>
                  {user.name?.substring(0, 1)}
                </div>
              )}{' '}
            </div>
          </div>
        </td>
        <td className='fw-bolder'>
          <span className='text-hover-primary'
            onClick={async () => {
              setProfilUserId(user.id)
              await openProfilModal()
            }}
          >
            {user.name}{' '}
          </span>

          <span className='text-muted d-block fw-bold'>{user.email}</span>
        </td>
        <td className='text-gray-500'>{user.roles[0].name}</td>
        {/* <td className='text-gray-500' >{user.assignment!==null && user.assignment.name}</td>
        {loginUser.name==='Süper Admin Kullanıcısı' ?( <td>
         
         <a
           href='#'
           className='btn btn-light btn-active-light-primary btn-sm'
           data-kt-menu-trigger='click'
           data-kt-menu-placement='bottom-end'
         >
           İşlem
           <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
         </a>
         {/* begin::Menu */}
        {/* <div
           className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
           data-kt-menu='true'
         >
           {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
             <a className='menu-link px-3' onClick={async () => await handleShow()}>
               Düzenle
             </a>
           </div>
           {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
             <a
               className='menu-link px-3'
               data-kt-users-table-filter='update_photo'
               onClick={async ()=> await openPhotoModal()}
             >
               Fotograf Düzenle
             </a>
           </div>

           {/* end::Menu item */}
        {/* </div>
       </td>):''} 
        */}
      </tr>
      {/* // <Modal show={show} onHide={handleClose}>
      //   <Modal.Body style={{width: '100%'}}>
      //     <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
      //     <AddUser */}
      {/* //       user={user}
      //       update={true}
      //       handleClose={handleClose}
      //       refreshPage={refreshPage}
      //     />
      //   </Modal.Body> */}
      {/* // </Modal> */}
      {/* 
      // <Modal show={showPhoto} onHide={closePhotoModal}>
      //   <Modal.Body style={{width: '100%'}}>
      //     <ModalHeader handleClose={closePhotoModal} titleHeader={'Fotoğraf Düzenle'} />
      //       <AddPhoto user_id={user.id} handleClose={closePhotoModal} doc={user.avatar}/>
      //   </Modal.Body>
      // </Modal> */}
      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
          <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
