import {FC} from 'react'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'

import {UsersListSearchComponent} from './UsersListSearchComponent'

type Props = {
  refreshPage: any
  setWord: any
}

const UsersListHeader: FC<Props> = ({refreshPage, setWord}) => {
  return (
    <div className='card-header border-0 pt-6 '>
      <UsersListSearchComponent setWord={setWord} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <UsersListToolbar refreshPage={refreshPage} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
