import {FC, useState} from 'react'
import { Button, Dropdown, Modal} from 'react-bootstrap'
import {Supplier} from '../../Supplier/Supplier'
import swal from 'sweetalert'
import { DeletePurchase } from '../core/_requests'
import { ModalHeader } from '../../components/ModalHeader'
import { AddPurchase } from '../Budget-edit/AddPurchase'
import NumberFormat from 'react-number-format'
import { ProfilePopUp } from '../../Profile/ProfilePopUp'

type Props = {
  refreshPage: any
  purchase: any
}
const deletePurchase =(id: any, refreshPage: any)=>{
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeletePurchase(id).then(() => {
        swal('Silindi!', 'Bütçe başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const Purchase: FC<Props> = ({refreshPage, purchase}) => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () =>{
    setShowModal(false)
    refreshPage()
  }
  const handleShow = () =>{
    setShowModal(true)
  }
  const [showProfilModal,setShowProfilModal]= useState(false)
  const [profilUserId, setProfilUserId] =  useState(0)
  const closeProfilModal=()=>{
    setShowProfilModal(false)
  }
  const openProfilModal=async ()=>{
    await setShowProfilModal(true)
  }
  return (
    <>
      <tr className=''>
        <td className='text-gray-500'>{purchase.budget_code}</td>
        <td className='text-gray-500'>{purchase.request_number}</td>
        <td className='text-gray-500'>
        <NumberFormat value={purchase.amount} displayType={'text'} thousandSeparator={true} /> {purchase.currency}
        </td>
        <td className='text-gray-500'>
        <NumberFormat value={purchase.try_amount} displayType={'text'} thousandSeparator={true} /></td>
        <td className='text-gray-500'>
          {purchase.supplier.name} <br />{' '}
          <span className='fs-8'>{purchase.supplier.company_name}</span>
        </td>
        {purchase.purchase_by &&
         <td className='text-gray-500 fs-6'>
         <span className='text-hover-primary' onClick={async ()=>{setProfilUserId(purchase.purchase_by.id);
               await openProfilModal()}}> {purchase.purchase_by.name}</span>
         <br />{' '}
         <span className='fs-8'>{purchase.purchase_by.roles[0].name}</span>
       </td>}
       <td className='text-gray-500'> {purchase.created_at.substring(8, 10)}.{purchase.created_at.substring(5, 7)}.
          {purchase.created_at.substring(0, 4)}</td>
       
        <td  className='text-end'>
          
        <Dropdown>
            <Dropdown.Toggle
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              id='dropdown-basic'
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
               onClick={ handleShow}
              >
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item 
              onClick={() => {
                deletePurchase(purchase.id,refreshPage)
              }}
              >
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button  onClick={handleShow} className='me-3'>Düzenle</Button>
          <Button className='mt-1' onClick={()=>{deletePurchase(purchase.id, refreshPage)}}>Sil</Button>*/}
 </td> 
      </tr>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
             <AddPurchase handleClose={handleClose} purchase={purchase} update={true}/>
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
         <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
