import { CommentModel, CommentPost } from './../models/commentModel';
import { Activity } from './../models/activityModel';
import axios, {AxiosResponse} from 'axios'
import { Response} from '../../../../_theme/helpers'

const API_URL = 'https://sgd.ecodation.com'
export const GET_ISSUE = `${API_URL}/issues`
export const GET_ISSUE0 = `${API_URL}/issues?status=0`
export const GET_ISSUE1 = `${API_URL}/issues?status=1`
export const GET_ISSUE2 = `${API_URL}/issues?status=2`
export const GET_CATEGORIES = `${API_URL}/issue_categories`
export const CREATE_ISSUE=`${API_URL}/issues`
export const DELETE_ISSUE=`${API_URL}/issues`
export const UPDATE_ISSUE = `${API_URL}/issues`
export const CREATE_COMMENT=`${API_URL}/issues`
export const GET_SOCIAL_WIDGET=`${API_URL}/widgets/issues/statusByDirectorate`
export const GET_STATICS_WIDGET=`${API_URL}/widgets/issues/status`
export const USERS=`${API_URL}/users`


const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

export async function getActivities(period:string,q:any, assignment_id: any, widgetId: any) {
  if(widgetId==12)
    return await axios.get<any>(GET_ISSUE+'?period='+period+q+'&assignment_id='+assignment_id)
    else
    return await axios.get<any>(GET_ISSUE+'?period='+period+q+'&assigned_by='+assignment_id)
}

export async function getIssues0(period: string,q: string, assignment_id: any, widgetId: any) {
  if(widgetId == 12)
  return await axios.get<any>(GET_ISSUE0+'&period='+period+q+'&assignment_id='+assignment_id)
  else
  return await axios.get<any>(GET_ISSUE0+'&period='+period+q+'&assigned_by='+assignment_id)
}
export async function getIssues1(period : string,q: string, assignment_id: any, widgetId: any) {
  if(widgetId == 12)
  return await axios.get<any>(GET_ISSUE1+'&period='+period+q+'&assignment_id='+assignment_id)
  else
  return await axios.get<any>(GET_ISSUE1+'&period='+period+q+'&assigned_by='+assignment_id)
}
export async function getIssues2(period:string,q: string, assignment_id: any, widgetId:any) {
  if(widgetId == 12)
  return await axios.get<any>(GET_ISSUE2+'&period='+period+q+'&assignment_id='+assignment_id)
  else
  return await axios.get<any>(GET_ISSUE2+'&period='+period+q+'&assigned_by='+assignment_id)
}

export async function getActivityCategories(){
    return await axios.get<any>(GET_CATEGORIES)
}

export const createIssue = (activity: any): Promise<Activity | undefined> => {
  return axios
    .post(CREATE_ISSUE, activity, config)
    .then((response: AxiosResponse<Response<Activity>>) => response.data)
    .then((response: Response<Activity>) => response.data)
}
 
export async function DeleteIssue(id:any) {
  return await axios.delete<any>(DELETE_ISSUE+'/'+id)
}

export async function UpdateIssue(id:any, issue:any) {
  return await axios.put(UPDATE_ISSUE+'/'+id,issue)
}

export async function CreateComment(comment:CommentModel,id:any){
  return axios
    .post(CREATE_COMMENT+'/'+id+'/comments', comment)
//     .then((response: AxiosResponse<Response<CommentModel>>) => response.data)
//     .then((response: Response<CommentModel>) => response.data)
// 
}

export async function getSocialWidget(){
  return await axios.get<any>(GET_SOCIAL_WIDGET)
}

export async function getStaticWidget(){
  return await axios.get<any>(GET_STATICS_WIDGET)
}

export async function getUserByAssignment(){
  return await axios.get<any>(USERS)
}