import { FC } from "react"
import NumberFormat from "react-number-format"
import { NavLink } from "react-router-dom"
type Props={
    cattact: any
}
export const CAItem : FC<Props>=({cattact})=>{
    return(
       <>
         <tr>
        <td className='fw-bolder'>
            {cattact?.attack_date.slice(0,10)}
        </td>
        <td className='text-gray-500'>{cattact?.customer_name}</td>
        <td className='text-gray-500'>{cattact?.time_range}</td>
        <td className='text-gray-500'>{cattact?.attack_size} GBps</td>
        <td className='text-gray-500'>{cattact?.description}</td>
        {/* <td className='text-gray-500'>{isam.supplier.company_name}</td> */}
     
       
      </tr>
       </>
    )
}