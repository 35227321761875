import {FC} from 'react'
import { NavLink } from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  supplier: any
}
export const UserInfo: FC<Props> = ({supplier}) => {
  const filename = (param: any) => {
    const icon = param.split('.')[1]
    return icon
  }
  return (
    <>
      <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ml-0'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body '>
            <div className='d-flex flex-center flex-column py-5'>
              {/*  <div className='symbol symbol-100px symbol-circle mb-7'>
              <img src='assets/media/avatars/300-6.jpg' alt='image' />
            </div> */}

              <span className='fs-3 text-gray-800 fw-bolder mb-3'>
              <NavLink className='text-gray-800 text-hover-primary' to='../profile' >{supplier.name}</NavLink>
              </span>

              <div className='mb-9'>
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {supplier.category.name}
                </div>
              </div>
            </div>

            <div className='separator'></div>

            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                <div className='fw-bolder mt-5'>Şirket adı</div>
                <div className='text-gray-600'>{supplier.company_name}</div>

                <div className='fw-bolder mt-5'>E-Posta</div>
                <div className='text-gray-600'>
                  <a href='#' className='text-gray-600 text-hover-primary'>
                    {supplier.email}
                  </a>
                </div>

                <div className='fw-bolder mt-5'>Telefon Numarası</div>
                <div className='text-gray-600'>{supplier.phone}</div>

                <div className='fw-bolder mt-5'>Adres</div>
                <div className='text-gray-600'>{supplier.address}</div>

                <div className='fw-bolder mt-5'>Ülke</div>
                <div className='text-gray-600'>{supplier.country.name}</div>

                <div className='fw-bolder mt-5'>Vergi Numarası </div>
                <div className='text-gray-600'>{supplier.tax_number}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
