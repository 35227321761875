import {FC, useEffect, useState} from 'react'
import {KTCard} from '../../../_theme/helpers'
import { NoMatchRecords } from '../components/NoMatchRecords'
import {UsersListHeader} from './components/header/UsersListHeader'
import {InventoryItem} from './components/InventoryItem'
import {GetInventories} from './core/_requests'

export const Inventory: FC = () => {
  const [inventory, setInventory] = useState([
      {
        id:1,
        product_name: '',
        product_code: '',
        quantity: '',
        amount: '',
        currency: '',
        category: {
            id: 1,
            name: '',
        } ,
        supplier:{
            id:1,
            name: '',
            company_name: '',
            email: ''
        },
        start_date: '',
        end_date: '',
        contract_date: '',
        product_image: '',
        attachments: []
      }
  ])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    let link = '?q=' + searchKeyClick
    GetInventories(link).then(({data}) => {
      setInventory(data.data)
    })
  }, [refresh,searchKeyClick])
  return (
    <>
      <KTCard>
        <UsersListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} />
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-0'>
            <div className='card-body pt-3'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className=''>ÜRÜN</th>
                    <th className=''>ÜRÜN KODU</th>
                    <th className=''>ADET</th>
                    <th className=''>BİRİM BEDELİ</th>
                    <th className=''>KATEGORİ</th>
                    <th className=''>FİRMA</th>
                  </tr>
                </thead>

                <tbody className=''>
                  {inventory.length<1 ? 
                  <NoMatchRecords isTable={true}/>  : inventory.map((inv) => {
                    return (
                      <>
                        <InventoryItem key={inv.id} inventory={inv} />
                      </>
                    )
                  })}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}
