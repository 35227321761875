import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import {
  downloadMirroring,
  downloadReport,
  GetCA,
  GetCAStatistics,
  GetMirroring,
  GetReport,
  GetReportStatistics,
} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {ChartsWidget3} from '../components/ChartsWidget3'
import {SearchComponent} from '../components/SearchComponent'
import {TableBody} from './components/TableBody'
import {Add} from './add/Add'
import {ChartsWidget4} from '../../components/widgets/OperationalData/ChartsWidget4'

export const KGHCustomerData: FC = () => {
  const api = '/kgh_customer_data'
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [items, setItems] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [statistic, setStatistic] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [total, setTotal] = useState(0)

  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const count2: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()
  const [planC1, setPC1] = useState<any>()

  const [weekTitleState, setWeekCountTitle] = useState<any>()
  const weekAray: any[] | undefined = []
  const weekAray2: any[] | undefined = []
  const weekTitle: any[] | undefined = []
  const [weekCount, setWeekCount] = useState<any>()
  const [weekCount2, setWeekCount2] = useState<any>()

  useEffect(() => {
    GetReportStatistics('/kgh_customer_data/', '2').then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })

    GetReportStatistics('/kgh_customer_data/', '1').then((data) => {
      weekArrayCount(data.data)
      //setStatistic(data.data)
    })
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetReport(api, link).then((data) => {
      setItems(data?.data?.data)
      setSearchKeyClick('')
      setTotal(data?.data?.meta?.total)
    })
  }, [refresh, searchKeyClick, show])

  const downloa = () => {
    downloadReport(api, '').then((data) => {
      window.open(data?.data?.url)
    })
  }

  const prdType = (data: any) => {
    setTitle([])

    for (let i = 0; i < data.length; i++) {
      // total = total + data[i]?.total
      count1.push(data[i]?.total_thesis)
      count2.push(data[i]?.total_cancel)
      title1.push([data[i]?.month, data[i]?.start_day.split('-')[0]])
    }
    setPC(count1)
    setPC1(count2)
    setTitle(title1)
  }
  const weekArrayCount = (data: any) => {
    setWeekCountTitle([])

    for (let i = 0; i < data.length; i++) {
      // total = total + data[i]?.total
      weekAray.push(data[i]?.total_thesis)
      weekAray2.push(data[i]?.total_cancel)
      weekTitle.push(['W' + data[i]?.week_of_year, data[i]?.start_day.split('-')[0]])
    }
    setWeekCount(weekAray)
    setWeekCount2(weekAray2)
    setWeekCountTitle(weekTitle)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        {statistic.length > 0 ? (
          <div>
            <div className='row g-5 '>
              <div className='col-xl-6 col-xxl-6 mb-xl-6 mb-xxl-12'>
                <ChartsWidget4
                  className='h-100'
                  chartHeight={200}
                  categoriesData={title}
                  data={planC}
                  verticalTitle={''}
                  horizentalTitle={'Aylık KGH Müşteri Artış Trendi'}
                  pointTitle={'Sayısı'}
                  data1={planC1}
                />
              </div>
              <div className='col-xl-6 col-xxl-6 mb-xl-6 mb-xxl-12'>
                <ChartsWidget4
                  className='h-100'
                  chartHeight={200}
                  categoriesData={weekTitleState}
                  data={weekCount}
                  verticalTitle={''}
                  horizentalTitle={'Haftalık KGH Müşteri Artış Trendi'}
                  pointTitle={'Sayısı'}
                  data1={weekCount2}
                />
              </div>
            </div>
            <div className='row g-5 g-xxl-10  mb-5'>{/* end::Col */}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='d-flex mb-6 mt-5'>
        <div className='row w-100'>
          <div className='d-flex flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
              {' '}
              Toplam {total} müsteriye Veri Merkezi Güvenlik Hizmeti verilmektedir.
            </h3>
          </div>
        </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3' style={{overflow: 'scroll'}}>
              <table
                className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder '
                style={{overflow: 'scroll'}}
              >
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color: '#50c8da'}}>
                    <th className=''>Müşteri Id</th>
                    <th className=''>CRM Custome ID</th>
                    <th className=''>İl Adı</th>
                    <th className=''>Bölge Adi</th>
                    <th className=''>Soyad Unvan </th>
                    <th className=''>Hizmet No</th>
                    <th className=''>Zone Sayısı</th>
                    <th className=''>Alt Hizmet Türü Açıklama </th>
                    <th className=''>Durum</th>
                    <th className=''> Ürün Adi</th>
                    <th className=''>Bant Genişliği</th>
                    <th className=''> İlişkili Devre No</th>
                    <th className=''> Hız</th>
                    <th className=''>Koruma Tipi</th>
                    <th className=''> Iptal Tarihi</th>
                    <th className=''> Tesis Tarihi </th>

                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {items?.map((item: any) => {
                    return (
                      <>
                        <TableBody key={item?.id} item={item} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <Add handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
