import React, { FC, useEffect, useState } from 'react'
import {TimeLineBody} from '../components/TimeLineBody'
import { TimeLineHeader } from '../components/TimeLineHeader'

type Props = {
    day:any
    data:any
}

export const TimeLine: FC<Props> = ({day,data}) => {
  const [count, setCount] = useState(0)
  const [activeDay,setActiveDay] = useState(new Date())
  useEffect(()=>{},[])
  return (
    <div className='card h-md-100'>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder text-dark'>Yolda Ne Var</span>
        <span className='text-muted mt-1 fw-bold fs-7'>
          Toplamda {count} hedef var{' '}
        </span>
      </h3>

      {/* <div className='card-toolbar'>
        <a className='btn btn-sm btn-light'>
         {activeDay.toLocaleDateString("tr").slice(0,10)}
        </a>
      </div> */}
    </div>

    <div className='card-body p-2  px-0'>
       <TimeLineHeader  getDate={setActiveDay} days={day} setCount={setCount}/>
        <TimeLineBody setDate={activeDay.toLocaleDateString("tr").slice(0, 10)} data={data} />


    </div>
  </div>
  )
}
