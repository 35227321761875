import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getActivities } from '../core/_requests';

import { HeaderAlertItem } from './HeaderAlertItem'

export const HeaderAlert: FC = () => {
  const [activities,setActivities] = useState<any>([]);
  useEffect(()=>{
    getActivities('?page[size]=5').then(data=>{
      setActivities(data.data);
    })
  },[])
  return (
    <div className='mb-19'>
      {activities.map((ac: { created_at: string; description: string;},index: React.Key | null | undefined)=>{
        return  <HeaderAlertItem key={index} time={ac.created_at.slice(11,16)} title={ac.description}/>
      })}
       
        {/* <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/>
        <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/>
        <HeaderAlertItem time={'12 dk önce'} title={'Gelir Güvence müdürlüğü haftalık rapor verilerini yükledi'}/> */}
    </div>
  )
}
