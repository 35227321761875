import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_theme/layout/core'
import { Activities } from './Activities'
import { ActivityDetail } from './Activity-detail/ActivityDetail'



const ActivitesPageWrapper: React.FC = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='gorevler'
            element={<>
              <PageTitle>Faaliyetler</PageTitle>
              <Activities />
            </>} />
          <Route
            path='detail'
            element={<>
              <PageTitle breadcrumbs={[{title:'Faaliyetler',path:'../faaliyetler/gorevler',isActive:true}]}>/Detay</PageTitle>
              <ActivityDetail />
            </>} />
          </Route>

        <Route
          path=''
          element={<>
            <PageTitle>Faaliyetler</PageTitle>
            <Activities />
          </>} />
      <Route index element={<Navigate to='/faaliyetler/gorevler' />} /></Routes>

    )
  }
  
  export {ActivitesPageWrapper}