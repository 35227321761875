import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import ReactTags from 'react-tag-autocomplete'
import { getAssignments, getRoles } from '../core/requests'



type Props = {
  setRoles: any,
  delRole: any
  tags1: any
}


export const RolesTags: FC<Props> = ({setRoles,delRole,tags1})=> {
  const [tags, setTags] = useState<any>([])

  const [suggestions, setSuggestions] = useState([
    { id: 1, name: "Apples" },
  ])

  const reactTags = useRef();

  const onDelete = useCallback((tagIndex) => {
    delRole(tagIndex);
    setTags(tags.filter((_: any, i: any) => i !== tagIndex))
  }, [tags])

  const onAddition = useCallback((newTag) => {
    setTags([...tags, newTag])
    setRoles(newTag)

  }, [tags])
  useEffect(()=>{
    getAssignments().then(async ({data}) => {
      await setSuggestions(data.data);      
    })
    setTags(tags1)

  },[])

  return (
    <ReactTags
      placeholderText={'Arama yap?*'}
      autoresize={false}
      tags={tags}
      suggestions={suggestions}
      onDelete={onDelete}
      onAddition={onAddition}
    />
  )
}
