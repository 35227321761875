/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {PageTitle} from './page-title/PageTitle'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'

const HeaderTopbar: React.FC = () => {
  const {classes} = useLayout()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <div
      className={`${classes.headerContainer} py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between`}
    >
      <PageTitle />

      <div className='d-flex align-items-center overflow-auto pt-3 pt-sm-0'>
        <div className='header-search me-4'>
          {/* <Search /> */}
          <div className='d-flex'>
                <div className='d-flex align-items-center position-relative my-3 m-5'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-filemanager-table-filter='search'
                    className='form-control form-control-solid  ps-15'
                    placeholder='Ara'
                  />
                  {/* <button
                    type='button'
                    className='btn btn-primary '
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-search'
                      viewBox='0 0 16 16'
                    >
                      <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                    </svg>
                  </button> */}
                </div>
              </div>
        </div>

        <div className='d-flex'>
          {/* <div className='d-flex align-items-center me-4'>
            {/* eslint-disable-next-line */}
            {/* <a
              href='#'
              className='btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG path='/media/icons/duotune/general/gen025.svg' className='svg-icon-1' />
            </a>
            <QuickLinks />
          </div> */}

          {/* <div className='d-flex align-items-center me-4'>
            {/* eslint-disable-next-line */}
            {/* <a
              href='#'
              id='kt_drawer_chat_toggle'
              className='btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-1' />
            </a>
          </div> */}

          <div className='d-flex align-items-center'>
            {/* eslint-disable-next-line */}
            <div
             
              className='btn btn-icon btn-primary'
              data-kt-menu-attach='parent'
              data-kt-menu-flip='bottom'
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-overflow='false'
              data-kt-menu-placement='bottom-start'
            >
              {  user.avatar ? <img style={{width:'100%'}} className='rounded' src={user.avatar } alt="" />: 
              <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-3 '>{user.name?.substring(0, 1)}</div>
              } 
            </div>
           
            <HeaderUserMenu />
          
          </div>
     
        </div>
      </div>
    </div>
  )
}

export default HeaderTopbar
