
import React, { FC, useEffect, useState } from 'react'
import { GetTargetDate } from '../../../core/requests'
import "./style.css";

type Props={
    getDate: any
    days: number
    setCount: any
}

export const TimeLineHeader: FC<Props> = ({getDate,days, setCount}) =>  {

  const d = new Date()
  let today = new Date()
  let [activeDay,setActiveDay] = useState(new Date())
  const [dates1,setDates1]=useState<any>([]);
  const [busyDays,setBusyDays]=useState<any>([]);
  let id = JSON.parse(localStorage.getItem('projectID') || '{}')
  useEffect(()=>{
    var dates = []
    var count = -(((days/2)-1).toFixed(0));
    for (let i = 1; i < days; i++) {
      today.setDate(today.getDate() + count)
      dates.push(new Date(today))
      today = new Date()
      count += 1
    }
    setDates1(dates);
    GetTargetDate(id).then((data)=>{
      console.log(data.data.data)
        setBusyDays(data?.data?.data)
        setCount(data.data.length)
    })
  },[])

  const setActive = (day:any)=>{
    setActiveDay(day);
    getDate(day);
  }
  var options = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};
//day1?.start_date.split('-').reverse().toString().replaceAll(',','.')
console.log()
  return (
    <ul className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'>
      {dates1.map((day: any) => {
        return (
          <li className='nav-item p-0 ms-0'>
            <a
              className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger ${
                activeDay.toLocaleDateString("tr")==day.toLocaleDateString("tr") ? 'active' : ''
              } ${
                busyDays?.find((day1:any)=>{return (day.toLocaleDateString("tr")>= day1?.start_date.split('-').reverse().toString().replaceAll(',','.') && day.toLocaleDateString("tr")<= day1?.end_date.split('-').reverse().toString().replaceAll(',','.') )} ) ? 'busyDay' : ''} )
              }`}
              data-bs-toggle='tab'
              onClick={()=>setActive(day)}
            >
        
              <span className='fs-7 fw-bold'>{day.toLocaleDateString("tr",options).slice(11)}</span>
              <span className='fs-6 fw-bolder'>{day.toLocaleDateString("tr",options).slice(0,2)}</span>
            </a>
          </li>
        )
      })}
    </ul>
  )
}
