import {FC, useState} from 'react'
import swal from 'sweetalert'
import {KTSVG} from '../../../../_theme/helpers'
import {Dropdown, Modal} from 'react-bootstrap'
import {ModalHeader} from '../../components/ModalHeader'

import {Link, NavLink} from 'react-router-dom'
import { DeleteBudget } from '../core/_requests'
import { AddBudget } from '../Budget-edit/AddBudget'
import NumberFormat from 'react-number-format'
import { ProfilePopUp } from '../../Profile/ProfilePopUp'

type Props = {
  budget: any
  refreshPage: any
}

function calTime(date: string) {
  if (date !== undefined) {
    let dateTime = date.split('T')
    // dateTime[1] = '  '+dateTime[1].slice(0,5);
    return dateTime
  }
}

const deleteBudget = (id: any, refreshPage: any) => {
  swal({
    title: '',
    text: 'Kayıt silinecektir emin misiniz?',
    icon: 'warning',
    buttons: ['Vazgeç', 'Evet'],
  }).then(async (responese) => {
    if (responese) {
      await DeleteBudget(id).then(() => {
        swal('Silindi!', 'Bütçe başarı ile silindi.', 'success')
        refreshPage()
      })
    }
  })
}
export const BudgetItem: FC<Props> = ({budget, refreshPage}) => {
  const [showModal, setShowmodal] = useState(false)
  const handleClose = () => {
    setShowmodal(false)
    refreshPage()
  }
  const handleShow = () => {
    setShowmodal(true)
  }
  const [showProfilModal,setShowProfilModal]= useState(false)
  const [profilUserId, setProfilUserId] =  useState(0)
  const closeProfilModal=()=>{
    setShowProfilModal(false)
  }
  const openProfilModal=async ()=>{
    await setShowProfilModal(true)
  }
  return (
    <>
      <tr>
        <td className='fw-bolder'>
          <NavLink to='../detail' state={budget} className='text-gray-900 text-hover-primary'>
            {budget.title}
          </NavLink>
        </td>
        <td className='text-gray-500'>{budget.assignment.name}</td>
        {budget.owner &&  <td className='text-gray-500 text-hover-primary'onClick={async ()=>{setProfilUserId(budget.owner.id);
                await openProfilModal()}}>{budget.owner.name}</td>}
       
        <td className='text-gray-500'><NumberFormat value= {budget.amount} displayType={'text'} thousandSeparator={true} /> {budget.currency}</td>
        <td className='text-end' data-kt-filemanager-table=''>
          <Dropdown>
            <Dropdown.Toggle
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              id='dropdown-basic'
            ></Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item
              >
                {' '}
            
                <NavLink to='../detail' state={budget} className='menu-link px-3 text-black'>
                Görüntüle
              </NavLink>
             
              </Dropdown.Item>
              <Dropdown.Item
               onClick={ handleShow}
              >
                {' '}
                <span className='menu-link px-3'>Düzenle</span>
              </Dropdown.Item>
              <Dropdown.Item 
              onClick={() => {
                deleteBudget(budget.id,refreshPage)
              }}
              >
                <span className='menu-link text-danger px-3'>Sil</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Düzenle'} />
                <AddBudget budget={budget} update={true} handleClose={handleClose} refreshPage={refreshPage}/>
        </Modal.Body>
      </Modal>
      <Modal show={showProfilModal} onHide={closeProfilModal} size='sm'>
        <Modal.Body>
         <ProfilePopUp user_id={profilUserId} handleClose={closeProfilModal}/>
        </Modal.Body>
      </Modal>
    </>
  )
}
