import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {createIssue, getActivityCategories, getUserByAssignment, UpdateIssue} from '../core/_requests'
import {getUsers} from '../../core/requests'
import swal from 'sweetalert'
import * as Yup from 'yup'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../components/ModalHeader'
import { AddCategory } from '../../Categories/AddCategory'

type Props = {
  setModalShow: any
  activity: any
  update: boolean
}
const issueSchema = Yup.object().shape({
  title: Yup.string().required('Zorunlu alan'),
  assigned_by: Yup.number().required('Zorunlu alan'),
  issue_category_id: Yup.number().required('Zorunlu alan'),
  start_date: Yup.date()
    .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
  end_date: Yup.date()
    .when(
      'start_date',
      (start_date, yup) =>
        start_date &&
        yup.min(new Date(start_date), 'Lütfen başlangıç tarihten sonraki tarihlerden seçiniz')
    )
    // .min(Yup.ref('start_date'), 'Aynı Gün Olamaz')
    // .min(new Date(Date.now() - 86400000), 'Geçen Tarih Girilemez')
    .required('Zorunlu alan'),
})

export const AddActivity: FC<Props> = ({setModalShow, activity, update}) => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [users, setUsers] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const openCategoryModal = () => {
    setShowCategoryModal(true)
  }
  const closeCategoryModal = () => {
    setShowCategoryModal(false)
  }

  useEffect(() => {
    getActivityCategories().then(async ({data}) => {
      await setCategories(data.data)
    })

    getUserByAssignment().then(({data}) => {
      console.log(data.data)
      setUsers(data.data)
    })
  }, [update, showCategoryModal])

  const [userForEdit] = useState<any>({
    ...activity,
    id: activity.id,
    title: activity.title,
    start_date: activity.start_date,
    end_date: activity.end_date,
    content: activity.content,
    email_notification: activity.email_notification,
    phone_notification: activity.phone_notification,
    //issue_category_id: activity.issue_category_id,
    //assigned_by: activity.assigned_by,
  })

  const cancel = () => {
    setModalShow(false)
  }

  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: issueSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log(values)
          await UpdateIssue(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          formData.append('title', values.title)
          formData.append('start_date', values.start_date!)
          formData.append('end_date', values.end_date)
          formData.append('content', values.content)
          formData.append('email_notification', values.email_notification ? '1' : '0')
          formData.append('phone_notification', values.phone_notification ? '1' : '0')
          formData.append('issue_category_id', values.issue_category_id)
          formData.append('assigned_by', values.assigned_by)
          // formData.append('status', '0')
          await createIssue(formData)
            .then(() => {
              swal('Eklendi', 'Faaliyet başarı ile eklendi.', 'success', {timer: 2000})
            })
            .catch((err) => {
              console.log(err.message)

              swal('Hata!', err.response.data.message, 'error', {timer: 2000})
            })
          setModalShow()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })
  let date = new Date(formik.values.start_date)
  const loginUser: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input Başlık */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input Başlık */}
          {/* begin::Input Sorumlu */}
          <div className='row mb-7 d-flex'>
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Sorumlu</label>
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('assigned_by')}
                name='assigned_by'
                //defaultValue={activity.assigned_by}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                  Sorumlu Seç
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.assigned_by && formik.errors.assigned_by && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.assigned_by}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Sorumlu */}

            {/* begin::Input kategori */}
            <div className='col-xs-12 col-md-6'>
              <div className='d-flex justify-content-between'> 
              <label className='required fw-bold fs-7 mb-2'>Kategori Seç</label>
              {loginUser.name === 'Süper Admin Kullanıcısı' && (
                <img
                  src='https://cdn-icons-png.flaticon.com/512/992/992651.png'
                  alt='add-icon'
                  width='25px'
                  className=''
                  onClick={openCategoryModal}
                />
              )}
              </div>
              

              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Kategori Seç'
                {...formik.getFieldProps('issue_category_id')}
                name='issue_category_id'
                //defaultValue={activity.issue_category_id}
              >
                <option selected disabled hidden>
                  Kategori Seç
                </option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  )
                })}
              </select>
              {formik.touched.issue_category_id && formik.errors.issue_category_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.issue_category_id}</span>
                  </div>
                </div>
              )}
            </div>

            {/* end::Input Kategori */}
          </div>
          <div className='row mb-7 d-flex'>
            {/* Begin::Input Başlangıç Tarihi */}
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Başlangıç Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('start_date')}
                type='date'
                name='start_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_date && formik.errors.start_date},
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                autoComplete='off'
                // disabled={formik.isSubmitting || update}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_date}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Başlangıç tarihi */}
            {/* Begin::Input Bitiş Tarihi */}
            <div className='col-xs-12 col-md-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-7 mb-2'>Bitiş Tarihi</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder=''
                {...formik.getFieldProps('end_date')}
                type='date'
                min={date.getDate() + 1}
                name='end_date'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_date && formik.errors.end_date},
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                autoComplete='off'
                //disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_date}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input Bitiş tarihi */}
          </div>

          {/* begin::Input Açıklama */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Açıklama</label>
            {/* end::Label */}
            <textarea
              placeholder='Faaliyet için açıklama yazınız'
              {...formik.getFieldProps('content')}
              name='content'
              rows={8}
              cols={70}
              className='form-control form-control-solid mb-3 mb-lg-0'
            ></textarea>
            {/* {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )} */}
          </div>
          {/* end::Açıklama */}

          {/* begin:: Bildirimler */}
          <div className='mb-7 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-bold me-5'>
                <label className='fs-6'>Bildirim</label>
                <div className='fs-7 text-gray-400'>Eklendiğinde bildirim gönderilecektir</div>{' '}
              </div>

              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('email_notification')}
                    name='email_notification'
                    value='email_notification'
                    defaultChecked={activity.email_notification}
                  />
                  <span className='form-check-label fw-bold'>Email</span>
                </label>

                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    {...formik.getFieldProps('phone_notification')}
                    name='phone_notification'
                    value='phone_notification'
                    defaultChecked={activity.phone_notification}
                  />

                  <span className='form-check-label fw-bold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          {/* end:: Bildirimler */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              İptal
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
            >
              <span className='indicator-label'>Gönder</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </form>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
      <Modal show={showCategoryModal} onHide={closeCategoryModal}>
        <Modal.Body>
          <ModalHeader handleClose={closeCategoryModal} titleHeader={'Yeni Kategori Ekle'} />
          <AddCategory
            category={{
              id: undefined,
              name: '',
            }}
            update={false}
            handleClose={closeCategoryModal}
            numberMenu='issue'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
