import React, { FC } from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../_theme/layout/core'
import { AnnouncementDetail } from './Announcement-detail/AnnouncementDetail'
import {Announcements} from './Announcements'
import { InformationSecurityPolices } from './InformationSecurityPolices'
import { PlannedWorks } from './PlannedWorks'
import { SentryInformation } from './SentryInformation'

const AnnouncementsPageWrapper: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='bilgi-guvenligi-politikalari'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Duyurular',path:'../duyurular/tum-duyurular',isActive:true}]}>/ Bilgi Güvenliği ve Politikaları</PageTitle>
            <InformationSecurityPolices/>
            </>
          }
        />
          <Route
          path='tum-duyurular'
          element={
            <>
              <PageTitle>Duyurular</PageTitle>
              <Announcements />
            </>
          }
        />
         <Route
          path='detail'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Duyurular',path:'../duyurular/tum-duyurular',isActive:true}]}>/ Detay</PageTitle>
              <AnnouncementDetail />
            </>
          }
        />
          <Route
          path='nobetci-bilgisi'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Duyurular',path:'../duyurular/tum-duyurular',isActive:true}]}>/Nöbetçi Bilgisi</PageTitle>
              <SentryInformation />
            </>
          }
        />
          <Route
          path='planli-calismalar'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Duyurular',path:'../duyurular/tum-duyurular',isActive:true}]}>/Planlı Çalışmalar</PageTitle>
              <PlannedWorks />
            </>
          }
        />
        <Route index element={<Navigate to='/duyurular/tum-duyurular' />} />
      </Route>
    </Routes>
  )
}

export {AnnouncementsPageWrapper}
