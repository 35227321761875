import React, {FC, useEffect, useState} from 'react'
import { getProject } from '../../../../app/pages/Project/core/requests'
import './style.css'

type Props = {
  data: any
}
export const Timeline: FC<Props> = ({data}) => {
    const id = JSON.parse(localStorage.getItem('projectID') || '{}')
    const [project,setProject] = useState<any>({
        id: 1,
        code: '',
        name: '',
        start_date: '',
        end_date: '',
        status: {
          id: 1,
          name: '',
        },
        description: '',
        email_notification: false,
        phone_notification: false,
        group_directorate: {
          id: 2,
          name: '',
          order: 1,
          parent_id: 1,
          is_group_directorate: false,
          is_directorate: false,
          is_last: false,
        },
        users: [],
        files: [],
        created_at: '',
        created_by: {
          id: 1,
          name: '',
          email: '',
          avatar: '',
        },
      });
  
    useEffect(() => {
      getProject(id).then(res=>{
   
        setProject(res.data.data)
      }).catch(err=>{
        console.log(err);
      })
    }, [])
  const getClass = (status: string) => {
    switch (status) {
      case 'Planlanan':
        return 'event-date bg-soft-primary text-primary'
        break
      case 'Devam Eden':
        return ' event-date bg-soft-success text-success'
        break
      case 'Tamamlanan':
        return 'event-date bg-soft-warning text-warning'
        break

      default:
        break
    }
  }
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title mb-5'>Toplam Hedef ({data?.length})</h4>

              <div className='hori-timeline' dir='ltr'>
                <ul className=' events'>
                  <li className=' event-list' style={{marginRight:'20px'}}>
                    <div className='px-4'>
                      <div className='event-date bg-soft-primary text-light'>      {project?.start_date?.substring(8, 10)}.
                            {project?.start_date?.substring(5, 7)}.
                            {project.start_date?.substring(0, 4)}</div>
                      <h5 className='font-size-16'>Başlangıç Tarihi</h5>
                    </div>
                  </li>
                  {data?.reverse().map((target: any, index: React.Key | null | undefined) => {
                    return (
                      <li key={index} className=' event-list' style={{marginRight:'60px'}}>
                        <div className='px-5'>
                          <div className={getClass(target?.status?.name)}>
                            {' '}
                            {target?.start_date?.substring(8, 10)}.
                            {target?.start_date?.substring(5, 7)}.
                            {target?.start_date?.substring(0, 4)}
                          </div>
                          <h5 className='font-size-16'>{target?.title}</h5>
                        </div>
                      </li>
                    )
                  })}
                  {/* <li className=' event-list'>
                    <div className='px-4'>
                      <div className='event-date bg-soft-primary text-primary'>2 June</div>
                      <h5 className='font-size-16'>Event One</h5>
                      <p className='text-muted'>
                        It will be as simple as occidental in fact it will be Occidental Cambridge
                        friend Occidental Cambridge friend
                      </p>
                      <div>
                        <a href='#' className='btn btn-primary btn-sm'>
                          Read more
                        </a>
                      </div>
                    </div>
                  </li> */}

                  <li className=' event-list' style={{marginRight:'20px'}}>
                    <div className='px-4'>
                      <div className='event-date bg-soft-success text-success'>   {project?.end_date.substring(8, 10)}.{project?.end_date.substring(5, 7)}
                            .{project.end_date.substring(0, 4)}</div>
                      <h5 className='font-size-16'>Bitiş Tarihi</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
