import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'

import swal from 'sweetalert'
import {SwalOptions} from 'sweetalert/typings/modules/options'

import {getUsers} from '../../../core/requests'
import {createIssue, getActivityCategories, UpdateIssue} from '../../../Activities/core/_requests'
import { getBudgets } from '../../core/requests'
import * as Yup from 'yup'

type Props = {
  setModalShow: any
  project: any
  update: boolean
  setNext: any
  setBudget: any
}
const loginSchema = Yup.object().shape({

  budget_id : Yup.string()
    .required('Zorunlu alan'),

})
export const Budged: FC<Props> = ({setModalShow, project, update,setNext,setBudget}) => {

  const [budgets, setBudgets] = useState<any>([])

  useEffect(() => {
    getBudgets().then(({data}) => {
      setBudgets(data.data)
    })
  }, [update])

  const [userForEdit] = useState<any>({
    ...project,
    id: project.id,
    budget_id: project.budget_id,

  })

  const cancel = () => {
    setModalShow(false)
  }

  let formData = new FormData()
  const formik = useFormik({
    initialValues: userForEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (update) {
          console.log(values)
          await UpdateIssue(values.id, values)
            .then(() => {
              swal('Düzenlendi', 'Faaliyet başarı ile düzenlendi.', 'success', {timer: 2000})
            })
            .catch((err: {response: {data: {message: string | Partial<SwalOptions>}}}) => {
              swal('Hata', err.response.data.message, 'error', {timer: 2000})
            })
        } else {
          const body={
            budget_id:values.budget_id
          }
         
          setBudget(body)
        setNext(3);
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // setSubmitting(true)
        // cancel()
      }
    },
  })

  return (
    <>
      <div style={{width: '70%', minWidth: '300px'}} className='m-auto'>
        <div className='mb-5'>
          <h3>Bütçe</h3>
         
        </div>
        <form
          id='kt_modal_add_user_form'
          onSubmit={formik.handleSubmit}
          className='form'
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='mb-7'>
              {/* begin::Label */}
              {/* <label className=' fw-bold fs-7 mb-2'>Bütçe </label> */}
              {/* end::Label */}
              {/* begin::Input */}
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Sorumlu Seç'
                {...formik.getFieldProps('budget_id')}
                name='budget_id'
                defaultValue={project.budget_id}
              >
                {/* {activity.assigned_by!==''} */}
                <option selected disabled hidden>
                Bütçe Seçiniz
                </option>

                {budgets.map((budget: any) => {
                  return (
                    <option key={budget?.id} value={budget.id}>
                      {budget?.title}
                    </option>
                  )
                })}
              </select>
              {formik.touched.budget_id && formik.errors.budget_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.budget_id}</span>
                  </div>
                </div>
              )}
            </div>



            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
             
                onClick={() => {
                 
                  setNext(1)
                }  }
                className='btn btn-light me-3'
           
                disabled={formik.isSubmitting}
              >
              Geri
              </button>

              <button
                type='submit'
                className='btn btn-primary'
          
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Ekip Oluştur</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </div>
        </form>
      </div>
      {/*(formik.isSubmitting || isUserLoading) && <UsersListLoading />*/}
    </>
  )
}
