import {FC, Key, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {GetExchangeRates, updateHomeWidgets} from '../../core/requests'
import swal from 'sweetalert'

type Props = {
  plusOrMinus: boolean
  widgetId: any
  refreshPage?: any
}
export const ExchangeRates: FC<Props> = ({plusOrMinus, widgetId, refreshPage}) => {
  const [exchangeRates, setExchangeRates] = useState<any>([])
  useEffect(() => {
    GetExchangeRates().then(async ({data}) => {
      await setExchangeRates(data)
    })
  }, [])
  const loginUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel

  function homeWidgetShow() {
    swal({
      title: '',
      text: plusOrMinus ? 'Widget anasayfanıza eklensin mi?' : 'Widget silinecektir emin misiniz?',
      icon: 'warning',
      buttons: ['Vazgeç', 'Evet'],
    }).then(async (responese) => {
      if (responese) {
        updateHomeWidgets(loginUser.id, {widgets: [{id: widgetId, value: plusOrMinus}]})
        await refreshPage()
        // await DeleteAnnoun(data.id)
        // swal('Silindi!', 'Duyuru başarı ile silindi.', 'success')
        // navigate('/duyurular/bilgi-guvenligi-politikalari')
      }
    })
  }

  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-body'>
        <div
          className='d-flex justify-content-end'
          onClick={() => {
           homeWidgetShow()
          }}
        >
          <span className='btn btn-icon btn-light btn-active-color-primary mt-n2 me-n3  d-inline-flex '>
            {plusOrMinus ? '+' : '-'}
          </span>
        </div>
        <div className='d-flex flex-stack mb-8'>
          <div className='d-flex align-items-center me-5'>
            <span className='text-dark fw-bolder text-hover-primary fs-6'>Döviz</span>
          </div>

          <div className='text-gray-800 fw-bolder fs-6'>
            <span className='me-7'>Alış</span>

            <span className='m-0'>
              <span className='svg-icon svg-icon-7 svg-icon-success'>
                {/* <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              opacity='0.5'
                              d='M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z'
                              fill='currentColor'
                            />
                            <path
                              d='M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z'
                              fill='currentColor'
                            />
                          </svg> */}
              </span>
              Satış
            </span>
          </div>
        </div>
        {exchangeRates.map((ex: any,index: Key | null | undefined) => {
          return (
          
              <div key={index} className='d-flex flex-stack mb-8'>
                <div className='d-flex align-items-center me-5'>
                  <img
                    src={ex.currency_image}
                    className='me-3'
                    // style="width: 20px;border-radius: 4px"
                    alt=''
                  />

                  <span className='text-dark fw-bolder  fs-6'>{ex.currency_code}</span>
                </div>

                <div className='text-gray-600 fw-bolder fs-6'>
                  <span className='me-7'>{ex.banknote_buying}</span>

                  <span className='m-0'>
                    <span className='svg-icon svg-icon-7 svg-icon-success'>
                      {/* <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              opacity='0.5'
                              d='M13 9.59998V21C13 21.6 12.6 22 12 22C11.4 22 11 21.6 11 21V9.59998H13Z'
                              fill='currentColor'
                            />
                            <path
                              d='M5.7071 7.89291C5.07714 8.52288 5.52331 9.60002 6.41421 9.60002H17.5858C18.4767 9.60002 18.9229 8.52288 18.2929 7.89291L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L5.7071 7.89291Z'
                              fill='currentColor'
                            />
                          </svg> */}
                    </span>
                    {ex.banknote_selling}
                  </span>
                </div>
              </div>
        
          )
        })}
      </div>
    </div>
  )
}
