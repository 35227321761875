import React, {FC, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Card2} from '../../../../_theme/partials/content/cards/Card2'
import {IconUserModel} from '../../../modules/profile/ProfileModels'
import {getFilteredProjects, getProjects} from '../core/requests'
import {Project} from './Project'
import {ProjectsTableHeader} from './ProjectsTableHeader'

type Props = {
  modalShow: any
  setCount: any
  directorateID: any
  searchKeyClick: string
  selectedType: string
  selectedStatus: string
  selectedPlan: string
  refreshPage: any
  listShow: boolean
}

export const ProjectsList: FC<Props> = ({
  modalShow,
  setCount,
  directorateID,
  searchKeyClick,
  selectedPlan,
  selectedStatus,
  selectedType,
  refreshPage,
  listShow,
}) => {
  const [projects, setProjects] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const refreshCount = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }
  useEffect(() => {
    // let assignment = directorateID!=''?'?directorate_id='+directorateID:'';
    let link = ''
    if (searchKeyClick !== '') {
      link = '&q=' + searchKeyClick
    }
    getFilteredProjects(directorateID, link, selectedStatus, selectedType, selectedPlan)
      .then(({data}) => {
        setProjects(data.data)
        setCount(data.meta.total)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [
    modalShow,
    directorateID,
    searchKeyClick,
    selectedPlan,
    selectedStatus,
    selectedType,
    refresh,
  ])
  return (
    <div className='row w-100' style={{marginTop:'0px'}}>
      {listShow ? projects.map(
        (
          project: any
          // { id:number, name: string; description: string; start_date: string; end_date: string; created_by: IconUserModel[] | undefined; }
        ) => {
          return (
            <Project
              key={project.id}
              id={project.id}
              icon='/media/svg/brand-logos/plurk.svg'
              type={project.type}
              plan={project.plan}
              status={project.status}
              title={project.name}
              description={project.description}
              date={project.start_date}
              budget={project.end_date}
              progress={50}
              users={project.created_by}
              refreshPage={refreshPage}
              refreshCount={refreshCount}
            />
          )
        }
      ):
      <ProjectsTableHeader
          modalShow={modalShow}
          setCount={setCount}
          directorateID={directorateID}
          searchKeyClick={searchKeyClick}
          selectedType={selectedType}
          selectedStatus={selectedStatus}
          selectedPlan={selectedPlan}
          refreshPage={refreshPage} projects={projects} type={''}/>
      }
    </div>
  )
}
