import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTCard} from '../../../../_theme/helpers'
import { downloadMirroring, downloadReport, GetCA, GetCAStatistics, GetMirroring, GetReport} from '../core/request'
import {ModalHeader} from '../../components/ModalHeader'
import {ChartsWidget3} from '../components/ChartsWidget3'
import {SearchComponent} from '../components/SearchComponent'
import {  TableBody } from './components/TableBody'
import { Add } from './add/Add'
import { PieNOACChart } from '../../components/widgets/OperationalData/PieNOACChart'
import { BarNOACChart } from '../../components/widgets/OperationalData/BarNOACChart'


export const NumberOfAlarmCases: FC = () => {
  const api = '/number_of_alarm_cases'
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [items, setItems] = useState<any>([])
  const [refresh, setRefresh] = useState(false)
  const [statistic, setStatistic] = useState<any>([])
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let total = 0
 
 
  const [title, setTitle] = useState<any>()
  const count1: any[] | undefined = []
  const title1: any[] | undefined = []
  const [planC, setPC] = useState<any>()

  const [atatCountTitle, setAtatCountTitle] = useState<any>()
  const atatCountAray: any[] | undefined = []
  const atatCounttitle: any[] | undefined = []
  const [atatCount, setAtatCount] = useState<any>()

  useEffect(() => {
    GetCAStatistics('1').then((data) => {
      prdType(data.data)
      setStatistic(data.data)
    })
    GetCAStatistics('2').then((data) => {
      AttactCount(data.data)
      //setStatistic(data.data)
    })
    let link = ''
    if (searchKeyClick !== '') {
      link = '?q=' + searchKeyClick
    }
    GetReport(api,link).then((data) => {
      setItems(data?.data?.data)
      setSearchKeyClick('');
    })
  }, [refresh, searchKeyClick,show])

  const downloa = () => {
    downloadReport(api,'').then((data) => {
      console.log(data)
      window.open(data?.data?.url)
    })
  }

  const prdType = (data: any) => {
    setTitle([])
    total = 0
    for (let i = 0; i < data.length; i++) {
      // total = total + data[i]?.total
      count1.push(data[i]?.attack_size)
      title1.push(
        data[i]?.start_day.substring(8, 10) +
          '.' +
          data[i]?.start_day.substring(5, 7) +
          '-' +
          data[i]?.end_day.substring(8, 10) +
          '.' +
          data[i]?.end_day.substring(5, 7)
      )
    }
    setPC(count1)
    setTitle(title1)
  }
  const AttactCount = (data: any) => {
    setAtatCountTitle([])
    total = 0
    for (let i = 0; i < data.length; i++) {
      // total = total + data[i]?.total
      atatCountAray.push(data[i]?.total_attacks)
    }
    setAtatCount(atatCountAray)

    for (let i = 0; i < data.length; i++) {
      atatCounttitle.push(
        data[i]?.start_day.substring(8, 10) +
          '.' +
          data[i]?.start_day.substring(5, 7) +
          '-' +
          data[i]?.end_day.substring(8, 10) +
          '.' +
          data[i]?.end_day.substring(5, 7)
      )
    }
    setAtatCountTitle(atatCounttitle)
  }

  return (
    <>
      <div>
        <div className=''>
          <div className='d-flex justify-content-between'>
            <h3 className='text-dark mb-7'> </h3>

            <div className='d-flex m-3'>
              <div className='d-flex align-items-center position-relative my-3 m-5'></div>
            </div>
          </div>
        </div>
        <div className='row g-5 '>
            <div className='col-xl-4 col-xxl-4 mb-xl-5 mb-xxl-6 '>
              <PieNOACChart name={'f'} />
            </div>
            <div className='col-xl-8 col-xxl-8 mb-xl-5 mb-xxl-6'>
              <BarNOACChart  className={''}
                chartHeight={200}
                type={'2'}
                verticalTitle={''}
                horizentalTitle={'Alarm Tipi Sayıları'}
                pointTitle={''}/>
            </div>

            {/* end::Col */}
          </div>
      </div>
      <KTCard>
        <div id='kt_project_targets_card_pane'>
          <div className=''>
            <div className='card border-0 d-flex justify-content-between'>
              <div className='card-header'>
                <SearchComponent setWord={setSearchKeyClick} />
                <div className='d-flex m-3'>
                  <div className='d-flex align-items-center position-relative my-3 m-5'></div>

                  <Button
                    className='h-40px mt-3 mt-3'
                    style={{marginRight: '10px'}}
                    variant='primary'
                    onClick={downloa}
                  >
                    Dışarı Aktar
                  </Button>
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Rapor Dosyası Ekle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3' style={{overflow:'scroll'}}>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder ' style={{overflow:'scroll'}}>					 	
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr style={{color:'#50c8da'}}>
                  <th className=''>ID</th>
                    <th className=''>Name</th>
                    <th className=''>Type</th>
                    <th className=''>Severity</th>
                    <th className=''>Status </th>
                    <th className=''>Occurred</th>
             
    
         
                    {/* <th className=''>FİRMA</th> */}
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {items?.map((item: any) => {
                    return (
                      <>
                        <TableBody key={item?.id} item={item} />
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </KTCard>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <ModalHeader titleHeader={'Dosya Ekle'} handleClose={handleClose} />
          <Add handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
