import { table } from 'console';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import ReactTags from 'react-tag-autocomplete'
import { getAssignments } from '../../core/requests';
import "./style.css";

type Props = {
  setRoles: any,
  delRole: any
}


export const Tags: FC<Props> = ({setRoles,delRole})=> {
  const [tags, setTags] = useState<any>([])

  const [suggestions, setSuggestions] = useState([
    { id: 1, name: "Apples" },
  ])

  const reactTags = useRef();

  const onDelete = useCallback((tagIndex) => {
    delRole(tagIndex);
    setTags(tags.filter((_: any, i: any) => i !== tagIndex))
  }, [tags])

  const onAddition = useCallback((newTag) => {
    if(!tags.find((i: any)=> i?.id === newTag?.id))
    {
      setTags([...tags, newTag])
      setRoles(newTag)
    }
  
  }, [tags])
  useEffect(()=>{
    getAssignments().then(({data}) => {
      setSuggestions(data.data);      
    })
  },[])

  return (
    <ReactTags
      placeholderText={'Arama yap?*'}
      autoresize={false}
      tags={tags}
      suggestions={suggestions}
      onDelete={onDelete}
      onAddition={onAddition}
    />
  )
}
