import { FC } from "react"
import { useListView } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { BudgetToolbar } from "./BudgetToolBar"
import { BudgetSearchComponent } from "./BudgetSearchComponent.tsx"
import { Dropdown } from "react-bootstrap"

type Props ={
  refreshPage :any
  setWord: any
  setFilterWord: any
}

const BudgetHeader : FC <Props> = ({refreshPage, setWord, setFilterWord}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 '>
      <BudgetSearchComponent setWord={setWord}/>
      {/* begin::Card toolbar */}
     
      <div className='card-toolbar'>
      <Dropdown> 
            <Dropdown.Toggle
              className='btn btn-sm btn-light btn-active-light-primary me-3 h-40px'
              id='filter'
            
            >  Filtrele</Dropdown.Toggle> 
            <Dropdown.Menu>
            <Dropdown.Item   
              onClick={()=>{setFilterWord('')}}
              >
                {' '}
                <span className='menu-link px-3'>Tümü</span>
              </Dropdown.Item>
              <Dropdown.Item   
              onClick={()=>{setFilterWord('2')}}
              >
                {' '}
                <span className='menu-link px-3'>Opex</span>
              </Dropdown.Item>
              <Dropdown.Item 
               onClick={()=>{setFilterWord('1')}}
               >
                <span className='menu-link  px-3'>Capex</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        {/* begin::Group actions */}
       <BudgetToolbar refreshPage={refreshPage}/>
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BudgetHeader}
