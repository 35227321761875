import React, {FC, useEffect, useState} from 'react'
import { NoMatchRecords } from '../../components/NoMatchRecords'
import {getActivities} from '../core/_requests'

import {TargetItem2} from './TargetItem2'
 
type Props={
  show:boolean
  issuesId: string
  searchKeyClick: string
  period:string
  widgetId: any
}
export const TargetLists: FC <Props>= ({show,issuesId,searchKeyClick, period, widgetId}) => {
  const [activities, setActivities] = useState([
    {
      id:1,
      title: '',
      content: '',
      start_date:'',
      end_date:'',
      phone_notification: false,
      email_notification: false,
      comments: [],
      category: {
        id: 1,
        name: ''
      },
      status: {
        id: '0',
        name :'Planlanan'
      },
      assigned: {
        id :1,
        name: '',
        email: ''
      }

    }
  ])
  const [refresh,setRefresh]=useState('1')
  useEffect(() => {
    let link = ''
    if (searchKeyClick !== ''){
      link = '&q=' + searchKeyClick}
    getActivities(period,link, issuesId, widgetId).then(({data}) => {
      setActivities(data.data)
    })
  }, [refresh,show,issuesId, searchKeyClick,period])
  return (
    <div id='kt_project_targets_card_pane'>
      <div className='card card-flush'>
        <div className='card-body pt-3'>
          <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th className='min-w-100px'>FAALIYET</th>
                <th className='min-w-90px'>KATEGORİ</th>
                <th className='min-w-150px'>BAŞlANGIÇ TARİHİ</th>
                <th className='min-w-150px'>BİTİŞ TARİH</th>
                <th className='min-w-150px'>SORUMLU</th>
                <th className='min-w-90px'>DURUM</th>
           
              </tr>
            </thead>

            <tbody className='fs-6'>

              {activities.length < 1 ? <NoMatchRecords isTable={true}/> :
              activities.map((activity) => {
                return (
                  <TargetItem2
                    key={activity.id}
                    id={activity.id}
                    title={activity.title}
                    categoryName={activity.category.name}
                    start_date={activity.start_date}
                    end_date={activity.end_date}
                    userName={activity.assigned?.name}
                    status={'In Progress'}
                    issue={activity}
                    setRefresh={setRefresh}
                    assigned_id={activity.assigned?.id}
                  />
                )
              })}
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
