import {FC, useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'

import {KTCardBody} from '../../../../_theme/helpers'
import { UsersListLoading } from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import { UsersListPagination } from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import { useQueryResponseData, useQueryResponseLoading } from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { User } from '../../../modules/apps/user-management/users-list/core/_models'
import { CustomHeaderColumn } from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import { CustomRow } from '../../../modules/apps/user-management/users-list/table/columns/CustomRow'
import { usersColumns } from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import { UserListItem } from './UserLıstItem'

type Props = {
    usersList: any
}

const BlogViewUsers: FC<Props> = ({usersList}) => {
    console.log(usersList)
  const users =usersList;
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th className='min-w-250px'>Kullanıcılar</th>
              <th className='min-w-10px text-end'>Okuma Tarihi</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' >
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <UserListItem user={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                   Kullanıcı bulunmadı
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {BlogViewUsers}
