
import axios, {AxiosResponse} from 'axios'

const API_URL = 'https://sgd.ecodation.com'
export const INVENTORY = `${API_URL}/inventories`
export const INVENTORY_CATEGORIES = `${API_URL}/inventory_categories`

const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

//category

export async function GetInventoryCategories() {
    return await axios.get(INVENTORY_CATEGORIES)
}


//inventory

export async function GetInventories(link:string){
    return await axios.get(INVENTORY+link)
}

export async function GetInventory(id: any){
    return await axios.get(INVENTORY+'/'+id)
}
export async function CreateInventory(inventory: any){
    return await axios.post(INVENTORY,inventory,config)
}

export async function DeleteInventory(id: any){
    return await axios.delete(INVENTORY+'/'+id)
}

export async function UpdateInventory(id:any, inventory: any) {
    return await axios.put(INVENTORY+'/'+id,inventory)
}

// attachments
 export async function DeleteInventoryAttachment(inventory_id: any,attachment_id:any){
     return await axios.delete(INVENTORY+'/'+inventory_id+'/attachments/'+attachment_id)
 }

 export async function CreateInventoryAttachment(inventory_id:any,attachment: any){
     return await axios.post(INVENTORY+'/'+inventory_id+'/attachments',attachment,config)
 }
 export async function UpdateProductImage(inventory_id:any, image: any){
     return await axios.post(INVENTORY+'/'+inventory_id+'/product_image',image,config)
 }