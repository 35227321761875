import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {KTCard} from '../../../_theme/helpers'
import { ModalHeader } from '../components/ModalHeader'
import {RoleListHeader} from './components/roleHeader/RoleListHeader'
import {GetRole} from './core/_requests'
import { SetPage } from './edit-modal/SetPage'
import { SetPermission } from './edit-modal/SetPermission'

export const RolesPage: FC = () => {
  const [showPermissionModal, setPermissionModal] = useState(false)
  const [showPageModal, setPageModal] = useState(false)
  const [roleId, setRoleId]= useState(0)
  const openPermissionModal = (id: any)=>{
    setRoleId(id)
      setPermissionModal(true)
  }
  const closePermissionModal = ()=>{
      setPermissionModal(false)
  }
  const openPageModal = (id:any) =>{
  
      setRoleId(id)
      setPageModal(true)
  }
  const closePageModal = () =>{
      setPageModal(false)
  }

  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [roles, setRoles] = useState([
    {
      id: 1,
      name: '',
    },
  ])
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  useEffect(() => {
    GetRole().then(({data}) => {
      setRoles(data.data)
    })
  }, [refresh])
  return (
    <>
      {/* <KTCard> */}
        <RoleListHeader refreshPage={refreshPage} setWord={setSearchKeyClick} />
        <div id='kt_project_targets_card_pane'>
          <div className='card card-flush  border-top-0 border-bottom-0'>
            <div className='card-body pt-3'>
              <table className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'>
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className=''>Rol</th>
                  </tr>
                </thead>

                <tbody className='fs-6'>
                  {roles.map((role) => {
                    return (
                      <tr key={role.id}>
                        <td>{role.name}</td>
                        <td className='text-end'>
                          <Button className='me-4' onClick={()=>openPermissionModal(role.id)}>Yetki Tanımla</Button>
                          <Button onClick={()=>openPageModal(role.id)}>Sayfa Tanımla</Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* </KTCard> */}
      <Modal show={showPageModal} onHide={closePageModal}>
        <Modal.Body style={{width: '100%'}}>
        <ModalHeader handleClose={closePageModal} titleHeader={'Sayfa Tanımla'} />
        <SetPage role_id={roleId}/>
        </Modal.Body>
      </Modal>
      <Modal show={showPermissionModal} onHide={closePermissionModal} size='xl'>
        <Modal.Body  >
    
        <ModalHeader handleClose={closePermissionModal} titleHeader={'Yetki Tanımla'} />
        <SetPermission role_id={roleId}/>
       
        </Modal.Body>
      </Modal>
    </>
  )
}
