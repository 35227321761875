import React, {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ModalHeader} from '../components/ModalHeader'
import { NoMatchRecords } from '../components/NoMatchRecords'
import {AddBlog} from './blog-edit-modal/AddBlog'
import {BlogItem} from './components/BlogItem'
import {BlogItemHeader} from './components/BlogItemHeader'
import {InstaBlog} from './components/InstaBlog'
import {getBlog, GetBlogByCategory} from './core/_requests'

type BlogModel = {
  el: BlogModel
}

export const Blog: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [blog, setBlog] = useState<any>([
    //   {
    //   id: '1',
    //   title: '',
    //   start_date: '',
    //   content: '',
    //   is_public: false,
    //   is_slider: false,
    //   email_notification: false,
    //   phone_notification: false,
    //   category: {
    //     id: 1,
    //     name: '',
    //   },
    //   attachments: '',
    //   files: [],
    //   assignments: [
    //     {
    //       id: 1,
    //       name: '',
    //     },
    //   ],
    // }
  ])
  const [blog1, setBlog1] = useState<any>([])
  const [blog2, setBlog2] = useState<any>([])
  const [blog3, setBlog3] = useState<any>([])
  const [blog4, setBlog4] = useState<any>([])
  const [searchKey, setSearchKey] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')

  useEffect(() => {
    let link = ''
    if (searchKeyClick !== ''){
      link = '&q=' + searchKeyClick;
      setBlog1([])
      setBlog2([])
      setBlog3([])
      setBlog4([])
    } 
    getBlog(link, '?page[size]=7').then(({data}) => {
      setBlog(data)
    })
    if(link===''){
      getBlog(link, '?page[size]=7').then(({data}) => {
        setBlog(data)
      })
      GetBlogByCategory(1,'&page[size]=3').then(({data}) => {
        setBlog1(data.data)
      })
      GetBlogByCategory(2,'&page[size]=3').then(({data}) => {
        setBlog2(data.data)
      })
      GetBlogByCategory(3,'&page[size]=3').then(({data}) => {
        setBlog3(data.data)
      })
      GetBlogByCategory(4,'&page[size]=3').then(({data}) => {
        setBlog4(data.data)
      })
    }

  }, [show, searchKeyClick])

  return (
    <div id='kt_app_content_container' className='app-container container-fluid'>
      <div className='card'>
        <div className='card-body p-lg-10'>
          <div className='mb-15'>
            <div className='d-flex justify-content-between'>
              <h3 className='text-dark mb-7'>Son Gönderiler</h3>

              <div className='d-flex'>
                <div className='d-flex align-items-center position-relative my-3 m-5'>
                  <input
                    type='text'
                    data-kt-filemanager-table-filter='search'
                    className='form-control form-control-solid me-2'
                    placeholder='Blog Ara'
                    onChange={(e) => {
                     setSearchKey(e.target.value)
                     e.target.value=='' && setSearchKeyClick('')
                    }}
                  />
                  <button
                    type='button'
                    className='btn btn-primary '
                    onClick={() => {
                      setSearchKeyClick(searchKey)
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-search'
                      viewBox='0 0 16 16'
                    >
                      <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                    </svg>
                  </button>
                </div>
                {
                  <Button className='h-40px mt-3' variant='primary' onClick={handleShow}>
                    Yeni Blog
                  </Button>
                }
              </div>
            </div>

            <div className='separator separator-dashed mb-9'></div>
            {blog.length<1 ? <NoMatchRecords isTable={false}/> :   blog.map((e: any, index: React.Key | null | undefined) => {
              if (index === 0) {
                return (
                  <BlogItemHeader
                    key={index}
                    el={e}
                    path={e.files}
                    category_name={e.category.name}
                  />
                )
                //   }
                // }
              }
            })}

            <div className='row g-10'>
              {blog.map((e: any, index: React.Key | null | undefined) => {
                {
                  if (index !== 0) {
                    return (
                      <BlogItem
                        key={index}
                        blogType={'latest'}
                        blogName={e.category.name}
                        el={e}
                        path={e.files}
                      />
                    )
                  }
                }
              })}
            </div>
          
          </div>

          {blog1.length > 0 ? (
            <div className='mb-17'>
              <div className='d-flex flex-stack mb-5'>
                <h3 className='text-dark'>Vaka Çalışmaları</h3>
                <Link
                  to='ViewDetail'
                  state={{num: 1, category: 'Vaka çalışmaları'}}
                  className='fs-6 fw-bold link-primary'
                >
                  Tümünü Gör
                </Link>
              </div>
              <div className='separator separator-dashed mb-9'></div>
              <div className='row g-5 g-lg-10'>
                {blog1.map((e: any, index: React.Key | null | undefined) => {
                  {
                    return (
                      <BlogItem
                        key={index}
                        blogType={'latest'}
                        blogName={''}
                        el={e}
                        path={e.files}
                      />
                    )
                  }
                })}
              </div>
            </div>
          ) : (
            ''
          )}
       {blog2.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Ürün İncelemeleri</h3>
              <Link
                to='ViewDetail'
                state={{num: 2, category: 'Ürün İncelemeleri'}}
                className='fs-6 fw-bold link-primary'
              >
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9'></div>
            <div className='row g-5 g-lg-10'>
              {blog2.map((e: any, index: React.Key | null | undefined) => {
                {
                  return (
                    <BlogItem key={index} blogType={'latest'} blogName={''} el={e} path={e.files} />
                  )
                }
              })}
            </div>
          </div>
       ):''}
              {blog3.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Güncel Trendler</h3>
              <Link
                to='ViewDetail'
                state={{num: 3, category: 'Güncel Trendler'}}
                className='fs-6 fw-bold link-primary'
              >
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9'></div>
            <div className='row g-5 g-lg-10'>
              {blog3.map((e: any, index: React.Key | null | undefined) => {
                {
                  return (
                    <BlogItem key={index} blogType={'latest'} blogName={''} el={e} path={e.files} />
                  )
                }
              })}
            </div>
          </div>):''}
          {blog1.length > 0 ? (
          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>Etkinlikler</h3>
              <Link
                to='ViewDetail'
                state={{num: 4, category: 'Etkinlikler'}}
                className='fs-6 fw-bold link-primary'
              >
                Tümünü Gör
              </Link>
            </div>
            <div className='separator separator-dashed mb-9'></div>
            <div className='row g-5 g-lg-10'>
              {blog4.map((e: any, index: React.Key | null | undefined) => {
                {
                  return (
                    <BlogItem key={index} blogType={'latest'} blogName={''} el={e} path={e.files} />
                  )
                }
              })}
            </div>
          </div>):''}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body style={{width: '100%'}}>
          <ModalHeader handleClose={handleClose} titleHeader={'Yeni Blog'} />
          <AddBlog
            handleClose={handleClose}
            update={false}
            blog={{
              title: '',
              start_date: '',
              content: '',
              is_public: false,
              is_slider: false,
              email_notification: false,
              phone_notification: false,
              blog_category_id: '',
              attachments: '',
              files: '',
              assignments: [],
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
