import { FC } from "react"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import { PageTitle } from "../../../_theme/layout/core"
import { Inventory } from "./Inventory"
import { InventoryDetail } from "./inventory-detail/InventoryDetail"

export const InventoryPageWrapper : FC=()=>{
  return (
    <Routes>
      <Route element={<Outlet />}>
      <Route
          path='envanter-yonetimi'
          element={
            <>
            <PageTitle>Envanter Yönetimi</PageTitle>
             <Inventory/>
            </>
          }
        />
         <Route
          path='envanter-detail'
          element={
            <>
              <PageTitle breadcrumbs={[{title:'Envanter Yönetimi',path:'../envanter-yonetimi',isActive:true}]}>/Detay</PageTitle>
              <InventoryDetail/>
             
            </>
          }
        />
          
        <Route index element={<Navigate to='/envanter-yonetimi' />} />

      </Route>
    </Routes>
  )
}