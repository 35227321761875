import {FC, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {NoMatchRecords} from '../../components/NoMatchRecords'
import {BlogItem} from '../components/BlogItem'
import {GetBlogByCategory} from '../core/_requests'

type LocationState = {
  num: number
  category: string
}
export const ViewDetail: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [blog, setBlog] = useState<any>([])
  const [searchKey, setSearchKey] = useState('')
  const [searchKeyClick, setSearchKeyClick] = useState('')
  let data = location.state as LocationState
  useEffect(() => {
    let link = ''
    if (searchKeyClick !== '') link = '&q=' + searchKeyClick
    GetBlogByCategory(data.num, link).then(({data}) => {
      setBlog(data.data)
    })
  }, [searchKeyClick])
  return (
    <div id='kt_app_content_container' className='app-container container-fluid'>
      <div className='card'>
        <div className='card-body p-lg-10'>
          <div className='d-flex justify-content-end'>
            <div className='d-flex'>
              {/* <div className='d-flex align-items-center position-relative my-3 m-5'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-filemanager-table-filter='search'
                    className='form-control form-control-solid  ps-15'
                    placeholder='Blog Ara'
                    onChange={(e) => {
                    setSearchKey(e.target.value ? e.target.value : '')
                    }}
                  />
                </div> */}
              {}
            </div>
          </div>

          {/* <div className='row g-10'>
              {blog.map((e: any, index: React.Key | null | undefined) => {
                {
                  // if (e.assignments !== undefined || user.assignment === null) {
                  //   if (
                  //     user.assignment === null ||
                  //     e.assignments.find((e: {name: any}) => e.name === user.assignment)
                  //   ) {
                  return (
                    <BlogItem
                      key={index}
                      blogType={'latest'}
                      blogName={e.category.name}
                      el={e}
                      path={e.files}
                    />
                  )
                  //   }
                  // }
                }
              })}
            </div> */}

          <div className='mb-17'>
            <div className='d-flex flex-stack mb-5'>
              <h3 className='text-dark'>{data.category}</h3>
              <div className='d-flex align-items-center position-relative my-3 m-5 w-25'>
                {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span> */}

                <input
                  type='text'
                  data-kt-filemanager-table-filter='search'
                  className='form-control form-control-solid me-2'
                  placeholder='Blog Ara'
                  onChange={(e) => {
                    setSearchKey(e.target.value)
                    e.target.value == '' && setSearchKeyClick('')
                  }}
                />
                <button
                  type='button'
                  className='btn btn-primary '
                  onClick={() => {
                    setSearchKeyClick(searchKey)
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-search'
                    viewBox='0 0 16 16'
                  >
                    <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                  </svg>
                </button>
              </div>
            </div>

            <div className='separator separator-dashed mb-9'></div>
            <div className='row g-5 g-lg-10'>
              {blog.length < 1 ? (
                <NoMatchRecords isTable={false} />
              ) : (
                blog.map((e: any, index: React.Key | null | undefined) => {
                  {
                    // if (e.assignments !== undefined || user.assignment === null) {
                    //   if (
                    //     user.assignment === null ||
                    //     e.assignments.find((e: {name: any}) => e.name === user.assignment)
                    //   ) {
                    return (
                      <BlogItem
                        key={index}
                        blogType={'latest'}
                        blogName={''}
                        el={e}
                        path={e.files}
                      />
                    )
                    //   }
                    // }
                  }
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
