import {FC, Key, useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/css'
import {getAnnoun} from '../core/_requests'
import {Link} from 'react-router-dom'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Navigation, Pagination} from 'swiper'
import './style.css'

import $ from 'jquery' 

type Props = {
  className: string
  text: string
  textClass: string
  buttonText: string
  buttonClass: string
}

export const Banner: FC<Props> = ({className, text, textClass, buttonText, buttonClass}) => {
  const [show, setShow] = useState<boolean>(false)
  const [announ, setAnnoun] = useState<any>([])
  const [swiper, setSwiper] = useState<any>(null);
  const [height,setHeight] =  useState(false);
  useEffect(() => {
      getAnnoun().then( (data) => {
        console.log(data)
       setAnnoun(data.data.data)
        setHeight(true)
      })

  }, [])
  const pagination = {
    clickable: true,
  }

  $('.swiper-pagination-bullet').hover(function() {
    $( this ).trigger( "click" );
  });
  ;
 
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination]}
        autoHeight={true}
        pagination={{
          clickable: true,
     
        }}
        spaceBetween={50}
        slidesPerView={1}
    
        onSwiper={(s) => {
          setSwiper(s);
        }}
       
      
      
      >
        {announ?.map((announ: any, index: Key | null | undefined) => {
          return (
            announ?.files?.length > 0 ?
            <SwiperSlide
              key={index}
             
            >
              <div className={`card bgi-no-repeat bgi-size-cover max-w-100p ${className}`}  style={{
                backgroundImage: announ?.files?.length > 0 ? ` url(${announ?.files[0]?.path})` : '',
                backgroundPosition: '100% 100%',
                backgroundSize: '100% 100%',
      
                height: 'auto',
                minHeight:'300px'
              }}>
                <img src={announ?.files[0]?.path} style={{visibility: 'hidden'}} />

                {/* begin::Body */}

                <div
                  className=' d-flex flex-column justify-content-center ps-lg-15'
                  style={{
                    position: 'absolute',
                    top: '45%',
                  }}
                >
                  <h3
                    className={`${textClass} fs-1 fw-boldest mb-4 mb-lg-8 text-white`}
                    dangerouslySetInnerHTML={{__html: announ?.title}}
                    style={{}}
                  ></h3>
                </div>
                <div className='m-0' style={{position: 'absolute', bottom: '10px', left: '15px'}}>
                  {/* eslint-disable-next-line */}
                  <Link
                    to='/duyurular/detail'
                    state={announ}
                    className={`btn ${buttonClass} fw-bold`}
                    data-bs-target='#kt_modal_create_app'
                    onClick={() => {
                      setShow(!show)
                    }}
                  >
                    {buttonText}
                  </Link>
                </div>
              </div>
            </SwiperSlide>:''
          )
        })}
      </Swiper>

      {/* <Main
        show={show}
        handleClose={() => {
          setShow(false)
        }}
      /> */}
    </>
  )
}
