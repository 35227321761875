import {FC, useEffect, useState} from 'react'
import { NoMatchRecords } from '../../components/NoMatchRecords'

import {GetBudgets} from '../core/_requests'
import {BudgetItem} from './BudgetItem'
import { BudgetHeader } from './header/BudgetHeader'

export const BudgetsList: FC = () => {
  const [budget, setBudget] = useState([
    {
      id: 1,
      title: '',
      amount: 30000000,
      currency: 'TRY',
      year: 2022,
      company_contract_status: false,
      internal_signature_status: false,
      general_admission_status: false,
      invoice_status: false,
      migo_status: false,
      assignment: {
        id: 1,
        name: '',
        order: 2,
        parent_id: 4,
        is_group_directorate: false,
        is_directorat: false,
        is_last: false,
      },
      owner: {
        id: 5,
        name: 'Doğan Emre Öztaş',
        email: 'user5@ecodation.com',
        avatar:
          'https://sgd.ecodation.com/storage/713edffcdcf3673864243392ac1f81d6/doğan emre öztaş.jfif',
        roles: [
          {
            id: 10,
            name: 'Müdür',
          },
        ],
      },
      type: {
        id: 1,
        name: 'Capex',
      },
      category: {
        id: 2,
        name: 'Lisans',
      },
      company: {
        id: 2,
        name: 'TT',
      },
      purchase_type: {
        id: 3,
        name: 'Çerçeve',
      },
      created_at: '',
      created_by: {
        id: 1,
        name: '',
        email: '',
        avatar: '',
        roles: [
          {
            id: 11,
            name: '',
          },
        ],
      },
    },
  ])
  const [refresh, setRefresh] = useState(false)
  const [searchKeyClick, setSearchKeyClick] = useState('')
  const [filterWord, setFilterWord] = useState('')
  const refreshPage = () => {
    refresh ? setRefresh(false) : setRefresh(true)
  }

  useEffect(() => {
    let link = ''
    if (searchKeyClick !== ''){
      link = '&q=' + searchKeyClick;}

    GetBudgets(filterWord,link).then(({data}) => {
      setBudget(data.data)
    })
  }, [refresh, searchKeyClick, filterWord])
  return (
    <>
      <div id='kt_project_targets_card_pane'>
     <BudgetHeader refreshPage={refreshPage} setWord={setSearchKeyClick} setFilterWord={setFilterWord}/>
        <div className='card card-flush  border-0 '>
          <div className='card-body pt-3'>
            <table className='table table-row-dashed gy-6 align-middle fw-bolder'>
              <thead className='fs-7 text-gray-400 text-uppercase'>
                <tr>
                  <th className=''>BAŞLIK</th>
                  <th className=''>GÖREVLİ</th>
                  <th className=''>SAHİP</th>
                  <th className=''>TUTAR</th>
                  <th className=''></th>
                </tr>
              </thead>

              <tbody className='fs-6'>

                {budget.length<1 ? <NoMatchRecords isTable={true}/> :budget.map((budg) => {
                  return <BudgetItem key={budg.id} budget={budg} refreshPage={refreshPage} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
